// https://github.com/adazzle/react-data-grid/blob/main/src/hooks/useFocusRef.ts
import { useRef, useEffect, useLayoutEffect as useOriginalLayoutEffect } from 'react';
const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;
function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
  const ref = useRef<T>(null);
  useLayoutEffect(() => { if (!isSelected) return; ref.current?.focus({ preventScroll: true }); }, [isSelected]);
  return { ref, tabIndex: isSelected ? 0 : -1 };
}

interface CellExpanderFormatterProps {
  isCellSelected: boolean;
  expanded?: boolean;
  onCellExpand: () => void;
}

export function CellExpanderFormatter({
  isCellSelected,
  expanded,
  onCellExpand
}: CellExpanderFormatterProps) {
  const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);

  /*
  function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      onCellExpand();
    }
  } // gtkaezrqogtj repgtji reop gjpoijp
  */
// onKeyDown={handleKeyDown}
  return (
    <div className="cell-expand">
      <span onClick={onCellExpand}>
        <span ref={ref} tabIndex={tabIndex}>
          {expanded ? '\u25BC' : '\u25B6'}
        </span>
      </span>
    </div>
  );
}

