import { createContext, useContext, useState, useMemo, useRef, useEffect, useLayoutEffect as useOriginalLayoutEffect } from 'react';
import { SelectColumn, SelectCellFormatter } from 'react-data-grid';
import type { Column, SortColumn, HeaderRendererProps, FormatterProps, SummaryFormatterProps, DataGridHandle } from 'react-data-grid';
import * as Format from './formatUtils';

interface Product {
  id?: string;
  name: string;
  price_client: number;
  price_supplier: number;
  unit: string;
  type: string;
  description: string;
}

export interface Line extends Product {
  quantity: number;
  discount: number;
  sequence: number;
  loc_date?: string;
}

interface Summary {
  name: string;
  totalCount: number;
}

export interface Filter {
  name: string;
}

type Comparator = (a: Product, b: Product) => number;

export const product: Product = {
  name: '',
  price_client: 0,
  price_supplier: 0,
  unit: '',
  type: '',
  description: ''
};

export const line: Line = {
  ...product,
  quantity: 0,
  discount: 0,
  sequence: 10
};

export const primary: Line = {
  name: '20 min 3u',
  price_client: 59,
  price_supplier: 38,
  unit: 'uren',
  type: 'Stoelmassage',
  description: 'Massages van 20 minuten, omslagtijd inbegrepen, voordeeltarief',
  quantity: 0,
  discount: 0,
  sequence: 10
};

export const secondary: Line = {
  name: 'ver',
  price_client: 0.42,
  price_supplier: 0.42,
  unit: 'km',
  type: 'Verplaatsing',
  description: 'Schatting',
  quantity: 70,
  discount: 0,
  sequence: 20
};



// Context (to read filter values) prevents columns re-creation when filters change/lose focus
export const filterContext = createContext<Filter | undefined>(undefined);

export function getProductSummaryRows(rows: any) {
  let summaryRow: Summary = {
    name: 'total_0',
    totalCount: rows.length
  };
  return [summaryRow];
}

export function getProductColumns(filters: any, setFilters: any): readonly Column<Product, Summary>[] {
  return [
    SelectColumn,
    {
      key: 'id',
      name: 'ID',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: '',
      width: 150,
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      cellClass: 'highlight-name',
      summaryFormatter(props: SummaryFormatterProps<Summary, Product>) { return <>{props.row.totalCount} in total</>; },
      headerCellClass: 'filter-cell',
      headerRenderer: (props: HeaderRendererProps<Product, Summary>) => (
        <ProductFilterRenderer<Product, Summary, HTMLInputElement> {...props}>
          {({ filters, ...rest }) => (
            <input {...rest}
              className="filters"
              value={filters.name}
              onChange={(e) => setFilters({ name: e.target.value })}
              onKeyDown={Format.inputStopPropagation}
            />
          )}
        </ProductFilterRenderer>
      )
    },
    {
      key: 'price_client',
      name: 'Prijs',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 100,
      formatter(props) { return <Format.CurrencyFormatter value={props.row.price_client} />; }
    },
    {
      key: 'price_supplier',
      name: 'Kost',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      cellClass: 'highlight-status',
      width: 100,
      formatter(props) { return <Format.CurrencyFormatter value={props.row.price_supplier} />; }
    },
    {
      key: 'unit',
      name: 'Eenheid',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 40
    },
    {
      key: 'type',
      name: 'Soort',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 180
    },
    {
      key: 'description',
      name: 'Beschrijving',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      cellClass: 'text-left'
    }
  ];
};

export function getProductFilteredSortedRows(rows: any, productSortColumns: any, filters: any): readonly Product[] {
  // first filter, then sort
  const filteredRows = rows.filter((r: any) => {
    if (!filters.name) return true;
    const str = filters.name.toLowerCase();
    return (r.name.toLowerCase().includes(str) || r.unit?.toLowerCase().includes(str)
      || r.type?.toLowerCase().includes(str) || r.description?.toLowerCase().includes(str)
    );
  });
  if (productSortColumns.length === 0) return filteredRows;
  const sortedRows = [...filteredRows];
  sortedRows.sort((a, b) => {
    for (const sort of productSortColumns) {
      const comp = comparator(sort.columnKey);
      const compResult = comp(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
}


export function getLineColumns(products: ReadonlyMap<string, any>): readonly Column<Line, Summary>[] {
  return [
    {
      ...SelectColumn,
      cellClass: 'line-cell',
      headerCellClass: 'line-header'
    },
    {
      key: 'id',
      name: '',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: 'Product',
      width: 150,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header',
      editor: (props: any) => Format.ForeignEditor(props, products, true),
      formatter({row}) {
        return <>{Array.from(products).find(([k, v]) => v.name === row.name)![1]!.name}</>;
      }
    },
    {
      key: 'quantity',
      name: 'Volume',
      editor: Format.TextEditor,
      //editorOptions: Format.textEditorOptions(),
      width: 100,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header',
      formatter({row}) { return <>{row.quantity} {row.unit}</>; }
    },
    {
      key: 'price_client',
      name: 'Prijs',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 40,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header',
      formatter({row}) { return <Format.CurrencyFormatter value={row.price_client} />; }
    },
    {
      key: 'discount',
      name: 'Korting',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 40,
      cellClass: 'text-center line-cell',
      headerCellClass: 'line-header',
      formatter({row}) { return <>{row.discount > 0 ? `${row.discount}%` : '-'}</>}
    },
    {
      key: 'price_supplier',
      name: 'Kost',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 40,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header',
      formatter({row}) { return <Format.CurrencyFormatter value={row.price_supplier} />; }
    },
    {
      key: 'type',
      name: 'Soort',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 200,
      cellClass: 'line-cell',
      headerCellClass: 'line-header'
    },
    {
      key: 'description',
      name: 'Beschrijving',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      cellClass: 'line-cell',
      headerCellClass: 'line-header'
    },
    {
      key: 'unit',
      name: 'Eenheid',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 40,
      cellClass: 'text-center line-cell',
      headerCellClass: 'line-header'
    },
    {
      key: 'sequence',
      name: 'Volgorde',
      editor: Format.TextEditor,
      editorOptions: Format.textEditorOptions(),
      width: 40,
      cellClass: 'line-cell',
      headerCellClass: 'line-header'
    },
  ];
}

export function getLineSortedRows(rows: any, lineSortColumns: any): readonly Line[] {

  if (lineSortColumns.length === 0) return rows;
  const sortedRows = [...rows];
  sortedRows.sort((a, b) => {
    for (const sort of lineSortColumns) {
      const comp = comparator(sort.columnKey);
      const compResult = comp(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
}


function comparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case 'id':
    case 'name':
    case 'price_client':
    case 'price_supplier':
    case 'unit':
    case 'type':
    case 'description':
      return (a: any, b: any) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

function ProductFilterRenderer<R, SR, T extends HTMLOrSVGElement>(
  { isCellSelected, column, children }: HeaderRendererProps<R, SR> &
  { children: (args: { ref: React.RefObject<T>; tabIndex: number; filters: Filter; }) => React.ReactElement; }
) {
  const filters = useContext(filterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  return (<><div>{column.name}</div><div>{children({ ref, tabIndex, filters })}</div></>);
}


// https://github.com/adazzle/react-data-grid/blob/main/src/hooks/useFocusRef.ts
const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
  const ref = useRef<T>(null);
  //useLayoutEffect(() => { if (!isSelected) return; ref.current?.focus({ preventScroll: true }); }, [isSelected]);
  return { ref, tabIndex: isSelected ? 0 : -1 };
}
