import { useIonViewWillEnter, useIonViewDidLeave, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonButton, IonIcon, IonToast } from '@ionic/react';
import * as icons from 'ionicons/icons';
import { useRef, useEffect, useState, useMemo } from 'react';
// (see bottom too) https://github.com/adazzle/react-data-grid/blob/main/src/hooks/useFocusRef.ts
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { DateTime } from 'luxon';

import { Firestore, collection, getDoc, getDocs, doc, addDoc, updateDoc, deleteDoc, query, orderBy, limit, where } from 'firebase/firestore/lite';
import * as fs from '../firebase-init';

import DataGrid from 'react-data-grid';
import type { Column, SortColumn, DataGridHandle } from 'react-data-grid';

import * as Entity from './Entity';
import * as Report from './Report';
import * as Product from './Product';
import * as Template from './Template';
import * as Export from './exportUtils';
import * as Format from './formatUtils';
import './ExploreContainer.css';

interface ContainerProps {
  name: string;
}



/******* Firebase low-level crud *******/


// Return an array
async function getMaster(conn: any, coll: string, year: number = 0, month: number = 0) {
  console.log(`getting ${coll}..`);
  const collRef = collection(conn, coll);
  let add:{expandParent?: string; expandRole?: string; expanded?: boolean} = {};
  let q;
  if (['orders', 'contracts', 'invoices', 'creditnotes', 'clients', 'suppliers'].includes(coll)) {
    add.expandParent = '';
    add.expandRole = 'MASTER';
    add.expanded = false;
  }
  if (['orders', 'contracts', 'invoices', 'creditnotes'].includes(coll)) {
    const prefix = coll === 'orders' ? 'VO/' :
      (coll === 'contracts' ? 'IO/' : (coll === 'creditnotes' ? 'CN/' : 'VF/'));

    if (year && month) {
      // https://www.timeglobal.cn/programming/q_4284/google-firestore-query-on-substring-of-a-property-value-text-search/
      q = query(collRef, orderBy('loc_date', 'desc'), where('loc_date', '>=', year.toString() + '-' + month.toString().padStart(2, '0')), where('loc_date', '<=', year.toString() + '-' + month.toString().padStart(2, '0') + '\uf8ff'));


    } else if (year) {
      // https://www.timeglobal.cn/programming/q_4284/google-firestore-query-on-substring-of-a-property-value-text-search/
      q = query(collRef, orderBy('name', 'desc'), where('name', '>=', prefix + year.toString()), where('name', '<=', prefix + year.toString() + '\uf8ff'));

    } else {
      q = query(collRef, orderBy('name', 'desc'));
    }


  } else {
    q = query(collRef, orderBy('name'));
  }

  //console.log(q);


  const collSnapshot = await getDocs(q);

  const list = collSnapshot.docs.map(doc => {
    //if (doc.data().id) console.log('doc has id', doc.id);
    //if (!doc.id) console.log('doc is faulty', doc);
    return Object.assign(doc.data(), { id: doc.id }, add);
  });
  return list;

  //return [];
}

// Returns a map: ids as Map keys containing the row
async function getForeign(conn: any, coll: string) {
  console.log(`getting ${coll}..`);
  const collRef = collection(conn, coll);
  const q = query(collRef, orderBy("name")); //, limit(10)
  const collSnapshot = await getDocs(q);
  let list = new Map();
  collSnapshot.docs.map(doc => list.set(doc.id, doc.data()));
  return list;
}

// Turns master array into foreign map (for pages whose lines require parent lookup)
function turnMasterIntoForeign(masterArr: any) {
  console.log(`cloning master..`);
  return new Map<string, any>(masterArr.map((val: any) => {
    const clone = Object.assign({}, val);
    const id = clone.id;
    ['id', 'expandParent', 'expandRole', 'expanded'].map(field => {
      if (field in clone) delete clone[field];
    });
    return [id, val];
  }))
}

// getLastForeignName returns the last name added (e.g. VO/2022/016)
async function getNextForeignName(conn: any, coll: string, year: number) {
  console.log(`getting next ${coll.slice(0, -1)} name..`);
  const prefix = coll === 'orders' ? 'VO/' : (coll === 'contracts' ? 'IO/' : 'VF/');
  const collRef = collection(conn, coll);
  const q = query(collRef, orderBy('name', 'desc'), where('name', '>=', prefix + year.toString()), where('name', '<=', prefix + year.toString() + '\uf8ff'), limit(1));
  const collSnapshot = await getDocs(q);
  if (collSnapshot.docs.length) {
    let p = collSnapshot.docs[0].data()?.name.split('/');
    return [p[0], p[1], (parseInt(p[2]) + 1).toString().padStart(3, '0')].join('/');
  }
  // No existing records found in the currently selected year, so start anew
  return prefix + year.toString() + '/001';
}

async function getDetails(conn: any, coll: string, parentId: string | undefined, suppliers: any, clients: any) {

  if (parentId) {
    parentId = parentId.replace('_detail', '');
    const parentDoc = await getDoc(doc(conn, coll, parentId));
    let parent: any = { ...parentDoc.data(), id: parentDoc.id };

    return ['orders', 'contracts', 'invoices', 'creditnotes'].includes(coll) ?
      await getFullReport(conn, coll, parent, suppliers, clients) :
      await getFullEntity(conn, coll, parent);

  } else return {};
}

async function getFullEntity(conn: any, coll: string, parent: any) {
  const lineColl = coll === 'suppliers' ? 'contracts' : 'orders';
  const collRef = collection(conn, lineColl);
  const q = query(collRef, where('contact_' + coll.slice(0, -1), '==', parent.id), orderBy('name', 'desc')); //, limit(5)
  const collSnapshot = await getDocs(q);

  // const reportIds = collSnapshot.docs.map(doc => doc.id);


  parent.lines = await Promise.all(collSnapshot.docs.map(async doc => {
    if (lineColl === 'orders') {
      // Getting lines of lines, i.e. a client's orders' productlines
      const lineRef = collection(conn, `${lineColl}/${doc.id}/lines`);
      const q = query(lineRef, orderBy('sequence'));
      const lineSnapshot = await getDocs(q);
      const lines = lineSnapshot.docs.map(ldoc => {
        return {...Product.line, id: ldoc.id, ...ldoc.data()};
      });
      return { ...Entity.client, id: doc.id, ...doc.data(), lines };
    }

    return { ...Entity.supplier, id: doc.id, ...doc.data() };
  }));

  return parent;
}

async function getFullReport(conn: any, coll: string, parent: any, suppliers: any = false, clients: any = false) {
  const collRef = collection(conn, `${coll}/${parent.id}/lines`);
  const q = query(collRef, orderBy('sequence'));
  const collSnapshot = await getDocs(q);

  Object.keys(parent).filter((prop: any) => parent[prop]).forEach((prop: any) => {
    if (coll === 'invoices' || coll === 'creditnotes') {
      if (clients && prop.includes('client_origin')) {
        let client = clients.get(parent[prop]) || {};
        parent[prop] = Object.assign(client, {id: parent[prop]});
      }
    } else {
      if (clients && prop.includes('_client')) {
        let client = clients.get(parent[prop]) || {};
        parent[prop] = Object.assign(client, {id: parent[prop]});
      }
      if (suppliers && prop.includes('_supplier')) {
        let supplier = suppliers.get(parent[prop]) || {};
        parent[prop] = Object.assign(supplier, {id: parent[prop]});
      }
    }
  });

  parent.lines = collSnapshot.docs.map(doc => {
    return {...Product.line, id: doc.id, ...doc.data()};
  });

  return parent;
}

async function add(conn: any, col: string, data: object) {
  const docRef = await addDoc(collection(conn, col), data);
  return docRef;
}

async function remove(conn: any, col: string, id: string) {
  const docRef = await deleteDoc(doc(conn, col, id));
  return docRef;
}

// data may be partial
async function update(conn: any, col: string, id: string, data: object) {
  const docRef = await updateDoc(doc(conn, col, id), data);
  return docRef;
}

async function addSub(conn: any, col: string, subCol: string, parentId: string, data: Product.Line[]) {
  const colRef = collection(conn, `${col}/${parentId}/${subCol}`);
  let docRef;
  for (let i = 0; i < data.length; i++) {
  //data.forEach(async (line: Product.Line) => {
    delete data[i].id;
    docRef = await addDoc(colRef, data[i]);
  }
  //const docRef = await addDoc(colRef, data);
  return docRef;
}

async function updateSub(conn: any, col: string, subCol: string, parentId: string, id: string, data: any) {
  //const colRef = collection(conn, `${col}/${parentId}/${subCol}`);
  const docRef = doc(conn, `${col}/${parentId}/${subCol}`, id);
  await updateDoc(docRef, data);
  return docRef;
}

async function removeSub(conn: any, col: string, subCol: string, parentId: string, id: string) {
  //const colRef = collection(conn, `${col}/${parentId}/${subCol}`);
  const docRef = doc(conn, `${col}/${parentId}/${subCol}`, id);
  await deleteDoc(docRef);
  return docRef;
}





/******************************** REACT COMPONENT ********************************/

const ExploreContainer: React.FC<ContainerProps> = ({name: collectionName}) => {
  const [conn, setConn] = useState<any>();
  const [google, setGoogle] = useState<any>();

  const [showConfirmDeleteRows, setShowConfirmDeleteRows] = useState(false);


  useIonViewWillEnter(async () => {

    let [newConn, newGoogle]: any = await fs.connect(conn, google);

    setConn(newConn);
    setGoogle(newGoogle);

    // Sorting a column and then switching collection fails without the below
    clientSortCols && setClientSortCols([]);
    supplierSortCols && setSupplierSortCols([]);
    templateSortCols && setTemplateSortCols([]);
    orderSortCols && setOrderSortCols([]);
    contractSortCols && setContractSortCols([]);
    invoiceSortCols && setInvoiceSortCols([]);
    creditnoteSortCols && setCreditnoteSortCols([]);
    productSortCols && setProductSortCols([]);
    lineSortCols && setLineSortCols([]);

    // Determine loading schedule (always load master; foreign only when needed)
    const mem: any = { master: new Map(), products, suppliers, clients, templates };
    let load = ['master'];

    switch (collectionName) { // (deliberate fallthrough: no breaks)
      case 'contracts':
      case 'orders':
      case 'invoices':
      case 'creditnotes': load.push('suppliers', 'products', 'templates');
      case 'clients':
      case 'suppliers': load.push('clients');
    }

    let data = await Promise.all(load.map((k) => {
      if (k === 'master') {
        return getMaster(newConn, collectionName, curYear, curMonth);
      }
      // return mem[k].size ? mem[k] : getForeign(newConn, k);
      if (mem[k].size) {
        return mem[k];
      }
      if (k === collectionName) {
        return false;
      }
      return getForeign(newConn, k);
    }));

    const fMast = data.includes(false) ? turnMasterIntoForeign(data[0]) : new Map();

    load.forEach((k: string, i: number) => {
      if (mem[k].size === 0) {
        k === 'master' && setRows(data[i]);
        k === 'products' && setProducts(data[i]);
        k === 'suppliers' && setSuppliers(data[i] || fMast);
        k === 'clients' && setClients(data[i] || fMast);
        k === 'templates' && setTemplates(data[i]);
      }
    });

    //entitySortCols && setEntitySortCols([]); // todo: necessary?
    selectedRows && setSelectedRows(() => new Set());

    // Remove any focus from previous view
    gridRef.current && gridRef.current.selectCell({
      idx: ['products', 'templates'].includes(collectionName) ? 1 : 2,
      rowIdx: 0
    });

  }, [collectionName]);


  const [toast, setToast] = useState('');
  const gridRef = useRef<DataGridHandle>(null);
  const [curYear, setCurYear] = useState<number>(new Date().getFullYear());
  const [curMonth, setCurMonth] = useState<number>(0); // new Date().getMonth()
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(() => new Set());
  const [scheduleParent, setScheduleParent] = useState<any>({});

  //const [filters, setFilters] = useState<Entity.Filter>({ name: '' });
  const [clientFilter, setClientFilter] = useState<Entity.Filter>({name: ''});
  const [supplierFilter, setSupplierFilter] = useState<Entity.Filter>({name: ''});
  const [orderFilter, setOrderFilter] = useState<Report.Filter>({name: ''});
  const [contractFilter, setContractFilter] = useState<Report.Filter>({name: ''});
  const [invoiceFilter, setInvoiceFilter] = useState<Report.Filter>({name: ''});
  const [creditnoteFilter, setCreditnoteFilter] = useState<Report.Filter>({name: ''});
  const [productFilter, setProductFilter] = useState<Product.Filter>({name: ''});
  const [templateFilter, setTemplateFilter] = useState<Template.Filter>({name: ''});

  const [clients, setClients] = useState<Map<string, any>>(() => new Map());
  const [suppliers, setSuppliers] = useState<Map<string, any>>(() => new Map());

  const [templates, setTemplates] = useState<Map<string, any>>(() => new Map());
  const [products, setProducts] = useState<Map<string, any>>(() => new Map());

  const clearFilters = () => {
    const f = { name: '' };
    //setFilters(f);
    switch (collectionName) {
      case 'clients': setClientFilter(f); break;
      case 'suppliers': setSupplierFilter(f); break;
      case 'orders': setOrderFilter(f); break;
      case 'contracts': setContractFilter(f); break;
      case 'invoices': setInvoiceFilter(f); break;
      case 'creditnotes': setCreditnoteFilter(f); break;
      case 'products': setProductFilter(f); break;
      case 'templates': setTemplateFilter(f); break;
    }

    gridRef.current && gridRef.current.selectCell({
      idx: ['products', 'templates'].includes(collectionName) ? 1 : 2,
      rowIdx: 0
    });
  }

  const [clientSortCols, setClientSortCols] = useState<readonly SortColumn[]>([]);
  const [supplierSortCols, setSupplierSortCols] = useState<readonly SortColumn[]>([]);
  const [templateSortCols, setTemplateSortCols] = useState<readonly SortColumn[]>([]);
  const [orderSortCols, setOrderSortCols] = useState<readonly SortColumn[]>([]);
  const [contractSortCols, setContractSortCols] = useState<readonly SortColumn[]>([]);
  const [invoiceSortCols, setInvoiceSortCols] = useState<readonly SortColumn[]>([]);
  const [creditnoteSortCols, setCreditnoteSortCols] = useState<readonly SortColumn[]>([]);
  const [productSortCols, setProductSortCols] = useState<readonly SortColumn[]>([]);
  const [lineSortCols, setLineSortCols] = useState<readonly SortColumn[]>([]);



  /******* Instanced Entities (Clients) *******/

  const clientColumns = useMemo(() => Entity.getClientColumns(collectionName, clientFilter, setClientFilter, gridRef, LineGrid), [rows, collectionName]); // rows necessary

  const clientSummaryRows = useMemo(() => Entity.getSummaryRows(rows), [rows]);

  const clientFilteredSortedRows = useMemo(() => {
    return Entity.getFilteredSortedRows(rows, clientSortCols, clientFilter)
  }, [rows, clientSortCols, clientFilter]);

  /******* Instanced Entities (Suppliers) *******/

  const supplierColumns = useMemo(() => Entity.getSupplierColumns(collectionName, supplierFilter, setSupplierFilter, gridRef, LineGrid), [rows, collectionName]);

  const supplierSummaryRows = useMemo(() => Entity.getSummaryRows(rows), [rows]);

  const supplierFilteredSortedRows = useMemo(() => {
    return Entity.getFilteredSortedRows(rows, supplierSortCols, supplierFilter)
  }, [rows, supplierSortCols, supplierFilter]);


  /******* Instanced Orders *******/

  const orderColumns = useMemo(() => {
    return Report.getOrderColumns(orderFilter, setOrderFilter, gridRef, LineGrid, clients)
  }, [rows, collectionName, clients]);
  const orderSummaryRows = useMemo(() => Report.getOrderSummaryRows(rows), [rows]);
  const orderFilteredSortedRows = useMemo(() => {
    return Report.getOrderFilteredSortedRows(rows, orderSortCols, orderFilter, clients)
  }, [rows, orderSortCols, orderFilter]);

  /******* Instanced Invoices *******/

  const invoiceColumns = useMemo(() => {
    return Report.getInvoiceColumns(invoiceFilter, setInvoiceFilter, gridRef, LineGrid, clients, updateForeigner)
  }, [rows, collectionName, clients]);
  const invoiceSummaryRows = useMemo(() => Report.getInvoiceSummaryRows(rows), [rows]);
  const invoiceFilteredSortedRows = useMemo(() => {
    return Report.getInvoiceFilteredSortedRows(rows, invoiceSortCols, invoiceFilter)
  }, [rows, invoiceSortCols, invoiceFilter]);

  /******* Instanced Creditnotes *******/

  const creditnoteColumns = useMemo(() => {
    return Report.getInvoiceColumns(creditnoteFilter, setCreditnoteFilter, gridRef, LineGrid, clients, updateForeigner)
  }, [rows, collectionName, clients]);
  const creditnoteSummaryRows = useMemo(() => Report.getInvoiceSummaryRows(rows), [rows]);
  const creditnoteFilteredSortedRows = useMemo(() => {
    return Report.getInvoiceFilteredSortedRows(rows, creditnoteSortCols, creditnoteFilter)
  }, [rows, creditnoteSortCols, creditnoteFilter]);

  /******* Instanced Contracts *******/

  const contractColumns = useMemo(() => {
    return Report.getContractColumns(rows, contractFilter, setContractFilter, gridRef, LineGrid, clients, suppliers)
  }, [rows, collectionName, clients, suppliers]);
  const contractSummaryRows = useMemo(() => Report.getContractSummaryRows(rows), [rows]);
  const contractFilteredSortedRows = useMemo(() => {
    return Report.getContractFilteredSortedRows(rows, contractSortCols, contractFilter, clients, suppliers)
  }, [rows, contractSortCols, contractFilter]);

  /******* Instanced Templates *******/

  const templateColumns = useMemo(() => {
    return Template.getColumns(templateFilter, setTemplateFilter, gridRef);
  }, [rows, collectionName]);
  const templateSummaryRows = useMemo(() => Template.getSummaryRows(rows), [rows]);
  const templateFilteredSortedRows = useMemo(() => {
    return Template.getFilteredSortedRows(rows, templateSortCols, templateFilter);
  }, [rows, templateSortCols, templateFilter]);

  /******* Instanced Products *******/

  const productColumns = useMemo(() => {
    return Product.getProductColumns(productFilter, setProductFilter)
  }, [rows, collectionName]);
  const productSummaryRows = useMemo(() => Product.getProductSummaryRows(rows), [rows]);
  const productFilteredSortedRows = useMemo(() => {
    return Product.getProductFilteredSortedRows(rows, productSortCols, productFilter)
  }, [rows, productSortCols, productFilter]);


  /******* Instanced Order lines & grid *******/


  function LineGrid(
    { parentId, isCellSelected }:
    {
      parentId: string | undefined;
      isCellSelected: boolean;
    }) {
    const lineGridRef = useRef<DataGridHandle>(null);
    const [lines, setLines] = useState<any>([]); //CHANGED Product.Line[]
    const [parent, setParent] = useState<any>();
    const [selectedLines, setSelectedLines] = useState<ReadonlySet<string>>(() => new Set());
    const [template, setTemplate] = useState<string>('');
    const [showDistances, setShowDistances] = useState(false);
    const [showDistancesAgain, setShowDistancesAgain] = useState(false);
    const [showRecruitment, setShowRecruitment] = useState(false);
    const [showContractors, setShowContractors] = useState(false);

    const [showConfirmDeleteEvent, setShowConfirmDeleteEvent] = useState(false);
    const [showConfirmDeleteSheet, setShowConfirmDeleteSheet] = useState(false);
    const [showConfirmDeleteLine, setShowConfirmDeleteLine] = useState(false);
    const [showSelectResult, setShowSelectResult] = useState(false);
    const [resultOptions, setResultOptions] = useState<any[]>([]);
    const [showSoldResult, setShowSoldResult] = useState(false);
    const [showFilledResult, setShowFilledResult] = useState(false);
    const [resultText, setResultText] = useState<string>('');

    useEffect(() => {
      if (isCellSelected) {

        if (!parent || parentId?.replace('_detail', '') !== parent.id) {
          selectedLines && setSelectedLines(() => new Set());

          fs.checkFirestore(conn, setConn).then((connection: any) => {
            getDetails(connection, collectionName, parentId, suppliers, clients).then(par => {
              if (par) {
                setParent(par);
                setLines(par.lines);
              }
            });
          });
        }
        // lineGridRef.current!.element!.querySelector<HTMLDivElement>('.class')
      }
    }, [isCellSelected, parentId, suppliers, clients, products, templates]); // CHANGED parent

    const lineColumns = useMemo(() => {
      return Product.getLineColumns(products);
    }, [collectionName, products]);

    const reportLineColumns = useMemo(() => {
      return Report.getLineColumns(clients);
    }, [collectionName, parent, clients]);


    async function updateAmounts(newLines: any) {
      let untaxedCl = 0, untaxedSup = 0;
      const isContract = 'contact_supplier' in parent;

      newLines.forEach((r: any) => {
        untaxedCl += (1 - r.discount / 100) * r.price_client * r.quantity;
        untaxedSup += r.price_supplier * r.quantity;
      });

      const vatPerc = isContract ? parent.contact_supplier.vat_perc :
        ('invoice_client' in parent ? parent.invoice_client.vat_perc :
          parent.client_vat_perc);

      const amount_margin = Math.round((untaxedCl - untaxedSup) * 100) / 100;
      let amount_untaxed = isContract ? untaxedSup : untaxedCl;
      let amount_tax = amount_untaxed * (vatPerc / 100);

      const amount_total = Math.round((amount_untaxed + amount_tax) * 100) / 100;
      amount_untaxed = Math.round(amount_untaxed * 100) / 100;
      amount_tax = Math.round(amount_tax * 100) / 100;

      setToast(`Amounts for ${parent.name} successfully updated.`);
      const assign = { amount_untaxed, amount_tax, amount_total, amount_margin };
      setParent(await updateRow(parent.id, assign));
    }

    async function updateLines(changedRows: any[], changeData: any, parentId: string) {

      setLines(changedRows);

      for (const i of changeData.indexes) {
        if (Format.different(changedRows[i], lines[i])) {
          let clone = Object.assign({}, changedRows[i]);
          delete clone.id;
          const connection = await fs.checkFirestore(conn, setConn);
          await updateSub(connection, collectionName, 'lines', parentId, changedRows[i].id, clone);
        }
      }

      const updateAffectsAmounts = ['name', 'quantity', 'price_client', 'price_supplier', 'discount'].includes(changeData.column.key);

      if ('quantity' in changedRows[0] && updateAffectsAmounts) {
        await updateAmounts(changedRows);
      }
    }

    async function insertPrimaryLinesFromCode(parent: any) {

      if (parent.code) {
        for (const [i, code] of parent.code.split('+').entries()) {

          const mi = Format.parseCode(parent, i);

          // Build productcode e.g. '20 min 3u'
          let p: string[] = [mi.duration.massage.toString()];
          if (mi.duration.session.anyPresence / 60 > 3) {
            p.push('min 3u');
          } else if (mi.duration.session.anyPresence / 60 > 2) {
            p.push('max 3u');
          } else if (mi.duration.session.anyPresence / 60 > 1) {
            p.push('max 2u');
          } else if (mi.duration.session.anyPresence / 60 > 0.5) {
            p.push('max 1u');
          } else {
            p.push('per 1');
          }
          const productCode = p.join(' ');

          // Find product corresponding to productcode
          const product = Array.from(products).find(([k, v]) => {
            //console.log(v, k);
            //v.id = k.toString();
            return v.name === productCode;
          });

          // Add product line with mission parameters
          if (product) {
            let newLine = Object.assign(Product.primary, {
              name: product[1].name,
              quantity: mi.duration.mission,
              price_client: product[1].price_client,
              price_supplier: product[1].price_supplier,
              type: product[1].type,
              description: product[1].description,
              unit: product[1].unit
            });

            await insertReportLine(parent.id, newLine);

            if (newLine.quantity) {
              updateAmounts([...lines, newLine]);
            }
          }
        }
      }
    }

    async function insertReportLine(parentId: string, newLine: any) {
      const connection = await fs.checkFirestore(conn, setConn);
      const docRef = await addSub(connection, collectionName, 'lines', parentId, [newLine]);
      newLine.id = docRef!.id;
      setLines([...lines, newLine]);
    }

    async function insertLine(parent: any, type: string, assign: any = false) {
      let newLine = type === 'secondary' ? Product.secondary : Product.line;

      if (type === 'secondary' && parent.loc_client && assign) {
        newLine = Object.assign(newLine, assign);
      }

      await insertReportLine(parent.id, newLine);

      if (newLine.quantity) {
        updateAmounts([...lines, newLine]);
      }
    }

    async function duplicateLine() {
      if (selectedLines?.size === 1) {
        const id = [...Array.from(selectedLines.values())][0].toString();
        let dupeLine = Object.assign({}, lines.find((r: any) => r.id === id));
        delete dupeLine.id;
        //if (dupeRow.name) dupeRow.name = 'Copy ' + dupeRow.name;
        //const docRef = await add(collectionName, dupeRow);
        const connection = await fs.checkFirestore(conn, setConn);
        const docRef = await addSub(connection, collectionName, 'lines', parent.id, [dupeLine]);
        dupeLine.id = docRef!.id;
        setSelectedLines(() => new Set());
        const newLines = [...lines, dupeLine];
        setLines(newLines);
        updateAmounts(newLines);
      }
    }

    async function deleteLines() {
      if (selectedLines?.size) {
        // leveraging from()'s 2nd param to iterate over the Set-turned-Array
        Array.from(selectedLines.values(), async (id) => {
          const connection = await fs.checkFirestore(conn, setConn);
          await removeSub(connection, collectionName, 'lines', parent.id, id.toString());
        });
        const newLines = lines.filter((r: any) => !selectedLines.has(r.id || ''));
        setLines(newLines);
        updateAmounts(newLines);
      }
    }

    async function mapLine(assign: any) {
      let dex: number = -1;
      if (selectedLines?.size === 1) {
        const id = [...Array.from(selectedLines.values())][0].toString();
        dex = lines.findIndex((l: any) => l.id === id);
      } else {
        dex = lines.findIndex((l: any) => l.unit === 'km');
      }
      if (dex === -1) {
        return setToast('There\'s no product line using a distance unit.');
      }
      const strippedLines = lines.filter((l: any, i: number) => i !== dex);
      let newLine = Object.assign({}, lines[dex], assign);
      const newLines = [ ...strippedLines, newLine ];
      setLines(newLines);

      let clone = Object.assign({}, newLine);
      delete clone.id;
      const connection = await fs.checkFirestore(conn, setConn);
      await updateSub(connection, collectionName, 'lines', parent.id, newLine.id, clone);

      updateAmounts(newLines);
    }

    function openRonselator() {
      const url = 'https://massageatwork.be/builder.html?code=';
      // Omiting selfmargin in url because Ronselator recalculates it anyway
      //const mission = await Format.addSelfMargin(Format.parseCode(parent));
      const mission = Format.parseCode(parent);
      window.open(url + mission.formula.replace('%', '%25'), '_blank');
    }

    async function openSheet() {
      // Early exit conditions
      if (['invoices', 'creditnotes', 'contracts'].includes(collectionName) && !parent.sheet) {
        return setToast(`The ${collectionName.slice(0, -1)} has no sheet.`);
      }

      if (!parent.loc_date) {
        return setToast(`The ${collectionName.slice(0, -1)} has no start time. No sheet was created.`);
      }

      // Open sheet if present
      if (parent.sheet) {
        return window.open(parent.sheet, '_blank');
      }

      // Generate the sheet(s) object and create the sheet
      const sheetBody = Format.getSheetBody(parent);
      const response = await google.sheets.create({}, sheetBody);

      if (!response.result.spreadsheetUrl) {
        console.error(response);
        return setToast(`Something went wrong on Google's end.`);
      }

      // Set the sheet's permissions on Google Drive to 'anyone can write'
      const perms = await google.drive.permissions.create({
        fileId: response.result.spreadsheetId,
        resource: { type: 'anyone', role: 'writer' }
      });

      // Add the sheet to the parent's event, if it exists
      let sheetAddedToEvent = false;

      if (parent.eventId) {

        const ops = {
          prependDescr: `<br /><a target="_blank" href="${response.result.spreadsheetUrl}">${response.result.spreadsheetUrl}</a>`
        };
        if (await Format.modifyEvent(google.cal, parent.eventId.split('|'), ops)) {
          sheetAddedToEvent = true;
        }
      }

      // Update the parent
      const assign = { sheet: response.result.spreadsheetUrl };
      setParent(await updateRow(parent.id, assign));

      return setToast(`Sheet created for ${parent.code}${sheetAddedToEvent ? ' and added to calendar event' : ', but failed adding it to event'}. Click again to go there.`);

    }

    async function deleteSheet() {
      // Early exit conditions
      if (!parent.sheet) {
        return setToast(`The ${collectionName.slice(0, -1)} has no sheet.`);
      }

      // delete event(s) if present
      if (parent.sheet) {

        setParent(await updateRow(parent.id, { sheet: '' }));

        return setToast(`Sheet deleted from Blitzee. The URL remains accessible.`);
      }
    }

    async function getEvent() {
      if (!parent.eventId) {
        return setToast(`The ${collectionName.slice(0, -1)} has no calendar event.`);
      }
      const options = {
        calendarId: 'primary',
        eventId: parent.eventId
      };
      const event = await google.cal.events.get(options);
      console.log(event);
    }

    async function openEvent() {
      // Early exit conditions
      if (['invoices', 'creditnotes', 'contracts'].includes(collectionName) && !parent.eventId) {
        return setToast(`The ${collectionName.slice(0, -1)} has no calendar event.`);
      }

      if (!parent.lines) {
        return setToast(`The ${collectionName.slice(0, -1)} has no product lines. No event was created.`);
      }

      if (!parent.loc_date) {
        return setToast(`The ${collectionName.slice(0, -1)} has no start time. No event was created.`);
      }

      // Open event url(s) if present
      if (parent.event) {
        for (const eventUrl of parent.event.split('|')) {
          window.open(eventUrl, '_blank');
        }
        return true;
      }

      let ids = [];
      let urls = [];

      for (const [i, code] of parent.code.split('+').entries()) {

        const mi = await Format.addSelfMargin(Format.parseCode(parent, i));

        const nl = "\n";
        let description = parent.sheet ?
          `Sheet: ${parent.sheet}${nl}` : '';
        description += parent.contact_client.contact_email ?
          `Email: ${parent.contact_client.contact_email}${nl}` : '';
        description += parent.contact_client.contact_name ?
          `Contact: ${parent.contact_client.contact_name}${nl}` : '';
        description += `${nl}${parent.text}`;

        const options = {
          calendarId: 'primary',
          resource: {
            summary: mi.formula,
            location: mi.address,
            description,
            start: {
              dateTime: parent.loc_date.replace(' ', 'T') + ':00', // '2022-02-01T09:00:00',
              timeZone: 'Europe/Brussels'
            },
            end: {
              dateTime: parent.loc_date.slice(0, -6) + 'T' + mi.time.stop.replace('u', ':') + ':00',
              timeZone: 'Europe/Brussels'
            },
            extendedProperties: {
              shared: { 'X-MOZ-CATEGORIES': '2. Offerte gestuurd' }
            }
          }
        };

        const event = await google.cal.events.insert(options);

        if (!event.result.htmlLink) {
          console.error(event);
          return setToast(`Something went wrong on Google's end.`);
        }

        ids.push(event.result.id);
        urls.push(event.result.htmlLink);
      }

      setParent(await updateRow(parent.id, { eventId: ids.join('|'), event: urls.join('|') }));

      return setToast(`Event${ids.length > 1 ? 's' : ''} created on ${parent.loc_date}. Click again to go there.`);
    }

    async function deleteEvent() {
      // Early exit conditions
      if (!parent.eventId) {
        return setToast(`The ${collectionName.slice(0, -1)} has no calendar event.`);
      }

      // delete event(s) if present
      if (parent.eventId) {

        let ids = [];

        for (const eventId of parent.eventId.split('|')) {

          const options = {
            calendarId: 'primary',
            eventId
          };

          try {
            const ev = await google.cal.events.delete(options);

            if (![204, 410].includes(ev.status)) {
              console.error(ev);
              return setToast(`Something went wrong on Google's end.`);
            }
          } catch (err) {
            console.error('Promise error caught:', err);
          }

          ids.push(eventId);
        }

        setParent(await updateRow(parent.id, { eventId: '', event: '' }));

        return setToast(`Event${ids.length > 1 ? 's' : ''} deleted. Click calendar button to create new event(s).`);
      }
    }

    // Syncs calendar event to match with Blitzee. Updates its summary, category, location, date and timing while prepending any existing description with the current one. Secondary missions get their event summary and category updated only. A secondary mission is any mission in a multicode except the first one (e.g. 40p 2mas+8p30 1mas)
    async function syncEvent() {
      // Early exit conditions
      if (!parent.eventId) {
        return setToast(`The ${collectionName.slice(0, -1)} has no calendar event.`);
      }

      if (!parent.lines) {
        return setToast(`The ${collectionName.slice(0, -1)} has no product lines. Nothing was synced.`);
      }

      if (!parent.loc_date) {
        return setToast(`The ${collectionName.slice(0, -1)} has no start time. Nothing was synced.`);
      }

      // Open event url(s) if present


      let urls = [];
      let ids = parent.eventId.split('|');

      for (const [i, code] of parent.code.split('+').entries()) {

        if (ids.length > i) {
          const mi = await Format.addSelfMargin(Format.parseCode(parent, i));

          const options = {
            calendarId: 'primary',
            resource: {
              summary: mi.formula,
              location: mi.address,
              description: "\n" + (parent.sheet ? parent.sheet + "\n\n" : '') + parent.text,
              start: {
                dateTime: parent.loc_date.replace(' ', 'T') + ':00', // '2022-02-01T09:00:00',
                timeZone: 'Europe/Brussels'
              },
              end: {
                dateTime: parent.loc_date.slice(0, -6) + 'T' + mi.time.stop.replace('u', ':') + ':00',
                timeZone: 'Europe/Brussels'
              },
              extendedProperties: {
                shared: { 'X-MOZ-CATEGORIES': '2. Offerte gestuurd' }
              }
            }
          };

          let ops: any = {
            setSummary: mi.formula,
            removeCats: [],
            addCats:
              parent.status === 'invoiced' ? ['B. Klant recycleerbaar'] :
              (parent.status === 'sealed' ? ['A. Factuur sturen'] :
              (parent.status === 'filled' ? ['8. Contracten sturen'] :
              (parent.status === 'recruited' ? ['5. Masseurs geronseld'] :
              (parent.status === 'sold' ? ['3. Masseurs nodig'] :
              (parent.status === 'sent' ? ['2. Offerte gestuurd'] : [])))))
          };

          switch (parent.status) {
            case 'draft': ops.removeCats.push('B. Klant recycleerbaar');
            case 'invoiced': ops.removeCats.push('A. Factuur sturen');
            case 'sealed': ops.removeCats.push('8. Contracten sturen');
            case 'filled': ops.removeCats.push('5. Masseurs geronseld');
            case 'recruited': ops.removeCats.push('3. Masseurs nodig');
            case 'sold': ops.removeCats.push('2. Offerte gestuurd');
          }

          if (i === 0) {
            ops = {
              setLocation: mi.address,
              setStart: {
                dateTime: parent.loc_date.replace(' ', 'T') + ':00', // '2022-02-01T09:00:00',
                timeZone: 'Europe/Brussels'
              },
              setEnd: {
                dateTime: parent.loc_date.slice(0, -6) + 'T' + mi.time.stop.replace('u', ':') + ':00',
                timeZone: 'Europe/Brussels'
              },
              prependDescr: "\n" + (parent.sheet ? parent.sheet + "\n\n" : '') + parent.text,
              ...ops
            }
          }


          if (!await Format.modifyEvent(google.cal, [ids[i]], ops)) {
            return setToast(`Something went wrong on Google's end.`);
          }

        }
      }

      return setToast(`Event${ids.length > 1 ? 's' : ''} synced to Blitzee.`);
    }


    // find existing event and link order to it  @Tangle_Labs
    async function linkEvent() {

      const mi = Format.parseCode(parent);

      const events = await google.cal.events.list({
        calendarId: 'primary',
        singleEvents: true,
        q: mi.source,
        timeMin: DateTime.now().minus({ weeks: 4 }).toISO()
      });

      if (events.status === 200 && events.result.items.length) {
        setResultOptions(events.result.items);
        setShowSelectResult(true);
      } else {
        setToast('No results.');
      }
      return [];
    }

    async function createInvoice() {

      const cl = parent.invoice_client;
      const loc = parent.loc_client;
      const connection = await fs.checkFirestore(conn, setConn);
      const invoice = {
        name: await getNextForeignName(connection, 'invoices', curYear),
        code: parent.code,
        order_date: Format.dbFormatDate(new Date()),
        order_origin: parent.name,
        client_origin: cl.id,
        client_name: cl.name,
        client_address: cl.address1 + (cl.address2 ? "\n" + cl.address2 : '') + "\n" + cl.postal + ' ' + cl.place + "\n" + cl.country,
        client_vat_nr: cl.vat_nr,
        client_vat_perc: cl.vat_perc,
        client_ref: parent.client_ref,
        loc_client: loc.name,
        loc_address: loc.address1 + (loc.address2 ? "\n" + loc.address2 : '') +
          "\n" + loc.postal + ' ' + loc.place + "\n" + loc.country,
        loc_date: parent.loc_date,
        status: 'draft',
        text: parent.text
          .replace('Formule voor', 'Factuur voor')
          .replaceAll('zal zijn', 'was')
          .replaceAll('zullen zijn', 'waren')
          .replaceAll('wordt', 'werd')
          .replaceAll('worden', 'werden')
          .replace('Deze formule is maandelijks hernieuwbaar (optioneel). ', '')
          .replace('Ons uurtarief daalt naarmate je ons langer aan het werk houdt.', '')
          .replace("\nWerken jullie graag met een uurrooster? Dan stuur ik er eentje door waar jij of jullie deelnemers op kunnen intekenen.", '')
          .replace(' We zullen ons best doen deze nog ingevuld te krijgen.', '')
          .replace('De vermelde datum is \'n eerste voorstel. Als jullie graag een andere dag willen kan dat natuurlijk ook. ', ''),
        eventId: parent.eventId || '',
        event: parent.event || '',
        sheet: parent.sheet || '',
        amount_tax: parent.amount_tax,
        amount_untaxed: parent.amount_untaxed,
        amount_total: parent.amount_total
      };

      const docRef = await add(connection, 'invoices', invoice);
      const subRef = await addSub(connection, 'invoices', 'lines', docRef.id, parent.lines);
    }

    async function createCreditnote() {

      const cl = parent.invoice_client;
      const loc = parent.loc_client;
      const connection = await fs.checkFirestore(conn, setConn);
      const creditnote = {
        name: await getNextForeignName(connection, 'creditnotes', curYear),
        code: parent.code,
        order_date: Format.dbFormatDate(new Date()),
        order_origin: parent.name,
        client_origin: cl.id,
        client_name: cl.name,
        client_address: cl.address1 + (cl.address2 ? "\n" + cl.address2 : '') + "\n" + cl.postal + ' ' + cl.place + "\n" + cl.country,
        client_vat_nr: cl.vat_nr,
        client_vat_perc: cl.vat_perc,
        client_ref: parent.client_ref,
        loc_client: loc.name,
        loc_address: loc.address1 + (loc.address2 ? "\n" + loc.address2 : '') +
          "\n" + loc.postal + ' ' + loc.place + "\n" + loc.country,
        loc_date: parent.loc_date,
        status: 'draft',
        text: parent.text.replace('Formule voor', 'Creditnota voor'),
        eventId: parent.eventId || '',
        event: parent.event || '',
        sheet: parent.sheet || '',
        amount_tax: parent.amount_tax,
        amount_untaxed: parent.amount_untaxed,
        amount_total: parent.amount_total
      };

      const docRef = await add(connection, 'creditnotes', creditnote);
      const subRef = await addSub(connection, 'creditnotes', 'lines', docRef.id, parent.lines);
    }

    async function createContract(contractor: any, team: string) {

      const c = contractor;
      const loc = parent.loc_client;
      const mi = Format.parseCode(parent);
      let firstService = parent.lines.find((l: any) => l.unit === 'uren');
      let firstTravel = parent.lines.find((l: any) => l.unit === 'km');
      firstService.quantity = Math.round(100 * mi.duration.session.anyPresence / 60) / 100;
      firstTravel.quantity = Math.ceil(2 * c.distance.value / 1000);

      let untaxed = (mi.rate.masseur * mi.duration.session.anyPresence / 60) + (firstTravel.quantity * mi.rate.travel);
      const tax = Math.round(untaxed * c.vat_perc) / 100;
      untaxed = Math.round(100 * untaxed) / 100;
      const connection = await fs.checkFirestore(conn, setConn);

      const contract = {
        name: await getNextForeignName(connection, 'contracts', curYear),
        code: parent.code,
        order_date: Format.dbFormatDate(new Date()),
        order_name: parent.name,
        contact_supplier: c.id,
        supplier_ref: '',
        loc_client: loc.id,
        loc_date: parent.loc_date,
        status: 'draft',
        text: Format.getContractDescription(parent, team),
        eventId: parent.eventId || '',
        event: parent.event || '',
        sheet: parent.sheet || '',
        amount_tax: tax,
        amount_untaxed: untaxed,
        amount_total: Math.round(100 * (tax + untaxed)) / 100
      };

      const docRef = await add(connection, 'contracts', contract);
      const subRef = await addSub(connection, 'contracts', 'lines', docRef.id, [
        firstTravel,
        firstService
      ]);
    }

    return (
      <div onKeyDown={Format.stopPropagation}>
        <div className="colButtons">

        {['orders', 'contracts', 'invoices', 'creditnotes'].includes(collectionName) &&
          <>
          <IonButton size="small" color="success" title="Creates product line(s) for primary service corresponding to current mission code, e.g. 20p 2mas+6p30 1mas" onClick={e => insertPrimaryLinesFromCode(parent)}>
            <div className="label">New</div>
            <IonIcon slot="end" ios={icons.timerSharp} md={icons.timerOutline} />
          </IonButton>

          <IonButton size="small" color="success" title="Opens the map showing supplier distances. Selecting suppliers will then create a product line for transportation corresponding to the total distance traveled." onClick={e => {
            let clientName = '';
            if (parent.loc_client) {
              clientName = typeof parent.loc_client === 'string' ?
                parent.loc_client : parent.loc_client.name;
            }
            if (!parent.loc_client || clientName.includes('Diverse locaties')) {
              return insertLine(parent, 'secondary');
            }
            setShowDistances(true);
          }}>
            <div className="label">New</div>
            <IonIcon slot="end" ios={icons.carSharp} md={icons.carOutline} />
          </IonButton>
          <Format.SupplierMap
            showPopper={showDistances}
            parent={parent}
            suppliers={suppliers}
            onClickAway={(e: any) => setShowDistances(false)}
            onClose={(distance: number) => {
              setShowDistances(false);
              insertLine(parent, 'secondary', { quantity: distance });
            }}
          />

          <IonButton size="small" color="medium" title="Opens the map showing supplier distances. Selecting suppliers will then update the (first) existing transportation product line." onClick={e => {
            let clientName = '';
            if (parent.loc_client) {
              clientName = typeof parent.loc_client === 'string' ?
                parent.loc_client : parent.loc_client.name;
            }
            if (!parent.loc_client || clientName.includes('Diverse locaties')) {
              return setToast('Can\'t map for multiple locations.. yet ;)');
            }
            setShowDistancesAgain(true);
          }}>
            <div className="label">Map</div>
            <IonIcon slot="end" ios={icons.mapSharp} md={icons.mapOutline} />
          </IonButton>
          <Format.SupplierMap
            showPopper={showDistancesAgain}
            parent={parent}
            suppliers={suppliers}
            onClickAway={(e: any) => setShowDistancesAgain(false)}
            onClose={(distance: number) => {
              setShowDistancesAgain(false);
              mapLine({ quantity: distance });
            }}
          />

          <IonButton size="small" color="medium" onClick={duplicateLine}>
            <div className="label">Copy</div>
            <IonIcon slot="end" ios={icons.copySharp} md={icons.copyOutline} />
          </IonButton>

          <IonButton size="small" id="deleteLine" color="danger" title="Deletes the selected document(s)" onClick={e => { setShowConfirmDeleteLine(true) }}>
            <div className="label">Del</div>
            <IonIcon slot="end" ios={icons.trashSharp} md={icons.trashOutline} />
          </IonButton>
          <Format.ConfirmationModal
            showPopper={showConfirmDeleteLine}
            anchor='#deleteLine'
            report={setToast}
            onConfirm={async () => {
              setShowConfirmDeleteLine(false);
              deleteLines();
            }}
            onClose={async () => {
              setShowConfirmDeleteLine(false);
            }}
          />

          <IonButton id="linkEventButton" size="small" color="medium" title="Links an existing calendar event to the current Blitzee document. Will display a list of events mentioning the document's number." onClick={linkEvent}>
            <IonIcon slot="end" ios={icons.calendarNumberSharp} md={icons.calendarNumberOutline} />
            Link
          </IonButton>
          <Format.SelectResult
            showPopper={showSelectResult}
            anchor='#linkEventButton'
            options={resultOptions}
            parent={parent}
            onClickAway={(e: any) => setShowSelectResult(false)}
            onClose={async (result: any) => {
              setShowSelectResult(false);
              const [id, link] = result.split('|');
              if (id && link) {
                setToast(`Event linked successfully.`);
                const assign = { eventId: id, event: link };
                setParent(await updateRow(parent.id, assign));
              }
            }}
          />

          <IonButton size="small" color="medium" title="Syncs calendar event to match with Blitzee. Updates its summary, category, location, date and timing while prepending any existing description with the current one. Secondary missions get their event summary and category updated only. A secondary mission is any mission in a multicode except the first one (e.g. 40p 2mas+8p30 1mas)" onClick={syncEvent}>
            <div className="label">Sync</div>
            <IonIcon slot="end" ios={icons.calendarNumberSharp} md={icons.calendarNumberOutline} />
          </IonButton>

          <ExportButton color="medium" onExport={() => {
            const tpl = Array.from(templates.values()).find((tpl) =>
              template ? (tpl.name === template) : (tpl.for === collectionName)
            );
            return Export.toPdf(collectionName, parent, tpl, templates);
          }}>
            <div className="label">PDF</div>
            <IonIcon slot="end" ios={icons.downloadSharp} md={icons.downloadOutline} />
          </ExportButton>

          <IonButton size="small" color="medium" onClick={openRonselator}>
            <IonIcon slot="end" ios={icons.openSharp} md={icons.openOutline} />
            Rons
          </IonButton>
          </>
        }
        </div>

        <div className="lineButtons">
          {['orders', 'contracts', 'invoices', 'creditnotes'].includes(collectionName) &&
            <>
              <IonButton size="small" color="tertiary" onClick={openEvent}>
                <IonIcon slot="end" ios={icons[`calendar${parent && parent.event ? '' : 'Clear'}Sharp`]} md={icons[`calendar${parent && parent.event ? '' : 'Clear'}Outline`]} />
                Calendar
              </IonButton>

              {parent && parent.event &&
                <>
                <IonButton size="small" id="deleteEvent" color="danger" title="Removes event(s) associated with this document from Blitzee and deletes it from Google Calendar." onClick={e => { setShowConfirmDeleteEvent(true) }}>
                  <IonIcon slot="end" ios={icons.calendarSharp} md={icons.calendarOutline} />
                  Del
                </IonButton>
                <Format.ConfirmationModal
                  showPopper={showConfirmDeleteEvent}
                  anchor='#deleteEvent'
                  report={setToast}
                  onConfirm={async () => {
                    setShowConfirmDeleteEvent(false);
                    deleteEvent();
                  }}
                  onClose={async () => {
                    setShowConfirmDeleteEvent(false);
                  }}
                />
                </>
              }

              <IonButton size="small" color="dark" onClick={openSheet}>
                <IonIcon slot="end" ios={icons[`fileTray${parent && parent.sheet ? 'Full' : ''}Sharp`]} md={icons[`fileTray${parent && parent.sheet ? 'Full' : ''}Outline`]} />
                Sheet
              </IonButton>

              {parent && parent.sheet &&
                <>
                <IonButton size="small" id="deleteSheet" color="danger" title="Removes sheet(s) associated with this document from Blitzee but DOES NOT delete it from Google Docs." onClick={e => { setShowConfirmDeleteSheet(true) }}>
                  <IonIcon slot="end" ios={icons.fileTrayFullSharp} md={icons.fileTrayFullOutline} />
                  Del
                </IonButton>
                <Format.ConfirmationModal
                  showPopper={showConfirmDeleteSheet}
                  anchor='#deleteSheet'
                  report={setToast}
                  onConfirm={async () => {
                    setShowConfirmDeleteSheet(false);
                    deleteSheet();
                  }}
                  onClose={async () => {
                    setShowConfirmDeleteSheet(false);
                  }}
                />
                </>
              }
            </>
          }
          {['orders', 'invoices', 'creditnotes'].includes(collectionName) &&
            <>
              <Template.Dropdown
                value={template}
                options={new Map(Array.from(templates).filter(([k, v]) => v.for === collectionName))}
                onChange={(value: string) => setTemplate(value)}
              />
              <SmashButton color="primary" icon="mail" fast={true} onSmash={async e => {
                const tpl = Array.from(templates.values()).find((tpl) => tpl.name === template);
                if (parent.status !== 'sent') {
                  setParent(await updateRow(parent.id, { status: 'sent' }));
                }
                return Export.toMail(collectionName, parent, tpl, templates, e);
              }}>
                Mail client
              </SmashButton>
            </>
          }

          {['contracts'].includes(collectionName) &&
            <SmashButton color="secondary" icon="mail" fast={true} onSmash={async e => {
              parent.mission = Format.parseCode(parent);
              const firstTpl = Array.from(templates.values())
                .filter(tpl => tpl.for === 'contracts')[0];
              if (parent.status !== 'sent') {
                setParent(await updateRow(parent.id, { status: 'sent' }));
              }
              return Export.toMail(collectionName, parent, firstTpl, templates, e);
            }}>
              Mail supplier
            </SmashButton>
          }

          {['orders'].includes(collectionName) &&
            <>
              <IonButton id="soldButton" color="tertiary" size="small" onClick={async (e) => {
                if (parent.status !== 'sold') {
                  const ops = {
                    addCats: ['3. Masseurs nodig'],
                    removeCats: ['2. Offerte gestuurd']
                  };
                  if (await Format.modifyEvent(google.cal, parent.eventId.split('|'), ops)) {
                    setToast(`Event category set to ${ops.addCats.join(', ')}`);
                  } else {
                    setToast(`Something went wrong on Google's end.`);
                  }
                }
                setShowSoldResult(true);
              }}>
                <IonIcon slot="end" ios={icons.accessibilitySharp} md={icons.accessibilityOutline} />
                Sold
              </IonButton>
              <Format.SoldTextEditor
                showPopper={showSoldResult}
                report={setToast}
                parent={parent}
                onClose={async (result: string) => {
                  setShowSoldResult(false);
                  // This steals focus so put it here (instead of button Click)
                  if (parent.status !== 'sold') {
                    setParent(await updateRow(parent.id, { status: 'sold' }));
                  }
                }}
              />

              <IonButton color="secondary" size="small" onClick={e => {
                if (!parent.loc_client) {
                  return setToast(`This order has no location set, so we can't recruit.`);
                }
                if (parent.loc_client.name.includes('Diverse locaties')) {
                  return setToast(`This order is set to multiple unspecified locations, so we can't recruit.`);
                }
                setShowRecruitment(true);
              }}>
                <IonIcon slot="end" ios={icons.carSharp} md={icons.carOutline} />
                Recruit
              </IonButton>
              <Format.SupplierMap
                showPopper={showRecruitment}
                parent={parent}
                suppliers={suppliers}
                onClickAway={(e: any) => setShowRecruitment(false)}
                onClose={async (distance: number, recruits: any) => {
                  setShowRecruitment(false);
                  if (parent.status !== 'recruited') {
                    setParent(await updateRow(parent.id, { status: 'recruited' }));
                  }
                  const who = await Export.toSuppliers(collectionName, parent, recruits, Array.from(templates.values()).filter(tpl => tpl.for === 'recruitment')[0], templates);
                  const ops = {
                    prependDescr: "<br /><ol><li>" + who.join("</li><li>") + "</li></ol>",
                    addCats: ['5. Masseurs geronseld'],
                    removeCats: ['2. Offerte gestuurd', '3. Masseurs nodig']
                  };
                  if (await Format.modifyEvent(google.cal, parent.eventId.split('|'), ops)) {
                    setToast(`Event category set to ${ops.addCats.join(', ')} and description updated with recruitment report.`);
                  } else {
                    setToast(`Something went wrong on Google's end.`);
                  }
                }}
              />

              <IonButton id="filledButton" color="tertiary" size="small" onClick={async (e) => {
                if (parent.status !== 'filled') {
                  const ops = {
                    addCats: ['8. Contracten sturen'],
                    removeCats: ['2. Offerte gestuurd', '3. Masseurs nodig', '5. Masseurs geronseld']
                  };
                  if (await Format.modifyEvent(google.cal, parent.eventId.split('|'), ops)) {
                    setToast(`Event category set to ${ops.addCats.join(', ')}`);
                  } else {
                    setToast(`Something went wrong on Google's end.`);
                  }
                }
                setResultText(Format.getFilledText(parent));
                setShowFilledResult(true);
              }}>
                <IonIcon slot="end" ios={icons.walkSharp} md={icons.walkOutline} />
                Filled
              </IonButton>
              <Format.FilledTextEditor
                showPopper={showFilledResult}
                report={setToast}
                parent={parent}
                onClose={async (result: string) => {
                  setShowFilledResult(false);
                  if (result) {
                    navigator.clipboard.writeText(result).then(
                      () => setToast('Result was copied to the clipboard')
                    );
                  }
                  // This steals focus so put it here (instead of button Click)
                  if (parent.status !== 'filled') {
                    setParent(await updateRow(parent.id, { status: 'filled' }));
                  }
                }}
              />

              <IonButton color="secondary" size="small" onClick={e => {
                setShowContractors(true);
              }}>
                <IonIcon slot="end" ios={icons.documentLockSharp} md={icons.documentLockOutline} />
                New contracts
              </IonButton>
              <Format.SupplierMap
                showPopper={showContractors}
                parent={parent}
                suppliers={suppliers}
                onClickAway={(e: any) => setShowContractors(false)}
                onClose={async (distance: number, contractors: any) => {
                  setShowContractors(false);
                  const team = contractors.map((c: any) => (c.contact_name || c.name).split(' ')[0]).join(', ');
                  for (const contractor of contractors) {
                    await createContract(contractor, team);
                  }
                  if (parent.status !== 'sealed') {
                    setParent(await updateRow(parent.id, { status: 'sealed' }));
                  }
                  const ops = {
                    addCats: ['A. Factuur sturen'],
                    removeCats: ['2. Offerte gestuurd', '3. Masseurs nodig', '5. Masseurs geronseld', '8. Contracten sturen']
                  };
                  if (await Format.modifyEvent(google.cal, parent.eventId.split('|'), ops)) {
                    setToast(`Created contracts for ${team}. Event category set to ${ops.addCats.join(', ')}.`);
                  } else {
                    setToast(`Created contracts for ${team}, but ${parent.eventId ? 'event category update was skipped since it\'s not linked to an event.' : 'something went wrong on Google\'s end while changing the event category.'}`);
                  }
                }}
              />
              <SmashButton color="primary" icon="cash" fast={true} onSmash={async e => {
                await createInvoice();
                if (parent.status !== 'invoiced') {
                  setParent(await updateRow(parent.id, { status: 'invoiced' }));
                }
                const ops = {
                  addCats: ['B. Klant recycleerbaar'],
                  removeCats: ['2. Offerte gestuurd', '3. Masseurs nodig', '5. Masseurs geronseld', '8. Contracten sturen', 'A. Factuur sturen']
                };
                let evSuccess = false;
                if (parent.eventId) {
                  evSuccess = await Format.modifyEvent(google.cal, parent.eventId.split('|'), ops);
                }
                setToast(evSuccess ?
                  `Created invoice for ${parent.name}. Event category set to ${ops.addCats.join(', ')}.` :
                  `Created invoice for ${parent.name}, but ${parent.eventId ? 'event category update was skipped since it\'s not linked to an event.' : 'something went wrong on Google\'s end while changing the event category.'}`
                );
              }}>
                New invoice
              </SmashButton>

              <SmashButton color="primary" icon="bandage" fast={true} onSmash={async e => {
                await createCreditnote();
                setToast(`Created credit note for ${parent.name}.`);
              }}>
                New credit note
              </SmashButton>
            </>
          }

        </div>

          {['clients', 'suppliers'].includes(collectionName) &&
            <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, lineGridRef, e, 0)}>
              <DataGrid
                ref={lineGridRef}
                rows={lines}
                columns={reportLineColumns}
                rowKeyGetter={(row: any) => row.id}
                selectedRows={selectedLines}
                onSelectedRowsChange={setSelectedLines}
                className='linegrid'
                style={{ height: 275 }}
              />
            </ClickAwayListener>
          }
          {!['clients', 'suppliers'].includes(collectionName) &&
            <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, lineGridRef, e, 1)}>
              <DataGrid
                ref={lineGridRef}
                rows={lines}
                columns={lineColumns}
                rowKeyGetter={(row: any) => row.id}
                onRowsChange={(changedRows: any, changeData: any) => updateLines(changedRows, changeData, parent.id)}
                selectedRows={selectedLines}
                onSelectedRowsChange={setSelectedLines}
                className='linegrid'
                style={{ height: 250 }}
              />
            </ClickAwayListener>
          }

      </div>
    );
  }


  /******* Instanced Firebase Functions *******/

  async function insertRow() {
    let newRow: any = {}, filter: any;
    switch (collectionName) {
      case 'products': newRow = Product.product; filter = productFilter; break;
      case 'orders': newRow = Report.order; filter = orderFilter; break;
      case 'invoices': newRow = Report.invoice; filter = invoiceFilter; break;
      case 'creditnotes': newRow = Report.creditnote; filter = creditnoteFilter; break;
      case 'contracts': newRow = Report.contract; filter = contractFilter; break;
      case 'clients': newRow = Entity.client; filter = clientFilter; break;
      case 'suppliers': newRow = Entity.supplier; filter = supplierFilter; break;
      case 'templates': newRow = Template.template; filter = templateFilter; break;
      default: newRow = Report.order; filter = orderFilter; break;
    }
    const isSerial = rows[0]?.name.match(/[VIC][OFN]/);
    if (!filter.name && !isSerial) {
      return setToast(`Please provide the ${collectionName.slice(0, -1)} name in the filter box.`);
    }
    // create next serial name when needed (e.g. VO/2021/011 -> VO/2021/012)
    newRow.name = (isSerial && !filter.name) ? rows[0].name.slice(0, -3) +
      (parseInt(rows[0].name.split('/')[2]) + 1).toString().padStart(3, '0') :
      filter.name;

    if ('order_date' in newRow) {
      newRow.order_date = Format.dbFormatDate(new Date());
    }
    if ('loc_date' in newRow) {
      newRow.loc_date = Format.getPlausibleDbDateTime();
    }
    if ('status' in newRow) {
      newRow.status = 'draft';
    }
    const connection = await fs.checkFirestore(conn, setConn);
    const docRef = await add(connection, collectionName, newRow);
    const clone = Object.assign({}, newRow);


    // add new entry to the in-memory maps
    updateMemoryMap(docRef.id, clone);

    // enable linegrid
    clone.expandRole = 'MASTER';
    clone.id = docRef.id;
    setRows([clone, ...rows]);

    gridRef.current!.selectCell({idx: ['products', 'templates'].includes(collectionName) ? 3 : 4, rowIdx: 0});
  }

  async function duplicateRow() {
    if (selectedRows?.size === 1) {
      const id = [...Array.from(selectedRows.values())][0];
      const dupeRow = Object.assign({}, rows.find(r => r.id === id));
      delete dupeRow.id;
      const isSerial = rows[0].name.match(/[VI][OF]/);
      dupeRow.name = isSerial ?
        rows[0].name.slice(0, -3) +
        (parseInt(rows[0].name.split('/')[2]) + 1).toString().padStart(3, '0') :
        'Copy ' + dupeRow.name;

      const connection = await fs.checkFirestore(conn, setConn);
      const docRef = await add(connection, collectionName, dupeRow);

      // Fetching & copying product lines
      if (['orders', 'contracts', 'invoices', 'creditnotes'].includes(collectionName)) {
        const { lines } = await getFullReport(connection, collectionName, { ...dupeRow, id });

        if (lines) {
          await addSub(connection, collectionName, 'lines', docRef.id, lines);
        }
      }

      dupeRow.id = docRef.id;
      setSelectedRows(() => new Set());
      updateMemoryMap(docRef.id, dupeRow);
      setToast('Row duplicated as ' + dupeRow.name);
      return setRows([dupeRow, ...rows]);
    }
  }

  async function updateForeigner(coll: string, id: string, assign: any) {
    const connection = await fs.checkFirestore(conn, setConn);
    await update(connection, coll, id, assign);
    switch (coll) {
      case 'clients': clients.set(id, {...clients.get(id), ...assign}); break;
      case 'suppliers': suppliers.set(id, {...suppliers.get(id), ...assign}); break;
      case 'products': products.set(id, {...products.get(id), ...assign}); break;
      case 'templates': templates.set(id, {...templates.get(id), ...assign}); break;
    }
    return true;
  }

  async function updateRow(id: string, assign: any) {
    const connection = await fs.checkFirestore(conn, setConn);
    await update(connection, collectionName, id, assign);
    const rowIndex = rows.findIndex((r: any) => r.id === id);
    rows[rowIndex] = Object.assign(rows[rowIndex], assign);
    setRows([...rows]);
    updateMemoryMap(id, rows[rowIndex]);
    return rows[rowIndex];
  }

  async function updateRows(changedRows: any[], changeData: any) {

    const filtRowIndex = changeData.indexes[0];
    const changed = changedRows[filtRowIndex];
    const rowIndex = rows.findIndex((r: any) => r.id === changed.id);

    if (Format.different(changed, rows[rowIndex])) {
      let clone = Object.assign({}, changed);
      ['id', 'expandParent', 'expandRole', 'expanded'].map(field => {
        if (field in clone) delete clone[field];
      });
      //console.log(collectionName, changed.id, rows[rowIndex].id, clone)
      const connection = await fs.checkFirestore(conn, setConn);
      await update(connection, collectionName, changed.id, clone);
    }

    if ('code' in changed || 'loc_date' in changed) {
      const codeChanged = (changed.code !== rows[rowIndex].code);
      const startChanged = (changed.loc_date !== rows[rowIndex].loc_date);
      if (codeChanged || startChanged) {
        setScheduleParent(changed);
      }
    }


    rows[rowIndex] = changed;
    //console.log(changeData, changedRows, changed, filtRowIndex, rowIndex):

    if (changed.expandRole === 'MASTER') {
      if (changed.expanded && rows[rowIndex + 1]?.expandRole !== 'DETAIL') {
        // add a dummy detail row to hold the LineGrid
        rows.splice(rowIndex + 1, 0, {
          expandRole: 'DETAIL',
          id: changed.id + '_detail',
          parentId: changed.id
        });
        gridRef.current!.selectCell({ idx: 2, rowIdx: rowIndex });
        if (['orders', 'contracts', 'invoices', 'creditnotes'].includes(collectionName)) {
          setScheduleParent(changed)
          //setMission(Format.parseCode(changed));
        }
      } else if (!changed.expanded && rows[rowIndex + 1]?.expandRole === 'DETAIL') {
        // remove expanded row
        rows.splice(rowIndex + 1, 1);
        gridRef.current!.selectCell({ idx: 2, rowIdx: rowIndex });
      }
    }

    updateMemoryMap(changed.id, rows[rowIndex]);
    setRows([...rows]);
  }

  function deleteRows() {
    if (selectedRows?.size) {
      // leveraging from()'s 2nd param to iterate over the Set-turned-Array
      fs.checkFirestore(conn, setConn).then((connection: any) => {
        Array.from(selectedRows.values(), (id) => {
          remove(connection, collectionName, id.toString());
          updateMemoryMap(id.toString(), 'delete');
        });
      });
      setRows(rows.filter(r => !selectedRows.has(r.id)));
    }
  }

  function updateMemoryMap(id: string, updatedRow: any) {
    if (updatedRow === 'delete') {
      switch (collectionName) {
        case 'clients': clients.delete(id); break;
        case 'suppliers': suppliers.delete(id); break;
        case 'products': products.delete(id); break;
        case 'templates': templates.delete(id); break;
      }
    } else {
      let clone = Object.assign({}, updatedRow);
      ['id', 'expandParent', 'expandRole', 'expanded'].map(field => {
        if (field in clone) delete clone[field];
      });
      switch (collectionName) {
        case 'clients': clients.set(id, clone); break;
        case 'suppliers': suppliers.set(id, clone); break;
        case 'products': products.set(id, clone); break;
        case 'templates': templates.set(id, clone); break;
      }
    }
  }


  const defaultButtons = (
    <>
      <IonButton size="small" color="medium" onClick={clearFilters}>
        <IonIcon slot="end" ios={icons.funnelSharp} md={icons.funnelOutline} />
        Reset Filter
      </IonButton>

      <IonButton size="small" color="success" onClick={insertRow}>
        <IonIcon slot="end" ios={icons.addSharp} md={icons.addOutline} />
        New
      </IonButton>

      <IonButton size="small" color="medium" onClick={duplicateRow}>
        <IonIcon slot="end" ios={icons.copySharp} md={icons.copyOutline} />
        Copy
      </IonButton>

      <IonButton size="small" id="deleteRows" color="danger" onClick={e => { setShowConfirmDeleteRows(true) }}>
        <IonIcon slot="end" ios={icons.trashSharp} md={icons.trashOutline} />
        Delete
      </IonButton>
      <Format.ConfirmationModal
        showPopper={showConfirmDeleteRows}
        anchor='#deleteRows'
        report={setToast}
        onConfirm={async () => {
          setShowConfirmDeleteRows(false);
          deleteRows();
        }}
        onClose={async () => {
          setShowConfirmDeleteRows(false);
        }}
      />

      <IonToast
        color="primary"
        isOpen={toast !== ''}
        onDidDismiss={() => setToast('')}
        message={toast}
        duration={8000}
      />
    </>
  );

  // build [current year, .., 2014]
  const years = Array.from(Array(new Date().getFullYear() - 2013), (_, i) => (i + 2014).toString()).reverse();

  let curYearSelect = (
    <IonSelect value={curYear.toString()} className="year-select ion-float-right" interface="popover" onIonChange={e => {
      const val = parseInt(e.detail.value);
      setCurYear(val);
      fs.checkFirestore(conn, setConn).then((connection: any) => {
        getMaster(connection, collectionName, val).then(setRows);
      });
    }}>
      <IonSelectOption key="0" value="0">Any year</IonSelectOption>
      {years.map((year, index) => {
        return (<IonSelectOption key={year} value={year}>{year}</IonSelectOption>)
      })}
    </IonSelect>
  );

  let curMonthSelect = (
    <IonSelect value={curMonth} className="month-select ion-float-right" interface="popover" onIonChange={e => {
      const val = parseInt(e.detail.value);
      //console.log(curMonth, val);
      setCurMonth(val);
      fs.checkFirestore(conn, setConn).then((connection: any) => {
        getMaster(connection, collectionName, curYear, val).then(setRows);
      });
    }}>
      {['Any month', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => {
        return (<IonSelectOption key={index} value={index}>{month}</IonSelectOption>)
      })}
    </IonSelect>
  );

  function onRowSelected(selectedRows: any) {
    setSelectedRows(selectedRows);
    const lastSelRowId = Array.from(selectedRows.values()).slice(-1)[0];
    const lastSelRow = rows.find(r => r.id === lastSelRowId);
    //const hasMission = (lastSelRow && lastSelRow.code! && lastSelRow.loc_date!);
    //const mi = hasMission ? Format.parseCode(lastSelRow) : '';
    //setMission(hasMission ? mi : '');
    setScheduleParent(lastSelRow);
  }

  if (['clients'].includes(collectionName)) {

    return (
      <div className="container">
        <div className="default-buttons">{defaultButtons}</div>
          <Entity.filterContext.Provider value={clientFilter}>
            <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
              <DataGrid
                ref={gridRef}
                rowKeyGetter={(row: any) => row.id}
                columns={clientColumns}
                rows={clientFilteredSortedRows}
                defaultColumnOptions={{ sortable: true, resizable: true }}
                selectedRows={selectedRows}
                onSelectedRowsChange={onRowSelected}
                onRowsChange={updateRows}
                sortColumns={clientSortCols}
                onSortColumnsChange={setClientSortCols}
                summaryRows={clientSummaryRows}
                className="fill-grid"
                headerRowHeight={45}
                rowHeight={(args) => (args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 300 : 45)}
              />
            </ClickAwayListener>
          </Entity.filterContext.Provider>
      </div>
    );

  } else if (['suppliers'].includes(collectionName)) {

    return (
      <div className="container">
        <div className="default-buttons">{defaultButtons}</div>
          <Entity.filterContext.Provider value={supplierFilter}>
            <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
              <DataGrid
                ref={gridRef}
                rowKeyGetter={(row: any) => row.id}
                columns={supplierColumns}
                rows={supplierFilteredSortedRows}
                defaultColumnOptions={{ sortable: true, resizable: true }}
                selectedRows={selectedRows}
                onSelectedRowsChange={onRowSelected}
                onRowsChange={updateRows}
                sortColumns={supplierSortCols}
                onSortColumnsChange={setSupplierSortCols}
                summaryRows={supplierSummaryRows}
                className="fill-grid"
                headerRowHeight={45}
                rowHeight={(args) => (args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 300 : 45)}
              />
            </ClickAwayListener>
          </Entity.filterContext.Provider>
      </div>
    );

  } else if (['orders'].includes(collectionName)) {

    return (
      <div className="container">
        <Format.RowInfo parent={scheduleParent} />
        <div className="text-right clickaway">{curYearSelect}{curMonthSelect}{defaultButtons}</div>
        <Report.filterContext.Provider value={orderFilter}>
          <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
            <DataGrid
              ref={gridRef}
              rowKeyGetter={(row: any) => row.id}
              columns={orderColumns}
              rows={orderFilteredSortedRows}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              selectedRows={selectedRows}
              onSelectedRowsChange={onRowSelected}
              onRowsChange={updateRows}
              sortColumns={orderSortCols}
              onSortColumnsChange={setOrderSortCols}
              summaryRows={orderSummaryRows}
              className="fill-grid"
              headerRowHeight={45}
              rowHeight={(args) => (args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 300 : 45)}
            />
          </ClickAwayListener>
        </Report.filterContext.Provider>
      </div>
    );

  } else if (['contracts'].includes(collectionName)) {

    return (
      <div className="container">
        <div className="text-right clickaway">{curYearSelect}{curMonthSelect}{defaultButtons}</div>
        <Report.filterContext.Provider value={contractFilter}>
          <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
            <DataGrid
              ref={gridRef}
              rowKeyGetter={(row: any) => row.id}
              columns={contractColumns}
              rows={contractFilteredSortedRows}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              selectedRows={selectedRows}
              onSelectedRowsChange={onRowSelected}
              onRowsChange={updateRows}
              sortColumns={contractSortCols}
              onSortColumnsChange={setContractSortCols}
              summaryRows={contractSummaryRows}
              className="fill-grid"
              headerRowHeight={45}
              rowHeight={(args) => (args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 300 : 45)}
            />
          </ClickAwayListener>
        </Report.filterContext.Provider>
      </div>
    );

  } else if (['invoices'].includes(collectionName)) {

    return (
      <div className="container">
        <div className="text-right clickaway">{curYearSelect}{curMonthSelect}{defaultButtons}</div>
        <Report.filterContext.Provider value={invoiceFilter}>
          <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
            <DataGrid
              ref={gridRef}
              rowKeyGetter={(row: any) => row.id}
              columns={invoiceColumns}
              rows={invoiceFilteredSortedRows}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              selectedRows={selectedRows}
              onSelectedRowsChange={onRowSelected}
              onRowsChange={updateRows}
              sortColumns={invoiceSortCols}
              onSortColumnsChange={setInvoiceSortCols}
              summaryRows={invoiceSummaryRows}
              className="fill-grid"
              headerRowHeight={45}
              rowHeight={(args) => (args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 300 : 45)}
            />
          </ClickAwayListener>
        </Report.filterContext.Provider>
      </div>
    );

  } else if (['creditnotes'].includes(collectionName)) {

    return (
      <div className="container">
        <div className="text-right clickaway">{curYearSelect}{curMonthSelect}{defaultButtons}</div>
        <Report.filterContext.Provider value={creditnoteFilter}>
          <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
            <DataGrid
              ref={gridRef}
              rowKeyGetter={(row: any) => row.id}
              columns={creditnoteColumns}
              rows={invoiceFilteredSortedRows}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              selectedRows={selectedRows}
              onSelectedRowsChange={onRowSelected}
              onRowsChange={updateRows}
              sortColumns={creditnoteSortCols}
              onSortColumnsChange={setCreditnoteSortCols}
              summaryRows={invoiceSummaryRows}
              className="fill-grid"
              headerRowHeight={45}
              rowHeight={(args) => (args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 300 : 45)}
            />
          </ClickAwayListener>
        </Report.filterContext.Provider>
      </div>
    );

  } else if (['products'].includes(collectionName)) {

    return (
      <div className="container">
        <div className="text-right clickaway">{defaultButtons}</div>
        <Product.filterContext.Provider value={productFilter}>
          <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
            <DataGrid
              ref={gridRef}
              rowKeyGetter={(row: any) => row.id}
              columns={productColumns}
              rows={productFilteredSortedRows}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              selectedRows={selectedRows}
              onSelectedRowsChange={onRowSelected}
              onRowsChange={updateRows}
              sortColumns={productSortCols}
              onSortColumnsChange={setProductSortCols}
              summaryRows={productSummaryRows}
              className="fill-grid"
              headerRowHeight={45}
            />
          </ClickAwayListener>
        </Product.filterContext.Provider>
      </div>
    );

  } else if (['templates'].includes(collectionName)) {

    return (
      <div className="container">
        <div className="text-right clickaway">{defaultButtons}</div>
        <Template.filterContext.Provider value={templateFilter}>
          <ClickAwayListener onClickAway={e => Format.clearFocus(collectionName, gridRef, e)}>
            <DataGrid
              ref={gridRef}
              rowKeyGetter={(row: any) => row.id}
              columns={templateColumns}
              rows={templateFilteredSortedRows}
              defaultColumnOptions={{ sortable: true, resizable: true }}
              selectedRows={selectedRows}
              onSelectedRowsChange={onRowSelected}
              onRowsChange={updateRows}
              sortColumns={templateSortCols}
              onSortColumnsChange={setTemplateSortCols}
              summaryRows={templateSummaryRows}
              className="fill-grid"
              headerRowHeight={45}
            />
          </ClickAwayListener>
        </Template.filterContext.Provider>
      </div>
    );

  } else {
    return (
      <div className="container">
        <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p>
      </div>
    );
  }
};




/************* PDF Export ************/

function ExportButton({
  color,
  onExport,
  children
}: {
  color: string;
  onExport: (e: any) => Promise<unknown>;
  children: React.ReactChild[];
}) {
  const [exporting, setExporting] = useState(false);
  return (
    <IonButton
      size="small"
      color={color}
      disabled={exporting}
      onClick={async (e) => {
        setExporting(true);
        await onExport(e);
        setExporting(false);
      }}
    >
      {exporting ? 'Exporting' : children}
    </IonButton>
  );
}

function SmashButton({
  color,
  onSmash,
  icon,
  fast,
  children
}: {
  color: string;
  onSmash: (e: any) => Promise<unknown>;
  icon: string;
  fast: boolean;
  children: React.ReactChild;
}) {
  const [busy, setBusy] = useState(false);
  const [done, setDone] = useState<React.ReactChild>(children);
  const doneStr = ['Boom.', 'Booyah!', 'There ya go.', 'Sweet.', 'Groovy!', 'Wicked.', 'Yay.', 'Splendid!', 'Wheee!', '\'Aight.', 'Cowabunga!', 'Far out.', 'Radical!', 'Yup.', 'Done that.', 'Who dis?', 'No sweat.', 'Easy!', 'That it?', 'Woot!', 'Gaan banaan!', 'No sweat!'];

  return (
    <IonButton
      size="small"
      color={color}
      disabled={busy}
      onClick={async (e) => {
        !fast && setBusy(true);
        await onSmash(e);
        setDone(<>{doneStr[Math.floor(Math.random() * doneStr.length)]}</>);
        setBusy(false);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setDone(children);
      }}
    >
      <IonIcon slot="end" ios={(icons as any)[icon + 'Sharp']} md={(icons as any)[icon + 'Outline']} />
      {busy ? 'Processing..' : done}
    </IonButton>
  );
}

/*
const [toast, setToast] = useState(false);
<IonToast
                isOpen={toast}
                onDidDismiss={() => setToast(false)}
                message="Invoice created"
                duration={3000}
              />
*/





/******* React Component Export *******/

export default ExploreContainer;
