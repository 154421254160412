import { useRef, useState, useEffect, ReactElement, MutableRefObject } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Popper from '@mui/material/Popper';
import { DateTime, Interval } from 'luxon';
import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLabel, IonToggle, IonToast } from '@ionic/react';
import * as icons from 'ionicons/icons';
// Loading both js-api-loader and Wrapper (which wraps same lib)
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {Loader, LoaderOptions} from '@googlemaps/js-api-loader';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';

/******* Data grid UX *******/

export const getPlausibleDbDateTime = () => {
  return dbFormatDateTime(getPlausibleDateTime());
}

export const getPlausibleDateTime = () => {
  let now = new Date();
  const today = now.getDay();
  const addDays = today === 6 ? 2 : (today === 0 ? 1 : 0);
  return new Date(new Date(now.setDate(now.getDate() + 14 + addDays)).setHours(9, 30, 0));
}

export const DateTimeEditor = (props: any) => {
  const defaultDate = props.row[props.column.key] ?
    new Date(props.row[props.column.key]) : getPlausibleDateTime();
  const [val, setVal] = useState<Date>(defaultDate);
  return (
     <DateTimePicker
      ampm={false}
      renderInput={(atts) => {
        return (
          <TextField
            ref={atts.inputRef}
            inputProps={atts.inputProps}
            InputProps={atts.InputProps}
            inputRef={input => {
              if (input && document.activeElement !== input) {
                input.focus();
                input.select();
              }
            }}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                const cur = e.target.value;
                let strDate = '';
                if (cur) {
                  const d = DateTime.fromFormat(cur, 'dd/MM/yyyy HH:mm').toJSDate();
                  setVal(d);
                  strDate = dbFormatDateTime(d);
                }
                props.onRowChange({ ...props.row, [props.column.key]: strDate }, true);
              }
            }}
          />
        );
      }}
      value={val}
      inputFormat="dd/MM/yyyy HH:mm"
      mask="__/__/____ __:__"
      onChange={date => (date && setVal(date))}
      onAccept={date => {
        if (date && Date.parse(date.toString())) {
          props.onRowChange({ ...props.row, [props.column.key]: dbFormatDateTime(date) }, true);
        }
      }}
    />
  );
}

export const DateEditor = (props: any) => {
  const [val, setVal] = useState<Date>(new Date(props.row[props.column.key]));
  return (
    <DatePicker
      renderInput={(atts) => {
        return (
          <TextField
            ref={atts.inputRef}
            inputProps={atts.inputProps}
            InputProps={atts.InputProps}
            inputRef={input => {
              if (input && document.activeElement !== input) {
                input.focus();
                input.select();
              }
            }}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter' && val && Date.parse(val.toString())) {
                props.onRowChange({ ...props.row, [props.column.key]: dbFormatDate(val) }, true);
              }
            }}
          />
        );
      }}
      value={val}
      inputFormat="dd/MM/yyyy"
      mask="__/__/____"
      onChange={date => (date && setVal(date))}
      onAccept={date => {
        if (date && Date.parse(date.toString())) {
          props.onRowChange({ ...props.row, [props.column.key]: dbFormatDate(date) }, true);
        }
      }}
    />
  );
}

// props = {row, column, onRowChange, onClose}
export const TextEditor = (props: any, mutate: any) => {
  if (typeof mutate !== 'function') mutate = ((v: string) => v);
  const { row, column, onRowChange, onClose } = props;

  const [val, setVal] = useState<string>(mutate(props.row[props.column.key]));
  //defaultValue={row[column.key] as unknown as string}
  return (
    <input
      className="rdg-text-editor"
      ref={autoFocusAndSelect}
      value={val}
      onBlur={(ev: any) => {
        onRowChange({ ...row, [column.key]: ev.target.value.trim() }, true);
        onClose(true);
      }}
      onChange={(ev: any) => {
        ev.target.value = mutate(ev.target.value);
        setVal(ev.target.value);
      }}
      onKeyDown={(ev: any) => {
        ev.stopPropagation();

        let value = ev.target.value.trim();

        if (ev.key === 'Escape') {

          onClose();
        } else if (ev.key === 'Enter') {

          onRowChange({ ...row, [column.key]: value }, true);
          onClose();
          //scrollToCell();
        } else if (isNumeric(value)) {
          if (['ArrowUp', 'ArrowDown'].includes(ev.key)) {

            let num = parseFloat(value);
            const dec = (num - Math.floor(num)).toFixed(2).substring(2);
            const [bigStops, smallStops] = ev.key === 'ArrowUp' ?
              [['00', '33', '50', '83'], ['17', '25', '67', '75']] :
              [['00', '17', '50', '67'], ['25', '33', '75', '83']];
            const tIncr = bigStops.includes(dec) ? 0.17 :
              (smallStops.includes(dec) ? 0.08 : 1);
            num += (ev.key === 'ArrowUp' ? 1 : -1) * (ev.ctrlKey ? tIncr : 1);

            ev.target.value = ev.ctrlKey ? Math.round(num * 100) / 100 : num;
          }
        }
      }}
    />
  );
};

export function textEditorOptions(setValue?: any) {
  return {
    onNavigation(ev: any) {
      ev.stopPropagation();
      const value = ev.target.value.trim();

      if (isNumeric(value)) {
        if (['ArrowUp', 'ArrowDown'].includes(ev.key)) {
          ev.preventDefault();
          let num = parseFloat(value);
          const dec = (num - Math.floor(num)).toFixed(2).substring(2);
          const [bigStops, smallStops] = ev.key === 'ArrowUp' ?
            [['00', '33', '50', '83'], ['17', '25', '67', '75']] :
            [['00', '17', '50', '67'], ['25', '33', '75', '83']];
          const tIncr = bigStops.includes(dec) ? 0.17 :
            (smallStops.includes(dec) ? 0.08 : 1);
          num += (ev.key === 'ArrowUp' ? 1 : -1) * (ev.ctrlKey ? tIncr : 1);
          if (setValue) {
            setValue(ev.ctrlKey ? Math.round(num * 100) / 100 : num);
          }
        }
      }
      return false;
    }
  };
}



export const TextFieldEditor = (props: any, gridRef: any) => {
  const [val, setVal] = useState(props.row[props.column.key].trim());

  const getAnchorEl = () => {
    return (gridRef!.current!.element || document.body)
      .querySelector('.rdg-cell[aria-selected=true]')!;
  };
  return (
    <Popper open={true} anchorEl={() => getAnchorEl()} style={{ width: getAnchorEl().clientWidth + 'px'}}>
      <TextField
        autoFocus={true}
        fullWidth={true}
        minRows="2"
        maxRows="15"
        multiline={true}
        size="small"
        value={val}
        inputProps={{
          onBlur: (ev: any) => {
            const value = ev.target.value.trim();
            props.onRowChange({ ...props.row, [props.column.key]: value }, true);
          }
        }}
        onChange={(ev: any) => {
          setVal(ev.target.value);
        }}
        onKeyUp={(ev: any) => {
          if (ev.ctrlKey && ev.key === 'Enter') {
            const value = ev.target.value.trim();
            props.onRowChange({ ...props.row, [props.column.key]: value }, true);
            ev.preventDefault();
          }
        }}
      />
    </Popper>
  );
}


export const QuoteDescriptionEditor = (props: any, gridRef: any) => {
  const [val, setVal] = useState(props.row[props.column.key].trim());
  const [offerSheet, setOfferSheet] = useState(false);
  const [soon, setSoon] = useState(false);
  const [ourDate, setOurDate] = useState(false);
  const [english, setEnglish] = useState(false);
  const getAnchorEl = () => {
    return (gridRef.current!.element || document.body)
      .querySelector('.rdg-cell[aria-selected=true]')!;
  };
  return (
    <Popper
      open={true}
      anchorEl={() => getAnchorEl()}
      style={{ width: getAnchorEl().clientWidth + 'px'}}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, -40],
          },
        },
      ]}
    >
      <div className="field-buttons">
        <IonButton size="small" color="success" onClick={e => {
            props.onRowChange({ ...props.row, [props.column.key]: val }, true);
          }}>
          <IonIcon slot="end" ios={icons.thumbsUpSharp} md={icons.thumbsUpOutline} />
          Done
        </IonButton>

        <IonButton size="small" color="warning" onClick={e => {
            setVal(getQuoteDescription(props.row, { offerSheet, soon, ourDate, english }));
          }}>
          <IonIcon slot="end" ios={icons.sparklesSharp} md={icons.sparklesOutline} />
          Generate
        </IonButton>

        <IonLabel color="warning">Offer schedule</IonLabel>
        <IonToggle
          value="offerSheet"
          color="warning"
          checked={offerSheet}
          onIonChange={() => setOfferSheet(!offerSheet)}
        />

        <IonLabel color="warning">Soon</IonLabel>
        <IonToggle
          value="soon"
          color="warning"
          checked={soon}
          onIonChange={() => setSoon(!soon)}
        />

        <IonLabel color="warning">Our date</IonLabel>
        <IonToggle
          value="ourDate"
          color="warning"
          checked={ourDate}
          onIonChange={() => setOurDate(!ourDate)}
        />

        <IonLabel color="warning">English</IonLabel>
        <IonToggle
          value="english"
          color="warning"
          checked={english}
          onIonChange={() => setEnglish(!english)}
        />
      </div>
      <TextField
        autoFocus={true}
        fullWidth={true}
        minRows="2"
        maxRows="15"
        multiline={true}
        size="small"
        value={val}
        inputProps={{
          onBlur: (ev: any) => {
            if (!ev.target.className.includes('MuiInputBase')) {
              const value = ev.target.value.trim();
              props.onRowChange({ ...props.row, [props.column.key]: value }, true);
            }
          }
        }}
        onChange={(ev: any) => {
          setVal(ev.target.value.trim());
        }}
        onKeyUp={(ev: any) => {
          if (ev.ctrlKey && ev.key === 'Enter') {
            const value = ev.target.value.trim();
            props.onRowChange({ ...props.row, [props.column.key]: value }, true);
            ev.preventDefault();
          }
        }}
      />
    </Popper>
  );
}

export const CoordEditor = (props: any, gridRef: any) => {
  const [val, setVal] = useState<string>(props.row[props.column.key].trim());
  const [toast, setToast] = useState<string>('');
  const getAnchorEl = () => {
    return (gridRef.current!.element || document.body)
      .querySelector('.rdg-cell[aria-selected=true]')!;
  };
  return (
    <Popper
      open={true}
      anchorEl={() => getAnchorEl()}
      style={{ width: getAnchorEl().clientWidth + 'px'}}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, -35],
          },
        },
      ]}
    >
      <div className="field-buttons" style={{ marginBottom: '2px'}}>
        <Wrapper apiKey={"AIzaSyCvs077FgqXXHbhInbeq1xSENdWO4Gp6rU"} />
        <IonButton size="small" color="success" onClick={e => {
          if (!props.row) return setToast('Set mission location first.');
          const geocoder = new window.google.maps.Geocoder();
          const address = `${props.row.address1}, ${props.row.postal} ${props.row.place}, ${props.row.country}`;
          geocoder.geocode({ address }, async (results, status) => {
            if (status !== 'OK') return console.error(`Geocode failed: ${status}]`);
            const lat = await results[0].geometry.location.lat();
            const lng = await results[0].geometry.location.lng();
            props.onRowChange({ ...props.row, coord: `${lat},${lng}` }, true);
          });
        }}>
          <IonIcon slot="end" ios={icons.cloudDownloadSharp} md={icons.cloudDownloadOutline} />
          Fetch
        </IonButton>
      </div>
      <TextField
        autoFocus={true}
        fullWidth={true}
        minRows={2}
        maxRows={4}
        multiline={true}
        size="small"
        value={val}
        inputProps={{
          onBlur: (ev: any) => {
            if (!ev.target.className.includes('MuiInputBase')) {
              const value = ev.target.value.trim();
              props.onRowChange({ ...props.row, [props.column.key]: value }, true);
            }
          }
        }}
        onChange={(ev: any) => {
          setVal(ev.target.value.trim());
        }}
        onKeyUp={(ev: any) => {
          if (ev.key === 'Enter') {
            const value = ev.target.value.trim();
            props.onRowChange({ ...props.row, [props.column.key]: value }, true);
            ev.preventDefault();
          }
        }}
      />
      <IonToast
        isOpen={toast !== ''}
        onDidDismiss={() => setToast('')}
        message={toast}
        duration={3000}
      />
    </Popper>
  );
}

// Allows amending foreign client with invoice vat number
export const VatEditor = (props: any, gridRef: any, updateForeigner: any) => {
  const [val, setVal] = useState<string>(props.row[props.column.key].trim());
  const [toast, setToast] = useState<string>('');
  if (!gridRef.current) return null;
  const getAnchorEl = () => {
    return (gridRef.current!.element || document.body)
      .querySelector('.rdg-cell[aria-selected=true]')!;
  };
  return (
    <Popper
      open={true}
      anchorEl={() => getAnchorEl()}
      style={{ width: getAnchorEl().clientWidth + 'px'}}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, -80],
          },
        },
      ]}
    >
      <div className="field-buttons" style={{ marginBottom: '2px'}}>
        <IonButton size="small" color="success" onClick={e => {
          props.onRowChange({ ...props.row, [props.column.key]: val }, true);
        }}>
          <IonIcon slot="end" ios={icons.thumbsUpSharp} md={icons.thumbsUpOutline} />
          Done
        </IonButton>
        <IonButton size="small" color="success" onClick={e => {
          updateForeigner('clients', props.row.client_origin, { vat_nr: val });
          setToast('Client\'s VAT number was successfully changed.');
        }}>
          <IonIcon slot="end" ios={icons.cloudUploadSharp} md={icons.cloudUploadOutline} />
          To client
        </IonButton>
      </div>

      <TextField
        autoFocus={true}
        fullWidth={true}
        multiline={false}
        size="small"
        value={val}
        className="inplace-editor"
        inputProps={{
          onBlur: (ev: any) => {
            if (!ev.target.className.includes('MuiInputBase')) {
              const value = ev.target.value.trim();
              props.onRowChange({ ...props.row, [props.column.key]: value }, true);
            }
          }
        }}
        onChange={(ev: any) => {
          setVal(ev.target.value.replace(/[ .\-]/g, ''));
        }}
        onKeyUp={(ev: any) => {
          if (ev.key === 'Enter') {
            const value = ev.target.value.trim();
            props.onRowChange({ ...props.row, [props.column.key]: value }, true);
            ev.preventDefault();
          }
        }}
      />

      <IonToast
        isOpen={toast !== ''}
        onDidDismiss={() => setToast('')}
        message={toast}
        duration={3000}
      />
    </Popper>
  );
}

export const SelectEditor = (props: any, options: any) => {
  const ref = useRef(null);
  const [val, setVal] = useState(props.row[props.column.key].trim() || options[0] || '');
  const [open, setOpen] = useState(true);
  return (
    <Select
      size="small"
      open={open}
      onOpen={(ev: any) => setOpen(true)}
      ref={ref}
      value={val}
      onChange={(ev: any, option: any) => {
        setOpen(false);
        if (option) {
          setVal(option.props.value);
          let rowMod = { [props.column.key]: option.props.value };
          props.onRowChange({ ...props.row, ...rowMod }, true)}
        }
      }
    >
      {options.map((value: string) => {
        value = value.trim();
        return (
          <MenuItem
            key={value}
            onBlur={(ev: any) => setOpen(false)}
            onClick={(ev: any) => setOpen(false)}
            onKeyDown={(ev: any) => {
              if (ev.key === 'Enter') {
                setOpen(false);
              }
            }}
            value={value}
          >
            {value}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export const ForeignEditor = (props: any, foreign: any, useName: boolean = false) => {
  const initVal = props.row[props.column.key];
  const initForeigner = useName ? Array.from(foreign.values()).find((v: any) => v.name === initVal) : foreign.get(initVal);
  const [val, setVal] = useState(initForeigner?.name || '');
  const [open, setOpen] = useState(true);

  return (
    <Autocomplete
      autoComplete={true}
      autoSelect={true}
      disableClearable={true}
      fullWidth={true}
      openOnFocus={true}
      open={open}
      PopperComponent={(props: any) => { return (
        <Popper
          {...props}
          modifiers={[ { name: "offset", options: { offset: [-1, 12] }}]}
        />
      )}}
      id="combo-box-demo"
      size="small"
      renderInput={(params) => <TextField {...params} autoFocus={true} />}
      value={val}
      isOptionEqualToValue={(option, value) => {
        //console.log(option, value);
        return option.label.trim() === value; // CHANGED value.label to value
      }}
      options={[ ...Array.from(foreign, ([key, option]) => {
        return ({ key: key, label: option.name });
      }), { key: '0', label: '' } ]}
      onOpen={(ev: any) => { setOpen(true) }}
      onClose={(ev: any, reason: string) => { setOpen(false) }}
      onChange={(e: any, value: any) => {

        if (value) {
          setVal(useName ? value.label.trim() : value.key);

          let rowMod = {
            [props.column.key]: useName ? value.label.trim() : value.key
          };

          if (props.column.key === 'contact_client') {
            if (props.row.invoice_client === props.row.contact_client) {
              rowMod = Object.assign(rowMod, { invoice_client: value.key });
            }
            if (props.row.loc_client === props.row.contact_client) {
              rowMod = Object.assign(rowMod, { loc_client: value.key });
            }
          }

          const ent = useName ? Array.from(foreign.values()).find((v: any) => v.name === value.label) : foreign.get(value.key);

          if ('quantity' in props.row && props.column.key === 'name') {

            rowMod = Object.assign(rowMod, {
              price_client: ent.price_client,
              price_supplier: ent.price_supplier,
              type: ent.type,
              description: ent.description,
              unit: ent.unit
            });
          }
          // Invoice client_name override
          if (props.column.key === 'client_name') {

            rowMod = Object.assign(rowMod, {
              client_origin: value.key,
              client_address: ent.address1 + (ent.address2 ? "\n" + ent.address2 : '') + "\n" + ent.postal + ' ' + ent.place + "\n" + ent.country,
              client_vat_nr: ent.vat_nr,
              client_vat_perc: ent.vat_perc
            });
          }
          // Invoice loc_client override
          if (props.column.key === 'loc_client' && ent && 'loc_address' in ent) {

            rowMod = Object.assign(rowMod, {
              loc_address: ent.address1 + (ent.address2 ? "\n" + ent.address2 : '') + "\n" + ent.postal + ' ' + ent.place + "\n" + ent.country
            });
          }

          props.onRowChange({ ...props.row, ...rowMod }, true)}
        }
      }
    />
  ); //
}


export function RowInfo({ parent }: {parent: any}) {

  const hasMission = (parent && parent.code! && parent.loc_date!);

  if (!document.body || !hasMission) return null;

  const getAnchorEl = () => {
    return document.body.querySelector('#menu-panel')!;
  };

  let schedule = '';
  let mi;
  for (const [i, code] of parent.code.split('+').entries()) {
    mi = parseCode(parent, i);
    schedule += schedule ? "\n\n" : '';
    schedule += mi.slots ? mi.slots.map((s: string) => s.replace(/(\d+-)[\d\-]*-(\d+)$/, '$1$2')).join("\n") : '';
  }

  return (
    <Popper
      open={true}
      anchorEl={() => getAnchorEl()}
      style={{ width: '126px'}}
      className='rowinfo'
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 0],
          },
        },
      ]}
    >
      <div className='title'>{mi?.source || ''}</div>
      <TextField
        fullWidth={true}
        minRows="2"
        maxRows="21"
        multiline={true}
        size="small"
        value={schedule}
        style={{ backgroundColor: 'transparent' }}
        InputProps={{ style: { padding: '0 0 0 10px', fontSize: '11pt', color: 'var(--ion-color-light)' } }}
      />
    </Popper>
  );
}

export function SelectResult({
  showPopper,
  anchor,
  options,
  parent,
  onClickAway,
  onClose
}: {
  showPopper: boolean,
  anchor: string,
  options: any,
  parent: any,
  onClickAway: any,
  onClose: any
}) {
  const getAnchorEl = () => {
    return document.body.querySelector(anchor)!;
  };

  if (!showPopper) return null;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        open={showPopper}
        anchorEl={() => getAnchorEl()}
        placement="bottom-end"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
        ]}
      >
          <div className="selectresult">
          {options.map((event: any) => {

            return (
              <MenuItem
                key={event.id}
                onClick={(ev: any) => onClose(ev.target.attributes.value.textContent)}
                onKeyDown={(ev: any) => {
                  if (ev.key === 'Enter') {
                    onClose(ev.target.attributes.value.textContent);
                  }
                }}
                value={event.id + '|' + event.htmlLink}
              >
                {event.summary}
              </MenuItem>
            );
          })}
          </div>
      </Popper>
    </ClickAwayListener>
  );
}

export function ConfirmationModal(
  { showPopper, anchor, report, onConfirm, onClose }:
  { showPopper: boolean, anchor: string, report: any, onConfirm: any, onClose: any }
) {

  const [hasConfirmed, setHasConfirmed] = useState(false);

  /*
  useEffect(() => {
    showPopper && !localText && setLocalText(getFilledText(parent, { includeSheet }))
  }, [showPopper, parent]);
  */
  if (!showPopper) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={showPopper}
        anchorEl={() => document.body.querySelector(anchor)!}
        placement="bottom-start"
        style={{ width: '240px' }}
        modifiers={[ { name: "offset", options: { offset: [0, 5] } }]}
      >
        <div className="modal-panel">
          <strong>Are you sure?</strong><br />
          <div className="result-buttons" style={{marginTop: '5px'}}>
            <IonLabel color="warning">I'm sure</IonLabel>
            <IonToggle
              value="hasConfirmed"
              color="warning"
              checked={hasConfirmed}
              onIonChange={() => {
                setHasConfirmed(!hasConfirmed);
              }}
            />

            <IonButton size="small" color={hasConfirmed ? 'success' : 'danger'} style={{marginLeft: '10px'}} onClick={e => {
              if (hasConfirmed) {
                onConfirm();
              } else {
                report('Operation was NOT confirmed.');
              }
              onClose();
            }}>
              <IonIcon slot="end" ios={icons[hasConfirmed ? 'checkmarkSharp' : 'handLeftSharp']} md={icons[hasConfirmed ? 'checkmarkOutline' : 'handLeftOutline']} />
              Confirm
            </IonButton>
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
}

export function SoldTextEditor(
  { showPopper, report, parent, onClose }:
  { showPopper: boolean, report: any, parent: any, onClose: any }
) {
  const [localText, setLocalText] = useState('');
  const [repeating, setRepeating] = useState(false);
  const [ourDate, setOurDate] = useState(false);
  const [includeSheet, setIncludeSheet] = useState(false);

  useEffect(() => {
    showPopper && !localText && setLocalText(getSoldText(parent, { repeating, ourDate, includeSheet }));
  }, [showPopper, parent]);

  if (!showPopper) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={showPopper}
        anchorEl={() => document.body.querySelector('#soldButton')!}
        placement="bottom-start"
        style={{ width: '500px' }}
        modifiers={[ { name: "offset", options: { offset: [0, 3] } }]}
      >
        <div className="result-buttons">
          <IonButton size="small" color="success" onClick={e => {
            if (localText) {
              navigator.clipboard.writeText(localText).then(
                () => report('Result was copied to the clipboard')
              );
            }
            onClose(localText);
          }}>
            <IonIcon slot="end" ios={icons.clipboardSharp} md={icons.clipboardOutline} />
            Clipboard
          </IonButton>

          <IonLabel color="warning">Recurs</IonLabel>
          <IonToggle
            value="repeating"
            color="warning"
            checked={repeating}
            onIonChange={() => {
              const options =  { repeating: !repeating, ourDate, includeSheet };
              setLocalText(getSoldText(parent, options));
              setRepeating(!repeating);
            }}
          />

          <IonLabel color="warning">Our date</IonLabel>
          <IonToggle
            value="ourDate"
            color="warning"
            checked={ourDate}
            onIonChange={() => {
              const options =  { repeating, ourDate: !ourDate, includeSheet };
              setLocalText(getSoldText(parent, options));
              setOurDate(!ourDate);
            }}
          />

          <IonLabel color="warning">Include schedule</IonLabel>
          <IonToggle
            disabled={!Boolean(parent.sheet)}
            value="includeSheet"
            color="warning"
            checked={includeSheet}
            onIonChange={() => {
              const options =  { repeating, ourDate, includeSheet: !includeSheet };
              setLocalText(getSoldText(parent, options));
              setIncludeSheet(!includeSheet);
            }}
          />
        </div>
        <TextField
          className="selectresult"
          autoFocus={true}
          fullWidth={true}
          minRows="2"
          maxRows="15"
          multiline={true}
          size="small"
          value={localText}
          onChange={(ev: any) => { setLocalText(ev.target.value); }}
          onKeyUp={(ev: any) => {
            if (ev.ctrlKey && ev.key === 'Enter') {
              onClose(ev.target.value);
              ev.preventDefault();
            }
          }}
        />
      </Popper>
    </ClickAwayListener>
  );
}

export function FilledTextEditor(
  { showPopper, report, parent, onClose }:
  { showPopper: boolean, report: any, parent: any, onClose: any }
) {
  const [localText, setLocalText] = useState('');
  const [includeSheet, setIncludeSheet] = useState(false);

  useEffect(() => {
    showPopper && !localText && setLocalText(getFilledText(parent, { includeSheet }))
  }, [showPopper, parent]);

  if (!showPopper) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={showPopper}
        anchorEl={() => document.body.querySelector('#filledButton')!}
        placement="bottom-start"
        style={{ width: '500px' }}
        modifiers={[ { name: "offset", options: { offset: [0, 0] } }]}
      >
        <div className="result-buttons">
          <IonButton size="small" color="success" onClick={e => {
            if (localText) {
              navigator.clipboard.writeText(localText).then(
                () => report('Result was copied to the clipboard')
              );
            }
            onClose(localText);
          }}>
            <IonIcon slot="end" ios={icons.clipboardSharp} md={icons.clipboardOutline} />
            To clipboard
          </IonButton>

          <IonLabel color="warning">Include schedule</IonLabel>
          <IonToggle
            disabled={!Boolean(parent.sheet)}
            value="includeSheet"
            color="warning"
            checked={includeSheet}
            onIonChange={() => {
              const options =  { includeSheet: !includeSheet };
              setLocalText(getFilledText(parent, options));
              setIncludeSheet(!includeSheet);
            }}
          />
        </div>
        <TextField
          className="selectresult"
          autoFocus={true}
          fullWidth={true}
          minRows="2"
          maxRows="15"
          multiline={true}
          size="small"
          value={localText}
          onChange={(ev: any) => { setLocalText(ev.target.value); }}
          onKeyUp={(ev: any) => {
            if (ev.ctrlKey && ev.key === 'Enter') {
              onClose(ev.target.value);
              ev.preventDefault();
            }
          }}
        />
      </Popper>
    </ClickAwayListener>
  );
}

export function SupplierMap({
  showPopper,
  parent,
  suppliers,
  onClickAway,
  onClose
}: {
  showPopper: boolean,
  parent: any,
  suppliers: any,
  onClickAway: any,
  onClose: any
}) {
  const [dist, setDist] = useState(0);
  /*
  console.log('PARENT:', parent);
  const [localParent, setLocalParent] = useState(parent ?? {});
  const [localSuppliers, setLocalSuppliers] = useState(suppliers ?? {});
  */

  /*
  useEffect(() => {
    if ('loc_client' !in localParent) {
      setLocalParent(parent);
      console.log('LOCALPARENT:', parent);
    }
    if (!localSuppliers) {
      setLocalSuppliers(suppliers);
    }
  }, [parent, suppliers]);
  */

  const getAnchorEl = () => {
    return document.body.querySelector('.rdg-cell[aria-colindex="4"]')!;
  };

  //const loc = localParent.loc_client;
  if (!showPopper) return null;

  const locAddress = 'loc_address' in parent ? parent.loc_address :
    ('loc_client' in parent ? `${parent.loc_client.address1}, ${parent.loc_client.postal} ${parent.loc_client.place}, ${parent.loc_client.country}` : '');

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        open={showPopper}
        anchorEl={() => getAnchorEl()}
        style={{ width: '97%', height: '100%' }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, -46],
            },
          },
        ]}
      >
        <Wrapper apiKey={"AIzaSyCvs077FgqXXHbhInbeq1xSENdWO4Gp6rU"} render={(status: Status): any => {
          if (status === Status.LOADING) return <CircularProgress />;
          if (status === Status.FAILURE) return <h3>{status} ...</h3>;
          return null;
        }}>
          <Map
            parent={parent}
            orig={locAddress}
            dest={Array.from(suppliers)
              .map(([k, v]: any) => { v.id = k; return v; })
              .filter((v: any) => v.subcontractor === true)}
            onClose={onClose} //(distance: number) => onClose(Math.ceil(distance))
          />
        </Wrapper>
      </Popper>
    </ClickAwayListener>
  );
}



async function getDistanceChunk(service: any, orig: string, dests: any) {
  let response = await service.getDistanceMatrix({
    origins: [orig],
    destinations: dests, // max 25
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.METRIC,
    avoidHighways: false,
    avoidTolls: false
  });
  if (response && response.rows?.length) {
    return response.rows[0].elements.map((r: any, i: number) => Object.assign(r, { address: response.destinationAddresses[i] }));
  } else {
    console.error(response);
    return false;
  }
}

export function Map({
  parent,
  orig,
  dest,
  onClose
}: {
  parent: any;
  orig: string;
  dest: any;
  onClose: any;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [localSubcontractors, setLocalSubcontractors] = useState<any>([]);
  const [selectedSubs, setSelectedSubs] = useState<any>([]);
  const [totalDistance, setTotalDistance] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      let subc = dest.filter((d: any) => d.subcontractor);
      const dests = subc.map((d: any) => `${d.address1}, ${d.postal} ${d.place}, ${d.country}`);
      const map = new window.google.maps.Map(ref.current, {
        center: { lat: 50.88715868200662, lng: 4.721457056035193 }, // MaW
        zoom: 9
      });

      if (orig) {
        // Place markers
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: orig }, (results, status) => {
          if (status !== 'OK') return console.error(`Geocode failed: ${status}]`);
          // Center map on client and place marker
          map.setCenter(results[0].geometry.location);
          new google.maps.Marker({ map,
              animation: window.google.maps.Animation.DROP,
              position: results[0].geometry.location,
              icon: {
                url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
              }
          });
          // Place subcontractor markers
          subc.forEach((sub: any, i: number) => {
            const [lat, lng] = sub.coord.split(',');
            subc[i].marker = new google.maps.Marker({ map,
              animation: window.google.maps.Animation.DROP,
              position: { lat: parseFloat(lat), lng: parseFloat(lng) },
              label: (n => n.includes(' ') ? n.substring(0, 1) + n.split(' ').pop().substring(0, 1) : n.substring(0, 2))(sub.contact_name || sub.name),
              title: sub.contact_name || sub.name
            });
          });
        });

        // Get distances
        const service = new window.google.maps.DistanceMatrixService();
        const addDistances = async function() {
          const destChunks = chunkArray(dests, 25);

          // destChunks.forEach(async (chunk, chIndex) => {
          for (let chIndex = 0; chIndex < destChunks.length; chIndex++) {
            let distanceChunk = await getDistanceChunk(service, orig, destChunks[chIndex]);

            if (distanceChunk.length) {
              // tack duration, distance & googleAddress onto subcontractors array
              distanceChunk.forEach((r: any, i: number) => {
                let wholeIndex = i + (25 * chIndex);
                const rBlank = {
                  distance: { text: 'not found', value: 0 },
                  duration: { text: '0 mins', value: 0 },
                  address: dests[wholeIndex]
                };
                subc[wholeIndex] = Object.assign(subc[wholeIndex], rBlank, r);
              });
            }
          }
          if (subc[0].distance.value) {
            subc.sort((a: any, b: any) => {
              return (a.distance.value > b.distance.value) ? 1 :
                ((b.distance.value > a.distance.value) ? -1 : 0);
            });
          }
          setLocalSubcontractors(subc);
        };
        addDistances();
      }
    }
  }, [ref, orig]);

  return (
    <IonGrid style={{ marginLeft: '145px', height: '85%', backgroundColor: '#353535' }}>
      <IonRow style={{ height: '100%' }}>
        <IonCol>
          <div ref={ref} id="map" style={{ height: '100%' }} />
        </IonCol>
        <IonCol style={{ height: '100%', overflow: 'hidden' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonButton style={{ marginRight: '10px' }} size="small" color="success" onClick={e => {
              const mi = parseCode(parent);
              onClose(mi.volume.sessions * Math.ceil(totalDistance / 10) * 10, selectedSubs);
            }}>
              <IonIcon slot="end" ios={icons.thumbsUpSharp} md={icons.thumbsUpOutline} />
              Done
            </IonButton>
            Total distance: <strong style={{ margin: '0 10px' }}>{totalDistance} km</strong>
            <div style={{margin: '0 10px 0 auto'}}>Mapping for {parent.code}</div>
          </div>
          <IonGrid style={{ height: '100%', overflow: 'auto'}}>
          {localSubcontractors.map((sub: any, i: number) => {
            return (
              <IonRow key={i} className={'ion-no-padding alternate-bg'}>
                <IonCol size={'5'} className={'ion-no-padding'} style={{ whiteSpace: 'nowrap' }}>
                  <SubcontractorCheckbox
                    value={sub.distance.value}
                    onChange={(e: any) => {
                      const newDist = totalDistance + (e.target.checked? 2 : -2) *
                        Math.round(parseInt(e.target.value) / 100) / 10;
                      setSelectedSubs(e.target.checked ? [...selectedSubs, sub] : selectedSubs.filter((s: any) => {
                        return sub.contact_name ? (sub.contact_name !== s.contact_name) : (sub.name !== s.name);
                      }));
                      setTotalDistance(Math.round(newDist * 10) / 10);
                      sub.marker.setAnimation(e.target.checked ? window.google.maps.Animation.BOUNCE : null);
                    }}
                  />
                  <span style={{ verticalAlign: 'middle' }}>
                    {sub.contact_name || sub.name}
                  </span>
                </IonCol>
                <IonCol className={'ion-no-padding'} style={{ fontSize: '82%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', alignSelf: 'center' }} title={sub.note ? sub.note.toLowerCase() : ''}>
                    {sub.note ? sub.note.toLowerCase() : ''}
                </IonCol>
                <IonCol size={'2'} className={'ion-text-center ion-no-padding'} style={{ alignSelf: 'center' }}>{sub.distance.text}</IonCol>
                <IonCol size={'1'} className={'ion-text-center ion-no-padding'} style={{ alignSelf: 'center' }}>{sub.duration.text.replace(/ hours?/, 'h').replace(/ mins?/, '′')}</IonCol>
              </IonRow>
            );
          })}
          </IonGrid>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

function SubcontractorCheckbox({ onChange, value }: { onChange: any, value: any }) {
  const [checked, setChecked] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<any>(value ?? {});

  useEffect(() => {
    setLocalValue(value || {});
  }, [value]);

  const handleChange = (e: any) => {
    setChecked(e.target.checked);
    onChange(e);
  };

  return (
    <Checkbox
      style={{ padding: '3px', marginRight: '6px' }}
      value={localValue}
      checked={checked}
      onChange={handleChange}
    />
  );
}

export async function getDirectionImages(origs: any, dest: string) {
  const service = new window.google.maps.DirectionsService();
  let imgs = [];
  for (let [index, orig] of origs.entries()) {
    if (index > 9) {
      await sleep(1000); // avoid Google direction_route's over_query_limit
    }
    imgs.push(await getDirectionImage(service, orig, dest));
  }
  return imgs;
  //return origs.map(async (orig: string) => {
  //  return await getDirectionImage(service, orig, dest);
  //});
}

async function getDirectionImage(service: any, orig: string, dest: string) {
  let response = await service.route({
    origin: orig,
    destination: dest,
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.METRIC,
    avoidHighways: false,
    avoidTolls: false
  });
  if (response) {
    let polyline = response.routes[0].overview_polyline;
    let image = 'https://maps.googleapis.com/maps/api/staticmap?size=500x400&language=nl&key=AIzaSyCvs077FgqXXHbhInbeq1xSENdWO4Gp6rU&path=enc%3A';
    return image + polyline +
      '&markers=icon:https://goo.gl/jRz3sH%7C' + encodeURIComponent(response.routes[0].legs[0].start_address.replace(/ /g, '+')) +
      '&markers=icon:https://goo.gl/NPLhaf%7C' + encodeURIComponent(response.routes[0].legs[0].end_address.replace(/ /g, '+'));
  }
  return '';
}




const formatCurrency = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: 'eur'
}).format;

export function dbFormatDate(date: Date) {
  if (!date) return '';
  return date.getFullYear() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate().toString().padStart(2, '0');
}

export function dbFormatDateTime(date: Date) {
  if (!date) return '';
  return dbFormatDate(date) +
    ' ' +
    date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0');
}

export function formatDate(value: string) {
  if (value) {
    return value.includes(':') ?
      new Date(value)
        .toLocaleString(navigator.language, {dateStyle: 'short', timeStyle: 'short'})
        .replace(',', '') :
      new Date(value).toLocaleDateString();
  }
  return '';
}

export function ForeignFormatter({ value }: { value: string }) {
  //console.log(value, clients.get(value));
  return <>{value}</>;
}

export function DateFormatter({ value }: { value: string }) {
  return <>{formatDate(value).replace('/' + new Date().getFullYear(), '')}</>;
}

export function CurrencyFormatter({ value }: { value: number }) {
  return <>{formatCurrency(value).replace(/^(\D)/, '$1 ')}</>;
}

export function PercentFormatter({ value }: { value: number }) {
  return <>{value}%</>;
}

export function stopPropagation(event: React.KeyboardEvent<HTMLDivElement>) {
  if (event.isDefaultPrevented()) {
    event.stopPropagation();
  }
}

export function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (['ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(event.key)) {
    event.stopPropagation();
  }
}

export function selectStopPropagation(event: React.KeyboardEvent<HTMLSelectElement>) {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
}

export function clearFocus(collectionName: string, gridRef: any, event: any, colIndex: number = 2) {
  const cl = event.srcElement.className;
  const clickArea = ['clickaway', 'default-buttons', 'toolbar-', 'inner-scroll'];
  const awayClass = cl.length && clickArea.some(c => cl.includes(c));
  const underRows = !cl.length && 'style' in event.srcElement.attributes && /^height: \d+px;$/.test(event.srcElement.attributes.style.value);
  if (underRows || awayClass) {
    gridRef.current!.selectCell({idx: ['products', 'templates'].includes(collectionName) ? 1 : colIndex, rowIdx: -1 });
  }
}

export function getSoldText(parent: any, options: any = {}) {
  options = { repeating: false, ourDate: false, includeSheet: false, ...options };
  const mi = parseCode(parent);
  const firstLast = (parent.contact_client.contact_name || parent.contact_client.name).split(' ');
  let text = (firstLast.length ? 'Dag ' + firstLast[0] : 'Hallo') + ",\n\nDank je wel voor de bevestiging.";
  text += options.repeating ? ' We kijken er naar uit om jullie te komen opvolgen!' : ' We kijken er naar uit om jullie in de watten te komen leggen!';
  if (options.ourDate) {
    text += ` Wat dacht je van ${mi.time.day} ${mi.time.date} om ${mi.time.start}? Andere datums kunnen ook, geef maar aan wanneer het best past.`;
  }
  text += "\n\n";
  text += options.includeSheet && parent.sheet ? "Hier vind je 'n uurrooster waar jullie deelnemers op kunnen intekenen:\n\n" + parent.sheet + "\n\nDie link kunnen jullie intern doorgeven. Je kan het rooster ook zelf invullen of helemaal niet gebruiken. Wijzigingen worden onmiddellijk getoond aan iedereen die de pagina open heeft, dus je kan deze link gerust aan iedereen tegelijk doorsturen. Wens je er iets aan te wijzigen? We passen het graag voor je aan.\n\n" :
    "Werken jullie graag met een uurrooster? Dan stuur ik er eentje door waar jij of jullie deelnemers op kunnen intekenen.\n\n";
  text += "Behalve zo'n 10 m² werkruimte per masseur hoeven jullie geen speciale voorbereidingen te treffen. 'n Aparte ruimte (ev. met meerdere masseurs) komt het vaakst voor. We kunnen ook wat achtergrondmuziek meenemen.\n\nIk doe het nodige en hou je op de hoogte.\n\nVriendelijke groeten,\nGoedele";
  return text;
}

export function getFilledText(parent: any, options: any = {}) {
  const mi = parseCode(parent);
  const arrival = DateTime.fromFormat(mi.time.start, "HH'u'mm").minus({minutes: 30}).toFormat("HH'u'mm");
  const firstLast = (parent.contact_client.contact_name || parent.contact_client.name).split(' ');

  let text = (firstLast.length ? 'Dag ' + firstLast[0] : 'Hallo') + ",\n\n" + `Ik kan intussen bevestigen dat jullie ons op ${mi.time.day} ${mi.time.date} mogen verwachten rond ${arrival}.` + "\n" + `De massages staan ingepland van ${mi.time.start} tot ${mi.time.stop} op het volgende adres:` + "\n\n" + mi.address + "\n\n" + `Als er een speciale toelating nodig is om binnen te geraken - of indien er een parkeerplaats gereserveerd dient te worden  - horen we het graag.` + "\n\n";

  if (options.includeSheet && parent.sheet) {
    text += "Hier vind je 'n uurrooster waar jullie deelnemers op kunnen intekenen:\n\n" + parent.sheet + "\n\nDie link kunnen jullie intern doorgeven. Je kan het rooster ook zelf invullen of helemaal niet gebruiken. Wijzigingen worden onmiddellijk getoond aan iedereen die de pagina open heeft, dus je kan deze link gerust aan iedereen tegelijk doorsturen. Wens je er iets aan te wijzigen? We passen het graag voor je aan.\n\n";
  }

  text += `Onze massages worden over de kleren heen gegeven en er worden geen lotions gebruikt. Mannen met een das zullen eventueel gevraagd worden hun das even te lossen of te verwijderen.` + "\n\n" + `Bedankt voor je vertrouwen in Massage at Work!` + "\n\nMvg,\nGoedele";

  return text;
}


/************ Ronselator Functions *************/

export function getQuoteDescription(parent: any, mods: any) {

  const codes = parent.code.split('+');
  let formule = '';
  let formuleEn = '';
  let massageDescr = [];
  let massageDescrEn = [];

  for (const [i, code] of codes.entries()) {

    const mi = parseCode(parent, i);

    formule += i === 0 ? 'Formule voor' : '';
    formule += codes.length > 1 ? (i === 0 ? ":\n- " : "\n- ") : ' ';
    formule += `${mi.volume.sessions > 1 ? mi.volume.sessions + ' sessies van ' : (codes.length > 1 ? 'Een' : 'een') + ' sessie van '}${mi.volume.massages} massages${mi.volume.sessions > 1 ? ' elk' : ''}, gegeven door ${mi.volume.masseurs} masseur${mi.volume.masseurs > 1 ? 's' : ''} die ${mi.volume.sessions > 1 ? 'telkens ' : ''}gedurende ${toHoursMinutes(mi.duration.session.anyPresence)} aanwezig ${mi.volume.masseurs > 1 ? 'zullen' : 'zal'} zijn. `;

    formuleEn += i === 0 ? 'This quote covers' : '';
    formuleEn += codes.length > 1 ? (i === 0 ? ":\n- " : "\n- ") : ' ';
    formuleEn += `${mi.volume.sessions > 1 ? mi.volume.sessions + ' sessions comprising ' : (codes.length > 1 ? 'A' : 'a') + ' session comprising '}${mi.volume.massages} massages${mi.volume.sessions > 1 ? ' each' : ''}, by ${mi.volume.masseurs > 1 ? mi.volume.masseurs + ' therapists' : 'a single therapist'} who'll be at the premises for ${toHoursMinutes(mi.duration.session.anyPresence, true)}${mi.volume.sessions > 1 ? ' per session' : ''}. `;

    if (mi.volume.breaks > 0) {
      formule += `Er ${mi.volume.breaks > 1 ? 'worden ' + mi.volume.breaks + ' breaks' : 'wordt een break'} van ${mi.duration.break} min voorzien. `;
      formuleEn += `We've scheduled ${mi.volume.breaks} break${mi.volume.breaks > 1 ? 's' : ''} lasting ${mi.duration.break} minutes. `;
    }

    if (mi.volume.lunches == 1) {
      formule += `De lunch van ${mi.duration.lunch} min wordt niet aangerekend.`;
      formuleEn += `We don't charge for lunchtime (${mi.duration.lunch} mins).`;
    }

    if (!massageDescr.join(' ').includes(` ${mi.duration.massage} min`)) {
      massageDescr.push(`${mi.duration.massage > 20 ? 'individuele ' : (mi.duration.massage < 20 ? 'ingekorte ' : 'standaard')}massage van ${mi.duration.massage} min`);
      massageDescrEn.push(`${mi.duration.massage > 20 ? 'individual' : (mi.duration.massage < 20 ? 'short' : 'recommended')} ${mi.duration.massage}-minute massage`);
    }

    if (codes.length === i + 1) {

      formule += "\n\nDeze formule is maandelijks hernieuwbaar (optioneel).";
      formuleEn += "\n\nYou can (optionally) renew the formula contained in this quote on a monthly basis.";

      formule += ` We gingen uit van onze ${toHumanList(massageDescr, ' en de ', ', de ')}. Ons uurtarief daalt naarmate je ons langer aan het werk houdt.` + "\n\n";
      formuleEn += ` These are our ${toHumanList(massageDescrEn, ' and the ', ', the ')}. Our hourly rate decreases the longer each visit lasts.` + "\n\n";

      if (mods.offerSheet) {
        formule += "Werken jullie graag met een uurrooster? Dan stuur ik er eentje door waar jij of jullie deelnemers op kunnen intekenen. ";
        formuleEn += "We can help out with timeslot assignment by providing you with an online schedule. Just let me know! ";
      }

      if (mods.ourDate) {
        formule += "De vermelde datum is 'n eerste voorstel. Als jullie graag een andere dag willen kan dat natuurlijk ook. ";
        formuleEn += "The listed date is an initial suggestion. Let us know if you have something else in mind. ";
      }

      if (mods.soon) {
        formule += "We zullen ons best doen deze nog ingevuld te krijgen.";
        formuleEn += "We'll try our best to move some engagements and make this happen.";
      }
    }
  }


  //copyToClipboard(formule);
  //setText(formule, formuleEn);

  return mods.english ? formuleEn : formule;
}

export function getContractDescription(parent: any, team: string) {
  const mi = parseCode(parent);

  let volMgs: string = mi.volume.massagesPerMas.toString();
  if (!Number.isInteger(mi.volume.massagesPerMas)) {
    volMgs = Math.ceil(mi.volume.massagesPerMas) + ' (ev. ' + Math.floor(mi.volume.massagesPerMas) + ')';
  }

  let formule = `- Voor ${volMgs} massages van ${mi.duration.massage} min` + "\n" + `- Op ${mi.time.day} ${mi.time.date} van ${mi.time.start} tot ${mi.time.stop}`;

  if (mi.volume.breaks > 0)
    formule += "\n" + `- Er ${mi.volume.breaks > 1 ? 'worden ' + mi.volume.breaks + ' breaks' : 'wordt een break'} van ${mi.duration.break} min voorzien`;

  if (mi.volume.lunches > 0)
    formule += "\n" + `- Lunchpauze van ${mi.duration.lunch} min`;

  // Team
  if (mi.volume.masseurs > 1) {
    formule += "\n- Team: " + team;
  }

  return formule;
}

// removed 2nd param includeSelfInFormula: boolean = false
export function parseCode(parent: any, codeIndex: number = 0) {

  // If parent contains a multicode, pick the one requested (e.g. 20p 2mas+9p 1mas)
  let code = parent.code.split('+')[codeIndex].trim();

  // Process mission code
  const codeArr = code.split(' ');
  const startTime = parent.loc_date ?
    parent.loc_date.split(' ')[1].replace(':', 'u') :
    '09u30';
  const pReg = {
    massages: /^(\d+x)?(\d*)p(\d*)$/i,
    masseurs: /^(\d*)mas$/i
  };
  // volume.massages is het totaal per session (alle masseurs samen)
  // visit = session + lunch
  let mi = {
    source: '',
    client: '',
    formula: '',
    address: '',
    volume: {
      sessions: 1,
      massages: 0,
      masseurs: 1,
      lunches: 0,
      breaks: 0,
      massagesPerMas: 0
    },
    duration: {
      mission: 0,
      visit: 0,
      session: { anyPresence: 0, avgPresence: 0 },
      massage: 20,
      lunch: 40,
      break: 10
    },
    time: { start: startTime, stop: '', date: '', day: '' }, // '9u30'
    slots: [''],
    rate: { client: 0, masseur: 0, travel: 0, clientNet: 0 },
    margin: { base: 0, percent: 0 }
  };
  codeArr.forEach((part: string) => {
    let prop: any = [];
    if (pReg.massages.test(part)) {
      // massages 2x15p5
      prop = part.match(pReg.massages);
      if (prop[1] && prop[1].length) mi.volume.sessions = prop[1].slice(0, -1);
      if (prop[2] && prop[2].length) mi.volume.massages = parseInt(prop[2]);
      if (prop[3] && prop[3].length) mi.duration.massage = parseInt(prop[3]);
    } else if (pReg.masseurs.test(part)) {
      // masseurs 5mas
      prop = part.match(pReg.masseurs);
      if (prop[1] && prop[1].length) mi.volume.masseurs = parseFloat(prop[1]);
    }
  });

  // Set nonstandard break & lunch duration
  if ([15, 30].some(dur => mi.duration.massage === dur)) {
    mi.duration.lunch = 30;
  }
  if ([12, 15].some(dur => mi.duration.massage === dur)) {
    mi.duration.break = mi.duration.massage;
  }

  // How many massages per masseur per session, on average
  mi.volume.massagesPerMas = mi.volume.massages / mi.volume.masseurs;

  // How many mins per session (last one to leave)
  mi.duration.session.anyPresence = Math.ceil(mi.volume.massagesPerMas) * mi.duration.massage;

  // Calculates & displays roster, sets mi.time.stop, mi.volume.breaks, mi.volume.lunches
  if (mi.time.start) {
    let slots = returnSlotsArray(mi);
    const endSlot = slots[slots.length - 1];
    mi.volume.breaks = slots.reduce((n, x) => n + +x.includes('break'), 0);
    mi.volume.lunches = slots.reduce((n, x) => n + +x.includes('lunch'), 0);
    mi.time.stop = endSlot.substring(0, endSlot.indexOf(' ')).replace(':', 'u');
    mi.slots = slots;

    let dParts = parent.loc_date.split(' ')[0].split('-');
    mi.time.date = dParts[2] + '/' + dParts[1];
    mi.time.day = new Date(parent.loc_date).toLocaleString('nl-NL', { weekday: 'long' });
  }

  // session = werkuren + breaks
  mi.duration.session.anyPresence += mi.duration.break * mi.volume.breaks;
  // visit = session + lunch
  mi.duration.visit = mi.duration.session.anyPresence + (mi.duration.lunch * mi.volume.lunches);

  // Exacte totaalduur in mins
  // niet 'last one to leave' anyPresence maar eerder avgPresence x nr sessions
  mi.duration.session.avgPresence = (mi.volume.massagesPerMas * mi.duration.massage) + (mi.duration.break * mi.volume.breaks);
  mi.duration.mission = Math.round((mi.duration.session.avgPresence * mi.volume.masseurs * mi.volume.sessions / 60) * 100) / 100;

  // Client, adres en korte docnaam VO/047 (client_name for invoices)
  const cl = (parent.contact_client || parent.client_name || parent.loc_client);

  mi.client = cl.name ? cl.name : (cl || '');
  const loc = parent.loc_client;
  mi.address = parent.loc_address ?
    parent.loc_address.replace(/\n/g, ', ') :
    (loc ?
      `${loc.address1 ? loc.address1 + ', ' : ''}${loc.postal} ${loc.place}` :
      ''
    );
  const source = (parent.origin || parent.order_name || parent.name).split('/');
  mi.source = `${source[0]}/${source[2]}`;

  if (parent.lines?.length) {
    const firstService = parent.lines.find((l: any) => l.unit === 'uren');
    const firstTravel = parent.lines.find((l: any) => l.unit === 'km');
    if (firstService && firstTravel) {
      mi.rate = {
        client: parseFloat(firstService.price_client),
        clientNet: Math.round(parseFloat(firstService.price_client) * (1 - parseFloat(firstService.discount) / 100) * 100) / 100,
        masseur: parseFloat(firstService.price_supplier),
        travel: parseFloat(firstTravel.price_supplier)
      };

      // Calculate base manhour expense per subcontractor and without travel costs
      // Subcontractors always leave last, while we ourselves always leave first
      mi.margin = {
        base: Math.round((((Math.ceil(mi.volume.massagesPerMas) * mi.duration.massage) + (mi.duration.break * mi.volume.breaks)) / 60) * mi.rate.masseur),
        percent: (mi.rate.clientNet - mi.rate.masseur) / mi.rate.clientNet
      };

      const title = mi.client.replace(/[ \-'"/%]/g, '');
      const margin = parent.amount_margin || (mi.margin.percent * parent.amount_untaxed);
      const strDiscount = firstService.discount ? firstService.discount + '% ' : '';

      mi.formula = `${title} ${code} ${mi.source} ${Math.ceil(margin)}/t ${strDiscount}${firstService.price_client}/u+${firstService.price_supplier}/u ${mi.time.date} ${mi.time.start}-${mi.time.stop} ${mi.address}`;
    }
  }

  return mi;
}

// Calculate own massage expense (we always stop earliest where applicable)
export async function addSelfMargin(mi: any) {
  let strSelf = '';
  let self = (((Math.floor(mi.volume.massagesPerMas) * mi.duration.massage) + (mi.duration.break * mi.volume.breaks)) / 60) * mi.rate.masseur;

  // Add travel expense
  if (!window.google || !window.google.maps) {
    await new Loader({ apiKey: "AIzaSyCvs077FgqXXHbhInbeq1xSENdWO4Gp6rU" }).load();
  }
  const service = new window.google.maps.DistanceMatrixService();
  const orig = 'Gemeentestraat 51, 3010 Kessel-Lo, België';

  const route = await getDistanceChunk(service, orig, [mi.address]);
  if ('distance' in route[0]) {
    self += 2 * Math.ceil(route[0].distance.value / 1000) * mi.rate.travel;
  } else {
    console.log('Couldn\'t get Goedele\'s distance to', mi.address, route);
  }

  mi.margin.self = Math.round(self);
  mi.formula = mi.formula.replace('/t ', `/t+${mi.margin.self}/d `);

  return mi;
}

function returnSlotsArray(mi: any) {
  let from = DateTime.fromFormat(mi.time.start.replace('u', ':'), 'H:mm');
  let lunch = DateTime.fromFormat('12:00', 'H:mm');
  let slots = [];
  let sinceBr = 0; // running tally mins worked since last break
  let ppl = 0; // running tally of massages

  const nextUp = () => {
    let mStr = '';
    for (let m = 0; m < mi.volume.masseurs; m++) {
      if (ppl < mi.volume.massages)
        mStr += (mStr.length ? '-' : '') + ++ppl;
    }
    return mStr;
  };

  while (ppl < mi.volume.massages) {
    // minutes left until end
    let tillEnd = (Math.ceil(mi.volume.massagesPerMas) - ppl / mi.volume.masseurs) * mi.duration.massage;

    // Consider adding break or lunch if over an hour left
    if (tillEnd >= 60) {
      let tillLunch = Interval.fromDateTimes(from, lunch).length('minutes');
      // tillBrLunch: mins till next hard stop (lunch or end, whichever is first)
      let tillBrLunch = from.hour < 12 ? tillLunch : tillEnd;
      let isLunchtime = from.hour == 12 && !from.minute;
      let isNearlyLunch = from.hour < 12 && tillLunch < mi.duration.massage;

      // Insert lunch if at or near lunch (closer to lunch than massage lasts)
      if (ppl && (isLunchtime || isNearlyLunch)) {
        slots.push(from.toFormat('HH:mm') + ' | lunch'); //DateTime.fromObject({ ...from })
        from = from.plus({ minutes: mi.duration.lunch });
        sinceBr = 0;
      }
      // Insert break if 2h+ since break, or in the middle of hard 2h periods
      else if (sinceBr >= 120 || (sinceBr > 60 && tillBrLunch > 60 && sinceBr >= tillBrLunch)) {
        slots.push(from.toFormat('HH:mm') + ' | break');
        from = from.plus({ minutes: mi.duration.break });
        sinceBr = 0;
      }
    }

    // Insert massage slot
    slots.push(from.toFormat('HH:mm') + ' | ' + nextUp());
    from = from.plus({ minutes: mi.duration.massage });
    sinceBr += parseInt(mi.duration.massage);
  }

  // Insert ending
  slots.push(from.toFormat('HH:mm') + ' | einde');

  return slots;
}

export async function modifyEvent(cal: any, ids: string[], operations: any) {

  if (ids.length) {

    for (const id of ids) {

      let ops = {
        setSummary: '',
        setLocation: '',
        setStart: {},
        setEnd: {},
        addCats: [],
        removeCats: [],
        prependDescr: '',
        ...operations
      };
      let resource: any = {};

      const { result } = await cal.events.get({
        calendarId: 'primary',
        eventId: id
      });

      if ('error' in result) {
        console.error(`Something went wrong on Google's end.`, result);
        return false;
      }

      if (ops.setSummary.length) {
        resource.summary = ops.setSummary;
      }

      if (ops.setLocation.length) {
        resource.location = ops.setLocation;
      }

      if (Object.keys(ops.setStart).length) {
        resource.start = ops.setStart;
      }

      if (Object.keys(ops.setEnd).length) {
        resource.end = ops.setEnd;
      }

      if (ops.addCats.length || ops.removeCats.length) {
        let cats = result.extendedProperties.shared['X-MOZ-CATEGORIES'].split(',');
        ops.addCats.forEach((c: string) => (!cats.includes(c) && cats.push(c)));
        cats = cats.filter((c: string) => !ops.removeCats.includes(c));
        cats.sort();
        resource.extendedProperties = { shared: {'X-MOZ-CATEGORIES': cats.join(',')} };
      }

      if (ops.prependDescr.length) {
        resource.description = ops.prependDescr + result.description;
      }

      //console.log(id, resource);

      const patched = await cal.events.patch({
        calendarId: 'primary',
        eventId: id,
        resource
      });

      if (!patched.result.htmlLink) {
        console.error(`Something went wrong on Google's end.`, patched, result);
        return false;
      }
    }

    return true;
  }

  return false;
}


// Google Sheets stuff

export function getSheetBody(parent: any) {

  const headerRows = 4;
  const footerRows = 3;
  const bgGreen = { red: 0.05882353, green: 0.6156863, blue: 0.34509805 };
  const bgBreak = { red: 0.85098039, green: 0.9176471, blue: 0.82745098 };
  const bgGrey = { red: 0.9372549, green: 0.9372549, blue: 0.9372549 };
  const bgWhite = { red: 1, green: 1, blue: 1 };
  const borderGrey = { red: 0.8509804, green: 0.8509804, blue: 0.8509804 };
  const border = { color: borderGrey, style: 'SOLID', width: 1 };

  let sheetBody: { properties: any; sheets: any[] } = {
    properties: { title: '' },
    sheets: []
  };
  let codes = parent.code.split('+');

  // Create a sheet for each mission code (e.g. 20p 2mas+9p 1mas)
  for (const [codeIndex, code] of codes.entries()) {

    let merges = [];
    let colmeta = [];
    let rowmeta = [];
    // date, weekday, slot rows with blank left margin col prefilled
    let rowdata: any = [ { values: [{}] }, { values: [{}] }, { values: [{}] } ];

    let mi = parseCode(parent, codeIndex);

    // totalCols gets 1 left margin col;
    // each session then gets a left time col and a right margin
    let totalCols = 1 + mi.volume.sessions * (2 + mi.volume.masseurs);
    let colWidth = totalCols > 4 ?
      { time: 60, slot: 180, margin: 20 } :
      { time: 80, slot: 280, margin: 20 };
    if (totalCols > 9) {
      colWidth = { time: 45, slot: 120, margin: 20 };
    }

    if (!sheetBody.properties.title) {
      sheetBody.properties.title = 'Massages ' + mi.client + ' ' + mi.time.date;
    }

    sheetBody.sheets.push({
      properties: {
        title: codes.length > 1 ? `Rooster ${codeIndex + 1}` : 'Uurrooster',
        gridProperties: {
          rowCount: headerRows + footerRows + mi.slots.length,
          columnCount: totalCols,
          hideGridlines: true
        },
        index: codeIndex,
        sheetId: codeIndex,
        sheetType: 'GRID'
      },
      merges: [
        { sheetId: codeIndex, startRowIndex: 0, endRowIndex: 1, startColumnIndex: 1, endColumnIndex: totalCols - 1 }
      ],
      data: [{
        rowData: [
          {
            values: [
              { userEnteredFormat: { backgroundColor: bgGreen } },
              {
                userEnteredFormat: {
                  backgroundColor: bgGreen,
                  textFormat: {
                    fontSize: 21,
                    foregroundColor: {red: 1, green: 1, blue: 1}
                  }
                },
                userEnteredValue: {stringValue: 'Uurrooster Massage at Work'}
              }
            ]
          }
        ],
        rowMetadata: [
          {pixelSize: 36}, // Title row
          {pixelSize: 48}, // Date row
          {pixelSize: 30}, // Weekday row
          {pixelSize: 30}  // Slot row (Stoel 1 etc)
        ],
        columnMetadata: [
          {pixelSize: colWidth.margin} // margin
        ]
      }]
    });


    // Process sessions

    // each session gets a left time col and a right margin col...
    for (let s = 0; s < mi.volume.sessions; s++) {
      // add time col meta
      colmeta.push({ pixelSize: colWidth.time }); // time
      // each masseur gets a slot col
      for (let m = 0; m < mi.volume.masseurs; m++) {
        colmeta.push({ pixelSize: colWidth.slot }); // slot
      }
      // add right margin (each session gets one)
      colmeta.push({ pixelSize: colWidth.margin });

      // add date & weekday merges
      const sta = 2 + s * (2 + mi.volume.masseurs); // left margin + time col, so 2 + s * ..
      const sto = sta + mi.volume.masseurs;
      merges.push({ sheetId: codeIndex, startRowIndex: 1, endRowIndex: 2, startColumnIndex: sta, endColumnIndex: sto });
      merges.push({ sheetId: codeIndex, startRowIndex: 2, endRowIndex: 3, startColumnIndex: sta, endColumnIndex: sto });

      // add to the date & weekday rowdata arrays
      rowdata[0].values.push(
        {}, // time col
        {
          userEnteredFormat: {
            horizontalAlignment: 'CENTER',
            numberFormat: {type: 'DATE', pattern: 'd/m'}
          },
          userEnteredValue: {formulaValue: '=TEXT(DATEVALUE("' + mi.time.date + '"); "DD/MM")'}
        }
      );
      rowdata[1].values.push(
        { userEnteredFormat: { borders: { bottom: border } } },
        {
          userEnteredFormat: {
            textFormat: { fontSize: 11, bold: true },
            horizontalAlignment: 'CENTER',
            verticalAlignment: 'MIDDLE',
            borders: { bottom: border }
          },
          userEnteredValue: {formulaValue: '=UPPER(TEXT(INDIRECT(ADDRESS(ROW()-1;COLUMN())); "DDDD"))'}
        }
      );
      // pad date & weekday rowdata arrays with empty cols so the next session processes correctly
      for (let remain = 0; remain < sto - sta; remain++) {
        rowdata[0].values.push({});
        rowdata[1].values.push({});
      }

      // add bottom border serving as the time col's top border
      rowdata[2].values.push({ userEnteredFormat: { borders: { bottom: border } } });
      // each masseur gets its slot rowdata (STOEL 1 2 row)
      for (let m = 0; m < mi.volume.masseurs; m++) {
        rowdata[2].values.push({
          userEnteredFormat: {
            textFormat: { fontSize: 11, bold: true },
            horizontalAlignment: 'CENTER',
            borders: { bottom: border }
          },
          userEnteredValue: {stringValue: 'STOEL ' + (m + 1)}
        });
      }
      rowdata[2].values.push({}); // this session's right margin

      // processing the slot rows themselves...
      let r = 3;
      // for (var r = 3; r < Math.ceil(mi.volume.massagesPerMas) + 3; r++) {
      for (let i = 0; i < mi.slots.length; i++) {
        if (!rowdata[r]) {
          rowdata[r] = { values: [] };
        }
        if (s == 0) {
          rowdata[r].values.push({}); // this is the initial left margin
        }
        const isBreak = mi.slots[i].indexOf('break') > -1;
        const isLunch = mi.slots[i].indexOf('lunch') > -1;
        const isDone = mi.slots[i].indexOf('einde') > -1;
        const isDoneAtSameTime = isDone && !(mi.volume.massages % mi.volume.masseurs);
        const isWork = !isBreak && !isLunch && !isDone;
        const isFinalSlot = !isDone && mi.slots[i + 1].indexOf('einde') > -1;
        const pThisSlot = isWork ? mi.slots[i].split('-').length : 0;
        const wasBreak = i > 0 && mi.slots[i - 1].indexOf('break') > -1;
        const wasLunch = i > 0 && mi.slots[i - 1].indexOf('lunch') > -1;
        const pLastSlot = i > 0 && !wasBreak && !wasLunch ? mi.slots[i - 1].split('-').length : 0;
        const timeParts = mi.time.start.split(/[:uh]/);
        rowdata[r].values.push(
          {
            userEnteredFormat: {
              backgroundColor: isWork ? (r % 2 ? bgGrey : bgWhite) : bgBreak,
              borders: { left: border, right: border, bottom: isDone ? border : null },
              horizontalAlignment: "RIGHT",
              verticalAlignment: 'MIDDLE',
              numberFormat: {type: "TIME", pattern: 'h:mm'}
            },
            userEnteredValue: r == 3
              ? { formulaValue: '=TIME(' + timeParts[0] + ';' + timeParts[1] + ';0)' }
              : { formulaValue: '=INDIRECT(ADDRESS(ROW()-1;COLUMN())) + TIME(0;' + (wasBreak ? mi.duration.break : (wasLunch ? mi.duration.lunch : mi.duration.massage)) + ';0)' }
          }
        );

        if ((isBreak || isLunch || isDoneAtSameTime) && mi.volume.masseurs > 1) {
          merges.push({ sheetId: codeIndex, startRowIndex: r + 1, endRowIndex: r + 2, startColumnIndex: rowdata[r].values.length, endColumnIndex: rowdata[r].values.length + mi.volume.masseurs });
        }

        // each masseur gets its slot rowdata (empty cells)
        for (let m = 0; m < mi.volume.masseurs; m++) {
          const isDoneEarly = isFinalSlot && (m >= pThisSlot);
          const wasDoneEarly = isDone && !isDoneAtSameTime && (m >= pLastSlot);
          // This is just filling blanks for merged cells
          if ((isBreak || isLunch || isDoneAtSameTime || wasDoneEarly) && m > 0) {
            rowdata[r].values.push({});
          // This provides the empty cells as well as any others
          } else {
            rowdata[r].values.push(
              {
                userEnteredFormat: {
                  backgroundColor: isWork && !isDoneEarly ? (r % 2 ? bgGrey : bgWhite) : bgBreak,
                  borders: { right: border, bottom: isDone || isDoneEarly ? border : null },
                  horizontalAlignment: 'CENTER',
                  verticalAlignment: 'MIDDLE'
                },
                userEnteredValue: {
                  stringValue: isBreak ? 'Break' : (isLunch ? 'Lunch' : (isDone || isDoneEarly ? 'Einde' : '' ))
                }
              }
            );
          }
        }

        rowdata[r].values.push({}); // right margin for session

        // only for initial session (one height per timeslot row)
        if (s == 0) {
          rowmeta.push({pixelSize: 30}); //
        }

        r++;
      }
    }

    /*
    // extraRow for top border
    var extraRow = { values: [{}] }; // initial left margin
    var topBorder = { userEnteredFormat: { borders: { top: border } } };
    for (var s = 0; s < mi.volume.sessions; s++) {
      extraRow.values.push(topBorder);
      for (var m = 0; m < mi.volume.masseurs; m++) {
        extraRow.values.push(topBorder);
      }
      extraRow.values.push({}); // right margin
    }
    rowdata.push(extraRow);
    rowmeta.push({pixelSize: 50});
    */

    // merged copyright line
    merges.push({ sheetId: codeIndex, startRowIndex: rowdata.length + 1, endRowIndex: rowdata.length + 2, startColumnIndex: 2, endColumnIndex: totalCols - 1 });
    rowmeta.push({pixelSize: 50});
    rowdata.push({
      values: [
        {},
        {
          userEnteredValue: {
            formulaValue: '=UNICHAR(169) & " " & YEAR(NOW())'
          }
        },
        {
          userEnteredValue: {
            formulaValue: '=HYPERLINK("https://massageatwork.be"; "Massage at Work")'
          }
        }
      ]
    });


    // final merged 'green line' row for color
    merges.push({ sheetId: codeIndex, startRowIndex: rowdata.length + 1, endRowIndex: rowdata.length + 2, startColumnIndex: 0, endColumnIndex: totalCols - 1 });
    rowmeta.push({pixelSize: 8});
    rowdata.push({ values: [{userEnteredFormat: { backgroundColor: bgGreen }}] });

    merges.forEach(el => sheetBody.sheets[codeIndex].merges.push(el));
    rowdata.forEach((el: any) => sheetBody.sheets[codeIndex].data[0].rowData.push(el));
    rowmeta.forEach(el => sheetBody.sheets[codeIndex].data[0].rowMetadata.push(el));
    colmeta.forEach(el => sheetBody.sheets[codeIndex].data[0].columnMetadata.push(el));

  }

  return sheetBody;
}



// 125 to '2 uren en 5 min'
function toHoursMinutes(m: number, toEnglish: boolean = false) {
  var minutes = m % 60;
  var hours = Math.floor(m / 60);
  if (toEnglish) {
    return (hours > 0 ? hours + ' hours' : '') + (minutes > 0 ? ' and ' + minutes + ' mins' : '');
  } else {
    return (hours > 0 ? hours + ' uren' : '') + (minutes > 0 ? ' en ' + minutes + ' min' : '');
  }
}

// 9u10 to 9.17
function toHoursDecimal(str: string) {
  var strArr = str.split(/[hu:]/);
  return Math.round(((parseInt(strArr[1]) / 60) + parseInt(strArr[0])) * 100) / 100;
}

/************ JS Utility Functions *************/

export function different(a: any, b: any) {
  return Object.entries(a).sort().toString() !== Object.entries(b).sort().toString();
}

// Turns ([1,2,3,4], 2) into [[1,2],[3,4]]
function chunkArray(list: any, chunkSize: number) {
  return [...Array(Math.ceil(list.length / chunkSize))]
    .map(_ => list.splice(0, chunkSize));
}

// Turns ['foo', 'bar', 'zed'] into 'foo, bar and zed'
function toHumanList(list: any, lastConjunction: string = ' and ', separator: string = ', ') {
  let conjParts = [list.slice(0, -1).join(separator), list.slice(-1)[0]];
  return conjParts.join(list.length < 2 ? '' : lastConjunction);
}

function isNumeric(str: string) {
  if (typeof str != "string") return false // we only process strings
  return !isNaN(parseFloat(str));
}

function autoFocusAndSelect(input: HTMLInputElement | null) {
  input?.focus();
  input?.select();
}

async function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}