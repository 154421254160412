import { createContext, useContext, useState, useMemo, useRef, useEffect, useLayoutEffect as useOriginalLayoutEffect } from 'react';
import { SelectColumn, SelectCellFormatter } from 'react-data-grid';
import type { Column, SortColumn, HeaderRendererProps, FormatterProps, SummaryFormatterProps, DataGridHandle } from 'react-data-grid';
import * as Format from './formatUtils';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export interface Template {
  id?: string;
  name: string;
  for: string;
  title: string;
  template: string;
  filename: string;
  files: string;
}

export interface Summary {
  name: string;
  totalCount: number;
}

export interface Filter {
  name: string;
}

export type Comparator = (a: Template, b: Template) => number;

export const template: Template = {
  name: '',
  for: '',
  title: '',
  template: '',
  filename: '',
  files: ''
};

function comparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case 'id':
    case 'name':
    case 'for':
    case 'title':
    case 'template':
    case 'filename':
    case 'files':
      return (a: any, b: any) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

// Context (to read filter values) prevents columns re-creation when filters change/lose focus
export const filterContext = createContext<Filter | undefined>(undefined);


export function Dropdown({ value, options, onChange, onBlur }: { value: string, options: ReadonlyMap<string, any>, onChange: any, onBlur?: any }) {
  const [localValue, setLocalValue] = useState(value ?? '');  // we want to keep value locally
  useEffect(() => {
    if (!value && options.size) {
      onChange(options?.values().next().value?.name);
    }
    setLocalValue(value || options?.values().next().value?.name || '')
  }, [value, options]);       // we want to update local value on prop value change

  const handleChange = (e: any) => {
    const value = e.target.value;
    setLocalValue(value);
    if (onChange) {
      onChange(value);
    }
  };
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e.target.value);
    }
  };
  return (
    <Select
      size="small"
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
    >

      {Array.from(options, ([key, tpl]) => {
        //if (tpl.for === 'quote') { // && tpl.name !== '1e offerte nieuwe klant'
          return (<MenuItem key={tpl.name} value={tpl.name}>{tpl.name}</MenuItem>);
        //}
      })}
    </Select>
  );
}


function TemplateFilterRenderer<R, SR, T extends HTMLOrSVGElement>(
  { isCellSelected, column, children }: HeaderRendererProps<R, SR> &
  { children: (args: { ref: React.RefObject<T>; tabIndex: number; filters: Filter; }) => React.ReactElement; }
) {
  const filters = useContext(filterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  return (<><div>{column.name}</div><div>{children({ ref, tabIndex, filters })}</div></>);
}

export function getSummaryRows(rows: any) {
  let summaryRow: Summary = {
      name: 'total_0',
      totalCount: rows.length
    };
    return [summaryRow];
}

export function getColumns(filters: any, setFilters: any, gridRef: any): readonly Column<Template, Summary>[] {
  return [
    SelectColumn,
    {
      key: 'id',
      name: 'ID',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: '',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 240,
      cellClass: 'highlight-name sel-wider wide-2 fade',
      summaryFormatter(props: SummaryFormatterProps<Summary, Template>) {
        return <>{props.row.totalCount} in total</>;
      },
      headerCellClass: 'filter-cell',
      headerRenderer: (props: HeaderRendererProps<Template, Summary>) => (
        <TemplateFilterRenderer<Template, Summary, HTMLInputElement> {...props}>
          {({ filters, ...rest }) => (
            <input {...rest}
              className="filters"
              value={filters.name}
              onChange={(e) => setFilters({ name: e.target.value })}
              onKeyDown={Format.inputStopPropagation}
            />
          )}
        </TemplateFilterRenderer>
      )
    },
    {
      key: 'template',
      name: 'Template',
      editor: (props: any) => Format.TextFieldEditor(props, gridRef),
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      cellClass: 'sel-wider wide-1 fade'
    },
    {
      key: 'title',
      name: 'Titel',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 250,
      cellClass: 'sel-wider wide-2 fade'
    },
    {
      key: 'for',
      name: 'Voor',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 100,
      cellClass: 'highlight-status'
    },
    {
      key: 'filename',
      name: 'Bestandsnaam',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 150,
      cellClass: 'sel-wider wide-2 fade'
    },
    {
      key: 'files',
      name: 'Bijlage',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 80,
      cellClass: 'sel-wider wide-3 fade'
    }
  ];
};

export function getFilteredSortedRows(rows: any, sortColumns: any, filters: any): readonly Template[] {
  // first filter, then sort
  const filteredRows = rows.filter((r: any) => {
    if (!filters.name) return true;
    const str = filters.name.toLowerCase();
    return (r.name.toLowerCase().includes(str) || r.for?.toLowerCase().includes(str)
      || r.title?.toLowerCase().includes(str) || r.template?.toLowerCase().includes(str) || r.fileName?.toLowerCase().includes(str) || r.files?.toLowerCase().includes(str)
    );
  });
  if (sortColumns.length === 0) return filteredRows;
  const sortedRows = [...filteredRows];
  sortedRows.sort((a, b) => {
    for (const sort of sortColumns) {
      const comp = comparator(sort.columnKey);
      const compResult = comp(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
}


// https://github.com/adazzle/react-data-grid/blob/main/src/hooks/useFocusRef.ts
const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
  const ref = useRef<T>(null);
  //useLayoutEffect(() => { if (!isSelected) return; ref.current?.focus({ preventScroll: true }); }, [isSelected]);
  return { ref, tabIndex: isSelected ? 0 : -1 };
}
