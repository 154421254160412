import { createContext, useContext, useState, useMemo, useRef, useEffect, useLayoutEffect as useOriginalLayoutEffect } from 'react';
import { SelectColumn, SelectCellFormatter } from 'react-data-grid';
import type { Column, SortColumn, HeaderRendererProps, FormatterProps, SummaryFormatterProps, DataGridHandle } from 'react-data-grid';
import * as Format from './formatUtils';
import { CellExpanderFormatter } from './CellExpander';

interface Entity {
  id?: string;
  name: string;
  address1: string;
  address2: string;
  postal: string;
  place: string;
  country: string;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  vat_nr: string;
  vat_perc: number;
  note: string;
  tshirt?: string;
  plate?: string;
  subcontractor?: boolean;
  coord?: string;
  expandParent?: string;
  expandRole?: string;
  expanded?: boolean;
}

interface Summary {
  name: string;
  totalCount: number;
  subcontractorCount?: number;
}

export interface Filter {
  name: string;
}

type Comparator = (a: Entity, b: Entity) => number;

export const client: Entity = {
  name: '',
  address1: '',
  address2: '',
  postal: '',
  place: '',
  country: 'België',
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  vat_nr: '',
  vat_perc: 21,
  note: ''
};

export const supplier: Entity = {
  ...client,
  tshirt: '',
  plate: '',
  subcontractor: true,
  coord: ''
}


// Context (to read filter values) prevents columns re-creation when filters change/lose focus
export const filterContext = createContext<Filter | undefined>(undefined);

export function getSummaryRows(rows: any) {
  let summaryRow: Summary = {
    name: 'total_0',
    totalCount: rows.length
  };
  if ('subcontractorCount' in rows) {
    summaryRow.subcontractorCount = rows.filter((r: any) => r.subcontractor).length;
  }
  return [summaryRow];
}

export function getClientColumns(coll: string, filters: any, setFilters: any, gridRef: any, LineGrid: any): readonly Column<Entity, Summary>[] {
  return [
    SelectColumn,
    {
      key: 'expanded',
      name: '',
      minWidth: 30,
      width: 30,
      colSpan(args) {
        if (args.type === 'ROW' && args.row.expandRole === 'DETAIL') {
          return coll === 'clients' ? 14 : 18;
        }
        return undefined;
      },
      cellClass(row) {
        return row.expandRole === 'DETAIL' ? 'cell-expand-back' : undefined;
      },
      formatter({ row, isCellSelected, onRowChange }) {
        if (row.expandRole === 'DETAIL') {
          return (
            <LineGrid isCellSelected={true} parentId={row.id} />
          );
        } else {
          return (
            <CellExpanderFormatter
              expanded={row.expanded}
              isCellSelected={isCellSelected}
              onCellExpand={() => {
                onRowChange({ ...row, expanded: !row.expanded });
              }}
            />
          );
        }
      }
    },
    {
      key: 'id',
      name: 'ID',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: '',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      cellClass: 'highlight-name fade',
      summaryFormatter({ row }) { return <>{row.totalCount} in total</>; },
      headerCellClass: 'filter-cell',
      headerRenderer: (props: HeaderRendererProps<Entity, Summary>) => (
        <EntityFilterRenderer<Entity, Summary, HTMLInputElement> {...props}>
          {({ filters, ...rest }) => (
            <input {...rest}
              className="filters"
              value={filters.name}
              onChange={(e) => setFilters({ name: e.target.value })}
              onKeyDown={Format.inputStopPropagation}
            />
          )}
        </EntityFilterRenderer>
      )
    },
    {
      key: 'address1',
      name: 'Adres',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      cellClass: 'sel-wider wide-2 fade'
    },
    {
      key: 'address2',
      name: 'Adres+',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 40,
      cellClass: 'sel-wider wide-4 fade'
    },
    {
      key: 'postal',
      name: 'Post',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 70,
      minWidth: 70,
      cellClass: 'text-center'
    },
    {
      key: 'place',
      name: 'Plaats',
      editor: Format.TextEditor,
      width: 120,
      cellClass: 'sel-wider wide-2 fade'
    },
    {
      key: 'country',
      name: 'Land',
      editor: (props: any) => Format.SelectEditor(props, [
        'België',
        'Duitsland',
        'Frankrijk',
        'Luxemburg',
        'Nederland',
        'Slovakije',
        'UK',
        'US'
      ]),
      width: 70,
      minWidth: 70,
      cellClass: 'text-center sel-wider wide-2 fade'
    },
    {
      key: 'contact_name',
      name: 'Contact',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      cellClass: 'highlight-status'
    },
    {
      key: 'contact_email',
      name: 'Email',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 70,
      minWidth: 70,
      cellClass: 'sel-wider wide-6 fade'
    },
    {
      key: 'contact_phone',
      name: 'Tel',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 100,
      cellClass: 'sel-wider wide-2 fade'
    },
    {
      key: 'vat_nr',
      name: 'BTW',
      editor: (props: any) => Format.TextEditor(props, (v: string) => v.replace(/[ .\-]/g, '')),
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 40,
      cellClass: 'sel-wider wide-2 fade'
     },
    {
      key: 'vat_perc',
      name: '%',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(event) { return Boolean(event.stopPropagation()) }},
      width: 60,
      minWidth: 60,
      formatter(props) { return <Format.PercentFormatter value={props.row.vat_perc} />; },
      cellClass: 'text-center'
    },
    {
      key: 'note',
      name: 'Nota',
      editor: (props: any) => Format.TextFieldEditor(props, gridRef),
      width: 40,
      cellClass: 'sel-wider wide-8 fade'
    }
  ];
};

export function getSupplierColumns(coll: string, filters: any, setFilters: any, gridRef: any, LineGrid: any): readonly Column<Entity, Summary>[] {
  return ([
    ...getClientColumns(coll, filters, setFilters, gridRef, LineGrid),
    {
      key: 'tshirt',
      name: 'Maat',
      width: 70,
      minWidth: 70,
      editor: Format.TextEditor
    },
    {
      key: 'plate',
      name: 'Nummerplaat',
      width: 70,
      minWidth: 70,
      editor: Format.TextEditor,
      cellClass: 'sel-wider wide-2 fade'
    },
    {
      key: 'coord',
      name: 'Geo',
      editor: (props: any) => Format.CoordEditor(props, gridRef),
      minWidth: 45,
      width: 45,
      cellClass: 'sel-wider wide-3 fade'
    },
    {
      key: 'subcontractor',
      name: 'Inzetbr',
      width: 70,
      minWidth: 70,
      formatter(props: FormatterProps<Entity, Summary>) { //{ row, onRowChange, isCellSelected }
        return (
          <SelectCellFormatter
            value={props.row.subcontractor === true ? true : false}
            onChange={() => {
              props.onRowChange({ ...props.row, subcontractor: !props.row.subcontractor });
            }}
            onClick={e => e.stopPropagation()}
            isCellSelected={props.isCellSelected}
          />
        );
      },
      summaryFormatter(props: SummaryFormatterProps<Summary, Entity>) {
        return <>{`${props.row.subcontractorCount} (${Math.floor((100 * (props.row.subcontractorCount || 0)) / props!.row.totalCount)}%) ✔️`}</>;

      }
    }
  ]);
}

export function getFilteredSortedRows(rows: any, sortColumns: any, filters: any): readonly Entity[] {

  const isMatch = (r: any) => {
    const str = filters.name.toLowerCase();
    return (r.name?.toLowerCase().includes(str)
      || r.address1?.toLowerCase().includes(str) || r.address2?.toLowerCase().includes(str)
      || r.postal?.toLowerCase().includes(str) || r.place?.toLowerCase().includes(str)
      || r.contact_name?.toLowerCase().includes(str) || r.note?.toLowerCase().includes(str)
    );
  }

  // first filter, then sort
  const filteredRows = rows.filter((r: any, index: number) => {
    if (!filters.name) {
      return true;
    }
    if (r.expandRole === 'DETAIL') {
      return isMatch(rows[index - 1]);
    }
    return isMatch(r);
  });

  if (sortColumns.length === 0) {
    return filteredRows;
  }

  const sortedRows = [...filteredRows];

  sortedRows.sort((a, b) => {
    for (const sort of sortColumns) {
      const comp = comparator(sort.columnKey);
      const compResult = comp(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });

  return sortedRows;
}


function comparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case 'id':
    case 'name':
    case 'address1':
    case 'address2':
    case 'postal':
    case 'place':
    case 'country':
    case 'contact_name':
    case 'contact_phone':
    case 'contact_email':
    case 'vat_nr':
    case 'note':
    case 'tshirt':
    case 'plate':
    case 'coord':
    case 'contact_supplier':
      return (a: any, b: any) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    case 'vat_perc':
      return (a: any, b: any) => {
        return a[sortColumn] - b[sortColumn];
      };
    case 'subcontractor':
      return (a: any, b: any) => {
        return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

function EntityFilterRenderer<R, SR, T extends HTMLOrSVGElement>(
  { isCellSelected, column, children }: HeaderRendererProps<R, SR> &
  { children: (args: { ref: React.RefObject<T>; tabIndex: number; filters: Filter; }) => React.ReactElement; }
) {
  const filters = useContext(filterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  return (<><div>{column.name}</div><div>{children({ ref, tabIndex, filters })}</div></>);
}


// https://github.com/adazzle/react-data-grid/blob/main/src/hooks/useFocusRef.ts
const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
  const ref = useRef<T>(null);
  useLayoutEffect(() => { if (!isSelected) return; ref.current?.focus({ preventScroll: true }); }, [isSelected]);
  return { ref, tabIndex: isSelected ? 0 : -1 };
}
