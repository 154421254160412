import { cloneElement } from 'react';
import type { ReactElement } from 'react';
import type { DataGridProps } from 'react-data-grid';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
//import TextField from '@mui/material/TextField';
//import Popper from '@mui/material/Popper';
import { parseCode, getDirectionImages } from './formatUtils';
const browserDownloadFolder = 'E:\\Downloads';
const fontFamily = 'helvetica';

interface HookData {
  table: any;
  pageNumber: number;
  pageCount: number;
  settings: any;
  doc: any;
  cursor: any;
}



export async function toCsv<R, SR>(
  parent: any,
  fileName: string
) {

  const content = '';
  downloadFile(fileName, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
}

export async function toXlsx<R, SR>(
  parent: any,
  fileName: string
) {
  const [{ utils, writeFile }] = await Promise.all([
    import('xlsx')
  ]);
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([]);
  utils.book_append_sheet(wb, ws, 'Sheet 1');
  writeFile(wb, fileName);
}


export async function toSuppliers(coll: string, parent: any, recruits: any, tpl: any, templates: ReadonlyMap<string, any>) {

  parent.mission = parseCode(parent);
  // Save msgFile asap (is async, unable to detect completion of disk write)
  const msgFile = await saveToPC(fillTemplate(tpl.template, parent, templates));

  const dest = `${parent.loc_client.address1}, ${parent.loc_client.postal} ${parent.loc_client.place}, ${parent.loc_client.country}`;
  let csv = ['"Name","FirstName","Email","Address","Company","Amount","Distance","Duration","DirectionLink","DirectionSrc"'];
  let who = [];
  let imgs = await getDirectionImages(recruits.map((sub: any) => sub.address), dest);

  for (let sub of recruits) {
    const totalDist = Math.ceil(sub.distance.value / 1000) * 2;
    const firstLast = (sub.contact_name || sub.name).split(' ');
    const paidExVat = Math.round(parent.mission.margin.base + (parent.mission.rate.travel * totalDist));
    const dirSrc = imgs.shift();
    const dirLink = 'https://www.google.be/maps/dir/' + encodeURIComponent(sub.address.replace(/ /g, '+')) + '/' + encodeURIComponent(dest.replace(/ /g, '+')) + '/';
    who.push(`${firstLast[0]}${firstLast.slice(-1)[0][0]} (${totalDist} km)`);
    csv.push(`"${firstLast.join(' ')}","${firstLast[0]}","${sub.contact_email}","${sub.address}","${sub.name}","${paidExVat}","${sub.distance.text}","${sub.duration.text}","${dirLink}","${dirSrc}"`);
  }

  console.log(`Geronseld: ${"\n" + who.join("\n")}`); //copyToClipboard
  const to = '{{Name}} <{{Email}}>';
  const subject = `${parent.loc_client.place} ${parent.mission.time.date} ${parent.mission.time.start}-${parent.mission.time.stop} (${parent.name})`;

  window.location.href =
    `thunder://to="${to}",subject='${subject}',message='${msgFile}'`;
    // ,attachment='${browserDownloadFolder}\\${pdfName}'`;

  saveToPC(csv.join("\n"), 'text/csv'); 
  return who;
}

export async function toMail(coll: string, parent: any, tpl: any, templates: ReadonlyMap<string, any>, ev: any) {
  const clientProp = coll === 'orders' ? 'contact_client' : (coll === 'contracts' ? 'contact_supplier' : 'client_origin');
  const docType = coll === 'orders' ? 'Offerte' :
    (coll === 'contracts' ? 'Contract' : 'Factuur');
  //const tpl = Array.from(templates).filter(([k, tpl]) => tpl.name === tplName)[0][1];
  const msgFile = await saveToPC(fillTemplate(tpl.template, parent, templates));
  const pdfName = await toPdf(coll, parent, tpl, templates);
  const toAddress = `${parent[clientProp].contact_name || parent[clientProp].name} <${parent[clientProp].contact_email}>`;

  const subject = coll === 'contracts' ?
    `${parent.mission.time.date} ${parent.loc_client.place} ${docType} ${parent.name}` :
    `Massage at Work ${docType} ${parent.name}`;

  window.location.href = `thunder://to="${toAddress}",` +
    `subject='${subject}',message='${msgFile}',` +
    `attachment='${browserDownloadFolder}\\${pdfName}'`;
}


// <R, SR>
export async function toPdf(coll: string, parent: any, tpl: any, templates: ReadonlyMap<string, any>) {

  const fileName = fillTemplate(tpl.filename, parent, templates)
    .replace(/[/ ]/g, '-') + '-' + generateId(4) + '.pdf';

  await (await buildPdfDoc(coll, parent)).save(fileName, { returnPromise: true });
  await sleep(750);
  return fileName;
}



function saveToPC(data: string, docType: string = 'text/html') {
  return new Promise(resolve => {
    const blob = new Blob([data], {type: docType + ';charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `blitzee-${generateId(15)}.${docType.split('/')[1]}`;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        link.removeEventListener('click', clickHandler);
        resolve(browserDownloadFolder + '\\' + link.download);
      }, 500);
    };
    link.href = url;
    link.addEventListener('click', clickHandler, false);
    link.click();
    //return ;
  });
}


function fillTemplate(tpl: string, parent: any, templates: ReadonlyMap<string, any>) {

  let getProp = (varName: string) => {
    let names = varName.split('.');
    let prop;
    switch (names.length) {
      case 1: prop = parent[names[0]]; break;
      case 2: prop = parent[names[0]][names[1]]; break;
      case 3: prop = parent[names[0]][names[1]][names[2]]; break;
      case 4: prop = parent[names[0]][names[1]][names[2]][names[3]]; break;
    }
    return prop;
  };

  // match brackets e.g. {<p> $var~modifier</p> |condition?override:fallback}
  return tpl.replace(/\{\{/g, '§[').replace(/\}\}/g, ']§').replace(/\{([^}]+)\}/g, (match, inside) => {
    let gotMatch = false;
    let fallback = '';
    let override: any = false;
    let condition: any = false;
    // condition may contain varName, otherwise first varName is taken as condProp
    let condProp: any = '';
    let firstVarName = true;
    // match and remove bracket fallback (for zero varName matches within bracket)
    inside = inside.replace(/\|([^|]+)$/, (fbMatch: any, fb: string) => {
      // gotMatch = true;
      if (fb.includes('?')) {
        [condition, fb] = fb.split('?');
      }
      if (fb.includes(':')) {
        [override, fb] = fb.split(':');
      }
      fallback = fb;
      return '';
    });
    // match varNames within bracket
    inside = inside.replace(/\$([a-zA-Z0-9~:_\-.]+)/g, (varMatch: any, varName: string) => {
      // detect modifier and remove from varName
      let mods = {
        toLowerCaseFirst: false,
        toUpperCaseFirst: false,
        toDashedDigits: false,
        plural: false,
        toHtml: false,
        firstWord: false
      };
      varName = varName.replace(/~([^~]+)$/, (modMatch: any, mod: string) => {
        let [name, arg] = mod.split('('); // ~plural(breaks)
        if (name in mods) {
          mods = Object.assign(mods, {
            [name]: arg ? arg.slice(0, -1) : true
          });
        }
        return '';
      });
      // handle subtemplate
      if (varName.substr(0, 9) === 'template_') {
        let subTpl = Array.from(templates).filter(([key, tpl]) => {
          return (
            tpl.for === 'template' &&
            tpl.name.toLowerCase().replace(/ /g, '_') === varName.substr(9)
          );
        })[0][1].template;
        if (subTpl) {
          gotMatch = true;
          return fillTemplate(subTpl, parent, templates);
        }
        return '';
      }

      // return modified property
      let prop = getProp(varName);

      //let prop = parent[varName];
      if (!prop && 'contact_client' in parent) {
        prop = parent.contact_client[varName];
      }
      if (!prop && 'contact_supplier' in parent) {
        prop = parent.contact_supplier[varName];
      }

      if (typeof prop !== 'undefined') {

        // Consider condition and gotMatch only for first var inside {}
        if (firstVarName) {
          firstVarName = false;
          if (condition === false) {
            gotMatch = fallback ? Boolean(prop) : true;
          } else {
            let operator = '=';
            [condProp, operator, condition] = condition.split(/([<>=])/);
            condProp = condProp ? getProp(condProp) : prop;
            switch (operator) {
              case '<': gotMatch = (parseFloat(condProp) < parseFloat(condition)); break;
              case '>': gotMatch = (parseFloat(condProp) > parseFloat(condition)); break;
              case '=': gotMatch = (condProp == condition); break;
              default: gotMatch = (condProp == condition); break;
            }
          }
        }

        // handle modifiers
        if (mods.toLowerCaseFirst) {
          prop = prop[0].toLowerCase() + prop.slice(1);
        }
        if (mods.toUpperCaseFirst) {
          prop = prop[0].toUpperCase() + prop.slice(1);
        }
        if (mods.toDashedDigits) {
          prop = prop.match(/(\d+)/g).join('-');
        }
        if (mods.toHtml) {
          prop = prop.replaceAll("\n", '<br>');
        }
        if (mods.firstWord) {
          prop = prop.split(' ')[0];
        }
        /*
        if (mods.plural) {
          prop = parseFloat(prop) > 1 ? mods.plural : prop;
        }
        */

        // varName base modifications
        if (varName.includes('amount_')) {
          prop = amount(parseFloat(prop));
        } else if (varName.includes('_date')) {
          prop = date(prop);
        }

        // console.log(prop, condition, gotMatch, override, fallback);

        if (gotMatch) {
          return override === false ? prop : override;
        }
        return '';
      }
      return '';
    });
    if (!gotMatch) { // whether (provided|first) varName satisfies the condition
      return fallback;
    }
    return inside;
  }).replace(/§\[/g, '{{').replace(/\]§/g, '}}');
}

async function buildPdfDoc(coll: string, parent: any) {
  // const { head, body, foot } = { head: [], body: parent.lines, foot: [] };
  let offset = 0;

  const doc = new jsPDF({ // A4: 21 x 29.7 cm
    orientation: 'p',
    unit: 'cm',
    putOnlyUsedFonts: true,
    compress: false
  });
  doc.setFontSize(10);

  let headerParams = {
    type: coll === 'invoices' ? 'Factuur' :
      (coll === 'creditnotes' ? 'Creditnota' :
      (coll === 'contracts' ? 'Inkooporder' :
      (['draft', 'sent'].includes(parent.status) ? 'Offerte' : 'Verkooporder'))),
    ref: parent.name
  };

  let footerParams = { date: date(parent.order_date) };

  offset += await pdfDrawHeader(doc, offset, headerParams);

  const re = coll === 'contracts' ?
    parent.contact_supplier : parent.contact_client;

  offset += pdfDrawRecipient(doc, offset, {
    title: ['invoices', 'creditnotes'].includes(coll) ? parent.client_name : re.name,
    address: ['invoices', 'creditnotes'].includes(coll) ?
      parent.client_address +
      (parent.client_vat_nr ? "\n\nOnd.nr: " + parent.client_vat_nr : '')
      :
      re.address1 +
      (re.address2 ? ` (${re.address2})` : '') + "\n" +
      re.postal + ' ' + re.place + "\n" +
      re.country + "\n" +
      (re.vat_nr ? "\nOnd.nr: " + re.vat_nr : '') +
      "\nContact: " +
      (re.contact_name ? re.contact_name : re.contact_email) +
      (re.contact_phone ? " (" + re.contact_phone + ")" : '')
  });

  const loc = parent.loc_client;
  switch (coll) {
    case 'contracts':
      offset += pdfDrawEntity(doc, offset, {
        title: 'Adres opdracht:',
        address: loc.name + "\n" + loc.address1 +
          (loc.address2 ? ` (${loc.address2})` : '') + "\n" +
          loc.postal + ' ' + loc.place + "\n" +
          loc.country + "\n\nContact:" +
          (!loc.contact_name && !loc.contact_phone ? '(bel ons bij problemen)' : '') +
          (loc.contact_name ? ` ${loc.contact_name} ` : '') +
          (loc.contact_phone ? '(' + loc.contact_phone + ')' : '')
      });
    break;
    case 'orders':
      const inv = parent.invoice_client;
      offset += pdfDrawEntity(doc, offset, {
        title: 'Facturatieadres:',
        address: inv.name + "\n" + inv.address1 +
          (inv.address2 ? ` (${inv.address2})` : '') + "\n" +
          inv.postal + ' ' + inv.place + "\n" +
          inv.country +
          (inv.vat_nr ? "\nOnd.nr: " + inv.vat_nr : '')
      }); // falling through: no break here
    case 'invoices':
    case 'creditnotes':
      offset += pdfDrawEntity(doc, offset, {
        title: 'Adres opdracht:',
        address: ['invoices', 'creditnotes'].includes(coll) ? parent.loc_address :
          loc.name + "\n" + loc.address1 +
          (loc.address2 ? ` (${loc.address2})` : '') + "\n" +
          loc.postal + ' ' + loc.place + "\n" +
          loc.country
      });
  }

  const type = coll === 'contracts' ? 'supplier' : 'client';

  let infoBar = {
    header: [
      'Opgesteld',
      'Aanvang opdracht',
      ['invoices', 'creditnotes'].includes(coll) ? 'Betalingstermijn': 'Geldigheid'
    ],
    rows: [[date(parent.order_date), date(parent.loc_date), '30 dagen']]
  };
  if (parent[`${type}_ref`]) {
    infoBar.header.push('Uw ref.');
    infoBar.rows[0].push(parent[`${type}_ref`]);
  }
  offset += pdfDrawMeta(doc, offset, infoBar);

  const vat_perc = ['invoices', 'creditnotes'].includes(coll) ?
    parent.client_vat_perc : parent[`contact_${type}`].vat_perc;
  const showDiscount = type === 'client' && parent.lines.some((r: any) => r.discount);

  offset += pdfDrawBill(doc, offset, {
    header: showDiscount ?
      ['Omschrijving', 'Volume', 'Prijs', 'Korting', 'Bedrag'] :
      ['Omschrijving', 'Volume', 'Prijs', 'Bedrag'],
    rows: parent.lines.map((r: any) => {
      let row = [
        r.type + (r.description ? "\n" + r.description : ''),
        `${amount(r.quantity)} ${r.unit}`,
        amount(r[`price_${type}`])
      ];
      if (showDiscount) {
        row.push(
          r.discount ? `${r.discount}%` : '',
          '€ ' + amount(r.quantity * r.price_client * (1 - r.discount / 100))
        );
      } else {
        row.push('€ ' + amount(r.quantity * r[`price_${type}`]));
      }
      return row;
    }),
    subtotal: {
      label: 'Subtotaal',
      amount: parent.amount_untaxed
    },
    vat: {
      label: `BTW (${vat_perc}%)`,
      amount: parent.amount_tax
    },
    total: {
      label: coll === 'invoices' ? 'Te betalen' : 'Totaal',
      amount: parent.amount_total
    }
  });

  offset += await pdfDrawText(doc, offset, coll, { ...headerParams, ...footerParams, text: parent.text });

  await pdfDrawFooter(doc, coll, footerParams);

  return doc;
}

async function pdfDrawFooter(doc: any, coll: string, report: any) {
  let offset = 24.5;
  offset += coll === 'contracts' ?
    await pdfDrawConditions(doc, 23.5, coll, {
      text: `De uitvoerder mag verplaatsingsvergoeding factureren (0,42 €/km), ook als dit contract geen raming vermeldt. De uitvoerder neemt de verantwoordelijkheid om vroeg genoeg aan te komen om tijdig te kunnen beginnen met de massages. De afgesproken werktijden worden steeds gerespecteerd, tenzij in expliciet akkoord met zowel de klant, de opdrachtgever als de uitvoerder. De uitvoerder treedt op in naam van Massage at Work, wisselt geen eigen contactgegevens uit en verwijst (potentiële) klanten steeds door naar Massage at Work. Overeengekomen en opgemaakt te Leuven, op ${report.date}, in tweevoud. Elke partij erkent één exemplaar te hebben ontvangen.`
    }) :

    await pdfDrawConditions(doc, 24.5, coll, {
      text: (['invoices', 'creditnotes'].includes(coll) ? '' : 'Je kan via email reserveren door aan te geven dat je akkoord gaat met de offerte. ') +
      "Verplaatsingskosten worden berekend aan het wettelijke minimumtarief. Parkeerkosten worden aangerekend indien we ze niet konden vermijden. We vragen ev. een voorschot van 33%. Annulaties proberen we steeds in der minne te regelen met de masseur(s), maar we behouden het recht op een vergoeding van 33% op het offertebedrag a rato van de geannuleerde uren. Op minder dan 5 dagen wordt dit 66%, onder 48 uren, 80% en de dag zelf 100%. De betalingstermijn (30 dagen) van onze facturen is geldig ongeacht de algemene of bijzondere voorwaarden van de klant. Betaling via overschrijving. Na de vervaldag is er van rechtswege en zonder ingebrekestelling een conventionele moratoire intrest verschuldigd (art. 5, Wet ter Bestrijding van de Betalingsachterstand). Beehive BV (Massage at Work) heeft dan recht op 12% forfaitaire schadevergoeding op het factuurbedrag (min. €50). Alle inningskosten worden aangerekend: minnelijke, gerechtelijke, deurwaarders- en advocatenkosten. Bij geschillen is enkel de rechtbank van Leuven bevoegd. Bezwaren dienen binnen 8 dagen na verzending te worden overgemaakt."
    });

  await pdfDrawCompanyInfo(doc, offset, {
    text: "Beehive BV | +32 496 02 29 03 | Email: info@massageatwork.be | Web: massageatwork.be | Ondernemingsnr: BE 0896 002 658\nRekening: Belfius IBAN BE29 0682 4921 6164 | BIC: GKCCBEBB | RPR: Leuven | Maatsch. zetel: Gemeentestr. 51, 3010 Leuven"
  });
}

// These functions return the height they occupy
async function pdfDrawHeader(doc: any, offset: number, report: any) {
  const fontSize = doc.getFontSize();
  const image = new Image();
  image.src = "assets/maw-logo-585x488.jpg";
  await image.decode();

    doc
      .addImage(image, "JPG", 1.25, 0.75, 5, 5 * 488 / 585)
      .setFontSize(28)
      .setTextColor(20, 10, 160)
      .text(report.type, 19.5, offset + 2.53, { align: 'right', maxWidth: 8 })
      .setFontSize(16)
      .setTextColor(0, 0, 0)
      .setFont(fontFamily, 'bold')
      .text(report.ref, 19.5, offset + 3.35, { align: 'right', maxWidth: 8 })
      .setFont(fontFamily, 'normal')
      .setFontSize(9)
      .text("Massage at Work\nGemeentestraat 51\n3010 Kessel-Lo\n" +
      "België\n+32 496 02 29 03\ninfo@massageatwork.be", 8, 1.5)
      .setDrawColor(20, 10, 160)
      .setLineWidth(0.04)
      .line(7, offset + 4, 19.75, offset + 4)
      .setFontSize(fontSize);

  return 5;
}

// Prints recipient at right-hand side (envelope window)
function pdfDrawRecipient(doc: any, offset: number, report: any) {
  doc
    .setFont(fontFamily, 'bold')
    .text(report.title, 10.5, offset)
    .setFont(fontFamily, 'normal')
    .text(report.address, 10.5, offset + 0.5);
  return 1; // continuing left-hand flow 1 lower than recipient started
}

// Prints entity at left-hand side
function pdfDrawEntity(doc: any, offset: number, report: any) {
  if (!report.address) return 3;
  const nrlines = report.address.match(/\n/g).length + 1;
  doc
    .setFont(fontFamily, 'bold')
    .text(report.title, 1.25, offset)
    .setFont(fontFamily, 'normal')
    .text(report.address, 1.25, offset + 0.5);
  return 0.5 + (0.42 * nrlines) + 0.35;
}

async function pdfDrawText(doc: any, offset: number, coll: string, report: any) {
  let splitText = doc.splitTextToSize(report.text, 18.25);

  for (const line of splitText) {
    if (offset > 23.5) {
      await pdfDrawFooter(doc, coll, report);
      doc
        .setTextColor(106, 106, 106)
        .text('Pagina 1/2', 19.5, 1.5, { align: 'right', maxWidth: 3 })
        .addPage()
        .text('Pagina 2/2', 19.5, 1.5, { align: 'right', maxWidth: 3 })
        .setTextColor(0, 0, 0);
      offset = 1 + await pdfDrawHeader(doc, 0, report);

    }
    doc.setFontSize(10);
    doc.text(line, 1.25, offset);
    offset += 0.45;
  }
  //doc.text(report.text, 1.25, offset, { maxWidth: 18.25 });
  return offset;
}

async function pdfDrawConditions(doc: any, offset: number, coll: string, report: any) {
  //console.log(offset);
  doc
    .setFontSize(8.5)
    .setTextColor(106, 106, 106)
    .text(report.text, 1.25, offset, { align: 'justify', maxWidth: 18.33 });

  if (coll === 'contracts') {
    const image = new Image();
    image.src = "assets/handtekening_goedele_transp.png";
    await image.decode();

    doc
      .setFontSize(10)
      .setTextColor(0, 0, 0)
      .addImage(image, "PNG", 12, offset + 2.2, 2.8, 2.8 * 241 / 298)
      .text('De opdrachtgever,', 12, offset + 2)
      .text('De uitvoerder,', 16, offset + 2);
  }

  return 3; // conditions get all remaining space until footer
}

async function pdfDrawCompanyInfo(doc: any, offset: number, report: any) {
  doc
    .setDrawColor(0, 0, 0)
    .setLineWidth(0.03)
    .line(1.25, offset, 19.75, offset)
    .setFontSize(9)
    .setTextColor(0, 0, 0)
    .text(report.text, 1.25, offset + 0.5, { align: 'justify', maxWidth: 18.25 });
  return 1;
}

// Prints entity at left-hand side
function pdfDrawMeta(doc: any, offset: number, report: any) {
  let yPos = offset;
  autoTable(doc, {
    theme: 'plain',
    startY: offset - 0.25,
    margin: { left: 1.25, right: 1.25 },
    tableLineWidth: 0.02,
    tableLineColor: [233, 86, 0],
    head: [report.header],
    body: report.rows,
    horizontalPageBreak: true, // orange 233,86,0 green 0,136,68
    styles: { cellPadding: 0.2, fontSize: 10, halign: 'center', cellWidth: 'wrap' },
    headStyles: { fillColor: [255, 240, 232], fontStyle: 'bold' },
    tableWidth: 'auto',
    didDrawPage: function(data: HookData) {
      yPos = data?.cursor.y;
    }
  });
  return yPos - offset + 0.5; // return height of table only
}

// Prints entity at left-hand side
function pdfDrawBill(doc: any, offset: number, report: any) {
  let yPos = offset;
  autoTable(doc, {
    theme: 'plain',
    startY: offset,
    margin: { left: 1.25, right: 1.25 },
    tableLineWidth: 0,
    head: [report.header],
    body: report.rows,
    horizontalPageBreak: false, // orange 233,86,0 green 0,136,68
    styles: {
      fillColor: [255, 255, 255],
      lineColor: [224, 224, 224],
      lineWidth: { top: 0, right: 0, bottom: 0.04, left: 0 },
      cellPadding: 0.2,
      fontSize: 10,
      halign: 'right',
      cellWidth: 'wrap'
    },
    columnStyles: { 0: { halign: 'left', cellWidth: 'auto', overflow: 'linebreak' } },
    headStyles: {
      halign: 'center',
      lineColor: [20, 10, 160], // green 0, 157, 79 lightgreen 210, 255, 233
      fontStyle: 'bold'
    },
    tableWidth: 'auto',
    didDrawPage: function(data: HookData) {
      yPos = data?.cursor.y;
    }
  });

  autoTable(doc, {
    theme: 'plain',
    startY: yPos - 0.04,
    tableWidth: 6,
    margin: { left: 13.75, right: 1.25 },
    body: [
      [report.subtotal.label, '€ ' + amount(report.subtotal.amount)],
      [report.vat.label, '€ ' + amount(report.vat.amount)]
    ],
    foot: [
      [report.total.label, '€ ' + amount(report.total.amount)]
    ],
    horizontalPageBreak: true, // orange 233,86,0 green 0,136,68
    styles: {
      fillColor: [255, 255, 255],
      lineColor: [20, 10, 160],
      lineWidth: { top: 0.04, right: 0, bottom: 0, left: 0 },
      cellPadding: 0.15,
      fontSize: 10,
      halign: 'right',
      cellWidth: 'wrap'
    },
    columnStyles: { 0: { halign: 'left' } },
    footStyles: {
      textColor: [20, 10, 160],
      fontStyle: 'bold',
      fontSize: 12
    }, // lightgreen 210, 255, 233
    didDrawPage: function(data: HookData) {
      yPos = data?.cursor.y;
    }
  });
  return yPos - offset + 0.75;
}



function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}


const numFormat = new Intl.NumberFormat('nl', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
function amount(value: number) {
  return numFormat.format(value);
}

function date(value: string) {
  if (value.includes(':')) {
    return new Date(value)
      .toLocaleString(navigator.language, { dateStyle: 'short', timeStyle: 'short' })
      .replace(',', ' om');
  }
  return new Date(value).toLocaleDateString();
}

// dec2hex :: Integer -> String jitresjoietrjioujiopjo
// i.e. 0-255 -> '00'-'ff'
function dec2hex (dec: number) {
  return dec.toString(16).padStart(2, "0")
}

// generateId :: Integer -> String
export function generateId (len: number) {
  let arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

function copyToClipboard(txt: string) {
    // create hidden text element, if it doesn't already exist
    let targetId = "_hiddenCopyText_";
    let origSelectionStart, origSelectionEnd;

    // must use a temporary form element for the selection and copy
    let target: any = document.getElementById(targetId);
    if (!target) {
      let target = document.createElement("textarea");
      target.style.position = "absolute";
      target.style.left = "-9999px";
      target.style.top = "0";
      target.id = targetId;
      document.body.appendChild(target);
    }
    target.textContent = txt;

    // select the content
    let currentFocus: any = document.activeElement;
    target.focus();
    target.setSelectionRange(0, target.value.length);

    // copy the selection
    let succeed;
    try {
      succeed = document.execCommand("copy");
    } catch(e) {
      succeed = false;
    }
    // restore original focus
    if (currentFocus && 'focus' in currentFocus) {
      currentFocus.focus();
    }

    // clear temporary content
    target.textContent = "";

    return succeed;
}

async function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}