import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import * as icon from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Orders',
    url: '/page/orders',
    iosIcon: icon.documentTextOutline,
    mdIcon: icon.documentTextSharp
  },
  {
    title: 'Contracts',
    url: '/page/contracts',
    iosIcon: icon.documentLockOutline,
    mdIcon: icon.documentLockSharp
  },
  {
    title: 'Invoices',
    url: '/page/invoices',
    iosIcon: icon.cashOutline,
    mdIcon: icon.cashSharp
  },
  {
    title: 'Credit notes',
    url: '/page/creditnotes',
    iosIcon: icon.bandageOutline,
    mdIcon: icon.bandageSharp
  },
  {
    title: 'Clients',
    url: '/page/clients',
    iosIcon: icon.peopleOutline,
    mdIcon: icon.peopleSharp
  },
  {
    title: 'Suppliers',
    url: '/page/suppliers',
    iosIcon: icon.schoolOutline,
    mdIcon: icon.schoolSharp
  },
  {
    title: 'Products',
    url: '/page/products',
    iosIcon: icon.cartOutline,
    mdIcon: icon.cartSharp
  },
  {
    title: 'Templates',
    url: '/page/templates',
    iosIcon: icon.colorPaletteOutline,
    mdIcon: icon.colorPaletteSharp
  }
];



const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent class="clickaway">
        <IonList id="menu-panel" class="clickaway">
          <IonListHeader>Blitzee</IonListHeader>
          <IonNote>v0.6 <a href="https://kanbanflow.com/board/714cd0f5dd5c7b36946e0ccbe21ba3a0" target="_blank">todo</a></IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
