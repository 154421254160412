import { initializeApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore/lite';
import { getAuth, GoogleAuthProvider, signInWithCredential } from "firebase/auth";

import { gapi, loadAuth2 } from 'gapi-script';

// Load Blitzee firebase config
const fbApp = initializeApp({
  apiKey: "AIzaSyCvs077FgqXXHbhInbeq1xSENdWO4Gp6rU",
  authDomain: "blitzee.firebaseapp.com",
  projectId: "blitzee",
  storageBucket: "blitzee.appspot.com",
  messagingSenderId: "1038044838760",
  appId: "1:1038044838760:web:260f109779cd48b6b374dd"
});

export async function checkFirestore(conn: any, setConn: any) {
  if (conn instanceof Firestore) {
    return conn;
  }
  if (!gapi.auth2) {
    console.log('Attempting fresh reconnect...');
    return (await connect(conn))![0];
  }
  const session = gapi.auth2.getAuthInstance();
  if (session.isSignedIn.get()) {
    const fsSession = await loadFirestoreSession(session);
    // Welcoming user
    console.log('Welcome back', fsSession.user.displayName);
    const connection = getFirestore(fbApp);
    setConn(connection);
    return connection;
  } else {
    console.log('Couldn\'t re-establish Firestore connection.');
    return conn;
  }
}

async function loadFirestoreSession(session: any) {
  // Extracting credential for firestore authentication
    const user = session.currentUser.get();
    const authResponse = user.getAuthResponse(true);
    const credential = await GoogleAuthProvider.credential(
      authResponse.id_token,
      authResponse.access_token
    );

    // Loading firestore
    const fsSession = await signInWithCredential(getAuth(), credential);
    return fsSession;
}


export async function connect(conn: any, google: any = {}) {

  if (conn instanceof Firestore) return [conn, google];

  // Blitzee id https://console.cloud.google.com/apis/credentials?project=blitzee
  const clientId = '1038044838760-p9fk9s4t01js04d1g7dlpvqt70nh4jev.apps.googleusercontent.com';
  const scopes = 'https://www.googleapis.com/auth/firebase ' +
    'https://www.googleapis.com/auth/calendar ' +
    'https://www.googleapis.com/auth/spreadsheets ' +
    'https://www.googleapis.com/auth/drive';
  const auth2 = await loadAuth2(gapi, clientId, scopes);
  const session = gapi.auth2.getAuthInstance();

  // Also ensure it's goedele.maw that's logged in
  if (session.isSignedIn.get() !== true || session.currentUser.get().getId() !== '104020839217697387212') {
    console.log('Key user sign-in required');
    // Show prompt
    await session.signIn();
  }

  if (session.isSignedIn.get() === true) {

    await new Promise((resolve: any) => gapi.load('client', () => resolve()));

    const res = await Promise.all([
      loadFirestoreSession(session),
      // Loading calendar
      new Promise((resolve: any) => gapi.client.load('calendar', 'v3', () => resolve())),
      // Loading sheets
      new Promise((resolve: any) => gapi.client.load('sheets', 'v4', () => resolve())),
      // Loading drive (for setting sheet permissions)
      new Promise((resolve: any) => gapi.client.load('drive', 'v3', () => resolve())),
    ]);

    // const fsSession = await loadFirestoreSession(session);

    // Welcoming user
    console.log('Welcome', res[0].user.displayName);

    return [
      getFirestore(fbApp),
      {
        cal: gapi.client.calendar,
        sheets: gapi.client.sheets.spreadsheets,
        drive: gapi.client.drive
      }
    ];
  }
}
