import { createContext, useContext, useState, useMemo, useRef, useEffect, useLayoutEffect as useOriginalLayoutEffect } from 'react';
import { SelectColumn, SelectCellFormatter } from 'react-data-grid';
import type { Column, SortColumn, HeaderRendererProps, FormatterProps, SummaryFormatterProps, DataGridHandle } from 'react-data-grid';
import { CellExpanderFormatter } from './CellExpander';

import * as Format from './formatUtils';
//import { LineGrid } from './Product';

interface Report {
  id?: string;
  name: string;
  code: string; //
  order_date: string;
  loc_client: string; 
  loc_date: string; //
  status: string;
  text: string;
  eventId: string;
  event: string;
  sheet: string;
  amount_tax: number;
  amount_untaxed: number;
  amount_total: number;
  expandParent?: string;
  expandRole?: string;
  expanded?: boolean;
}

interface Order extends Report {
  contact_client: string;
  invoice_client: string;
  client_ref: string;
  amount_margin: number;
}

interface Invoice extends Report {
  order_origin: string;
  client_origin: string;
  client_name: string;
  client_address: string;
  client_vat_nr: string;
  client_vat_perc: number;
  client_ref: string;
  loc_address: string;
}

interface Contract extends Report {
  order_name: string;
  contact_supplier: string;
  supplier_ref: string;
}

interface Line {
  id?: string;
  name: string;
  code: string;
  order_date: string;
  loc_client: string;
  loc_date: string;
  status: string;
  eventId: string;
  event: string;
  sheet: string;
  amount_untaxed: number;
  amount_total: number;
  lines?: any;
}

interface Summary {
  name: string;
  totalCount: number;
  totalTax: number;
  totalUntaxed: number;
  grandTotal: number;
  totalSent: number;
}

interface ContractSummary extends Summary {
  totalPaid: number;
  amountUnpaid: number;
}

interface OrderSummary extends Summary {
  totalMargin: number;
  totalSold: number;
}

interface InvoiceSummary extends Summary {
  totalMargin: number;
  totalPaid: number;
  amountUnpaid: number;
}

export interface Filter {
  name: string;
}

type OrderComparator = (a: Order, b: Order) => number;
type ContractComparator = (a: Contract, b: Contract) => number;
type InvoiceComparator = (a: Invoice, b: Invoice) => number;

export const order: Order = {
  name: 'VO/' + new Date().getFullYear() + '/',
  code: '',
  order_date: '',
  contact_client: '',
  invoice_client: '',
  loc_client: '',
  loc_date: '',
  status: '',
  text: '',
  eventId: '',
  event: '',
  sheet: '',
  client_ref: '',
  amount_tax: 0,
  amount_untaxed: 0,
  amount_total: 0,
  amount_margin: 0
};

export const contract: Contract = {
  name: 'IO/' + new Date().getFullYear() + '/',
  code: '',
  order_name: '',
  order_date: '',
  contact_supplier: '',
  loc_client: '',
  loc_date: '',
  status: '',
  text: '',
  eventId: '',
  event: '',
  sheet: '',
  supplier_ref: '',
  amount_tax: 0,
  amount_untaxed: 0,
  amount_total: 0
};

export const invoice: Invoice = {
  name: 'VF/' + new Date().getFullYear() + '/',
  code: '',
  order_date: '',
  order_origin: '',
  client_origin: '',
  client_name: '',
  client_address: '',
  client_vat_nr: '',
  client_vat_perc: 0,
  client_ref: '',
  loc_client: '',
  loc_address: '',
  loc_date: '',
  status: '',
  text: '',
  eventId: '',
  event: '',
  sheet: '',
  amount_tax: 0,
  amount_untaxed: 0,
  amount_total: 0
};

export const creditnote: Invoice = {
  ...invoice,
  name: 'CN/' + new Date().getFullYear() + '/'
};


// Context (to read filter values) prevents columns re-creation when filters change/lose focus
export const filterContext = createContext<Filter | undefined>(undefined);

export function getOrderSummaryRows(rows: any) {
  const { tax, untaxed, grand, margin, sold, sent } = rows.reduce(
    (a: any, b: any) => {
      const isSold = ['sold', 'recruited', 'filled', 'sealed', 'invoiced'].includes(b.status);
      return {
        tax: a.tax + (isSold ? parseFloat(b.amount_tax) : 0),
        untaxed: a.untaxed + (isSold ? parseFloat(b.amount_untaxed) : 0),
        grand: a.grand + (isSold ? parseFloat(b.amount_total) : 0),
        margin: a.margin + (isSold ? parseFloat(b.amount_margin) : 0),
        sold: a.sold + Number(isSold),
        sent: a.sent + Number(b.status === 'sent' || isSold)
      }
    },
    { tax: 0, untaxed: 0, grand: 0, margin: 0, sold: 0, sent: 0 }
  );
  let summaryRow: OrderSummary = {
    name: 'total_0',
    totalCount: rows.length,
    totalTax: Math.round(tax),
    totalUntaxed: Math.round(untaxed),
    grandTotal: Math.round(grand),
    totalMargin: Math.round(margin),
    totalSold: sold,
    totalSent: sent
  };
  return [summaryRow];
}

// These are client lines, i.e. their list of past orders
export function getLineColumns(clients: any): readonly Column<Line, Summary>[] {
  return [
    {
      key: 'id',
      name: '',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: 'Name',
      width: 120,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header'
    },
    {
      key: 'code',
      name: 'Code',
      width: 140,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header'
    },
    {
      key: 'event',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'eventId',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'sheet',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'loc_client',
      name: 'Locatie',
      cellClass: 'line-cell',
      headerCellClass: 'line-header',
      formatter(props) {
        return <Format.ForeignFormatter value={clients.get(props.row.loc_client)?.name} />;
      }
    },
    {
      key: 'order_date',
      name: 'Opgesteld',
      cellClass: 'text-center line-cell',
      headerCellClass: 'line-header',
      width: 140,
      formatter(props) { return <Format.DateFormatter value={props.row.order_date} />; }
    },
    {
      key: 'loc_date',
      name: 'Start',
      cellClass: 'highlight-status text-center line-cell',
      headerCellClass: 'line-header',
      width: 180,
      formatter(props) { return <Format.DateFormatter value={props.row.loc_date} />; }
    },
    {
      key: 'status',
      name: 'Status',
      width: 110,
      cellClass(row) { return 'line-cell status-' + row.status },
      headerCellClass: 'line-header'
    },
    {
      key: 'amount_untaxed',
      name: 'Subtotaal',
      width: 100,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_untaxed} />; }
    },
    {
      key: 'amount_total',
      name: 'Totaal',
      width: 100,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_total} />; }
    },
    {
      key: 'dummy_discount',
      name: 'Korting',
      width: 100,
      cellClass: 'text-right line-cell',
      headerCellClass: 'line-header',
      formatter({row}) {
        if (row.lines) {
          const firstService = row.lines.find((l: any) => l.unit === 'uren');
          return <>{firstService.discount}%</>;
        }
        return <>-</>;
      }
    }
  ];
}


export function getOrderColumns(filters: any, setFilters: any, gridRef: any, LineGrid: any, clients: any): readonly Column<Order, OrderSummary>[] {
  return [
    SelectColumn,
    {
      key: 'expanded',
      name: '',
      minWidth: 30,
      width: 30,
      colSpan(args) {
        return args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 19 : undefined;
      },
      cellClass(row) {
        return row.expandRole === 'DETAIL' ? 'cell-expand-back' : undefined;
      },
      formatter({ row, isCellSelected, onRowChange }) {
        if (row.expandRole === 'DETAIL') {
          return (
            <LineGrid isCellSelected={true} parentId={row.id} />
          );
        } else {
          return (
            <CellExpanderFormatter
              expanded={row.expanded}
              isCellSelected={isCellSelected}
              onCellExpand={() => {
                onRowChange({ ...row, expanded: !row.expanded });
              }}
            />
          );
        }
      }
    },
    {
      key: 'id',
      name: 'ID',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: '',
      width: 100,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) { return <div title="Sent or better (anything but draft)">{props.row.totalSent} sent</div>; },
      headerCellClass: 'filter-cell',
      headerRenderer: (props: HeaderRendererProps<Order, OrderSummary>) => (
        <OrderFilterRenderer<Order, OrderSummary, HTMLInputElement> {...props}>
          {({ filters, ...rest }) => (
            <input {...rest}
              className="filters"
              value={filters.name}
              onChange={(e) => setFilters({ name: e.target.value })}
              onKeyDown={Format.inputStopPropagation}
            />
          )}
        </OrderFilterRenderer>
      )
    },
    {
      key: 'code',
      name: 'Code',
      width: 110,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      cellClass: 'text-right sel-wider wide-2',
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) {
        return <div title="Sold or better: recruited, filled, sealed, invoiced">{props.row.totalSent ? Math.round(100 * props.row.totalSold / (props.row.totalSent + props.row.totalSold)) : 0}% sold</div>;
      }
    },
    {
      key: 'contact_client',
      name: 'Contact',
      editor: (props: any) => Format.ForeignEditor(props, clients),
      cellClass(row) {
        return row.expandRole === 'DETAIL' ?
          'cell-expand-back' : 'highlight-name sel-wider wide-3 fade';
      },
      formatter(props) {
        return <Format.ForeignFormatter value={clients.get(props.row.contact_client)?.name} />;
      },
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) {
        return <div title="Income (after supplier cost) from net revenue (amount sold without VAT)">{props.row.totalUntaxed ? Math.round(1000 * props.row.totalMargin / props.row.totalUntaxed) / 10 : 0}% net margin</div>;
      }
    },
    {
      key: 'invoice_client',
      name: 'Factuur',
      editor: (props: any) => Format.ForeignEditor(props, clients),
      cellClass: 'sel-wider wide-3 fade',
      formatter(props) { return <Format.ForeignFormatter value={clients.get(props.row.invoice_client)?.name} />; }
    },
    {
      key: 'loc_client',
      name: 'Locatie',
      editor: (props: any) => Format.ForeignEditor(props, clients),
      cellClass: 'sel-wider wide-3 fade',
      formatter(props) {
        return <Format.ForeignFormatter value={clients.get(props.row.loc_client)?.name} />;
      }
    },
    {
      key: 'order_date',
      name: 'Opgesteld',
      editor: Format.DateEditor,
      width: 88,
      cellClass: 'sel-wider wide-2',
      formatter(props) { return <Format.DateFormatter value={props.row.order_date} />; }
    },
    {
      key: 'loc_date',
      name: 'Start',
      editor: Format.DateTimeEditor,
      width: 100,
      cellClass: 'highlight-status sel-wider wide-2 fade',
      formatter(props) { return <Format.DateFormatter value={props.row.loc_date} />; }
    },
    {
      key: 'text',
      name: 'Omschrijving',
      editor: (props: any) => Format.QuoteDescriptionEditor(props, gridRef),
      width: 90,
      cellClass: 'sel-wider wide-9 fade'
    },
    {
      key: 'event',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'eventId',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'sheet',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'client_ref',
      name: 'Ref',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      width: 45,
      minWidth: 45,
      cellClass: 'sel-wider wide-3 fade'
    },
    {
      key: 'status',
      name: 'Status',
      editor: (props: any) => Format.SelectEditor(props, [
        'draft',
        'sent',
        'sold',
        'recruited', //scouted?
        'filled',
        'sealed',
        'invoiced'
      ]),
      width: 80,
      cellClass(row) { return 'status-' + row.status },
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) {
        return <div title="Sold or better: recruited, filled, sealed, invoiced">{props.row.totalSold} sold</div>;
      }
    },
    {
      key: 'amount_tax',
      name: 'BTW',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_tax} />; },
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) {
        return <div title="Total amount of VAT">&euro; {props.row.totalTax.toLocaleString()}</div>;
      }
    },
    {
      key: 'amount_untaxed',
      name: 'Subtotaal',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_untaxed} />; },
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) {
        return <div title="Net revenue (amount sold without VAT)">&euro; {props.row.totalUntaxed.toLocaleString()}</div>;
      }
    },
    {
      key: 'amount_total',
      name: 'Totaal',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_total} />; },
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) {
        return <div title="Gross revenue (amount sold including VAT)">&euro; {props.row.grandTotal.toLocaleString()}</div>;
      }
    },
    {
      key: 'amount_margin',
      name: 'Marge',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_margin} />; },
      summaryFormatter(props: SummaryFormatterProps<OrderSummary, Order>) {
        return <div title="Net margin: income (after supplier cost) from net revenue (amount sold without VAT)">&euro; {props.row.totalMargin.toLocaleString()}</div>;
      }
    }
  ];
}

export function getOrderFilteredSortedRows(rows: any, orderSortColumns: any, filters: any, clients: any): readonly Order[] {
  // first filter, then sort
  const isMatch = (r: any) => {
    const str = filters.name.toLowerCase();
    return r.name?.toLowerCase().includes(str)
      || r.code?.toLowerCase().includes(str) || r.order_date?.toLowerCase().includes(str)
      || clients.get(r.contact_client)?.name.toLowerCase().includes(str)
      || clients.get(r.invoice_client)?.name.toLowerCase().includes(str)
      || clients.get(r.loc_client)?.name.toLowerCase().includes(str)
      || r.loc_date?.toLowerCase().includes(str)
      || r.status?.toLowerCase().includes(str) || r.client_ref?.toLowerCase().includes(str);
  };
  const filteredRows = rows.filter((r: any, index: number) => {
    if (!filters.name) {
      return true;
    }
    if (r.expandRole === 'DETAIL') {
      return isMatch(rows[index - 1]);
    }
    return isMatch(r);
  });
  if (orderSortColumns.length === 0) return filteredRows;
  const sortedRows = [...filteredRows];
  sortedRows.sort((a, b) => {
    for (const sort of orderSortColumns) {
      const comparator = orderComparator(sort.columnKey);
      const compResult = comparator(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
}

export function getInvoiceSummaryRows(rows: any) {
  const { tax, untaxed, grand, margin, unpaid, paid, sent } = rows.reduce(
    (a: any, b: any) => {
      return {
        tax: a.tax + (b.status !== 'draft' ? parseFloat(b.amount_tax) : 0),
        untaxed: a.untaxed + (b.status !== 'draft' ? parseFloat(b.amount_untaxed) : 0),
        grand: a.grand + (b.status !== 'draft' ? parseFloat(b.amount_total) : 0),
        margin: a.margin + (b.status !== 'draft' ? parseFloat(b.amount_margin) : 0),
        unpaid: a.unpaid + (b.status === 'sent' ? parseFloat(b.amount_untaxed) : 0),
        paid: a.paid + Number(b.status === 'paid'),
        sent: a.sent + Number(b.status === 'sent' || b.status === 'paid')
      }
    },
    { tax: 0, untaxed: 0, grand: 0, margin: 0, unpaid: 0, paid: 0, sent: 0 }
  );
  let summaryRow: InvoiceSummary = {
    name: 'total_0',
    totalCount: rows.length,
    totalTax: Math.round(tax),
    totalUntaxed: Math.round(untaxed),
    grandTotal: Math.round(grand),
    totalMargin: Math.round(margin),
    amountUnpaid: Math.round(unpaid),
    totalPaid: paid,
    totalSent: sent
  };
  return [summaryRow];
}

export function getInvoiceColumns(filters: any, setFilters: any, gridRef: any, LineGrid: any, clients: any, updateForeigner: any): readonly Column<Invoice, InvoiceSummary>[] {
  return [
    SelectColumn,
    {
      key: 'expanded',
      name: '',
      minWidth: 30,
      width: 30,
      colSpan(args) {
        return args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 23 : undefined;
      },
      cellClass(row) {
        return row.expandRole === 'DETAIL' ? 'cell-expand-back' : undefined;
      },
      formatter({ row, isCellSelected, onRowChange }) {
        if (row.expandRole === 'DETAIL') {
          return <LineGrid isCellSelected={true} parentId={row.id} />;
        } else {
          return (
            <CellExpanderFormatter
              expanded={row.expanded}
              isCellSelected={isCellSelected}
              onCellExpand={() => {
                onRowChange({ ...row, expanded: !row.expanded });
              }}
            />
          );
        }
      }
    },
    {
      key: 'id',
      name: 'ID',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: '',
      width: 100,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      summaryFormatter(props: SummaryFormatterProps<InvoiceSummary, Invoice>) { return <div title="Sent or better (anything but draft)">{props.row.totalSent} sent</div>; },
      headerCellClass: 'filter-cell',
      headerRenderer: (props: HeaderRendererProps<Invoice, InvoiceSummary>) => (
        <OrderFilterRenderer<Invoice, InvoiceSummary, HTMLInputElement> {...props}>
          {({ filters, ...rest }) => (
            <input {...rest}
              className="filters"
              value={filters.name}
              onChange={(e) => setFilters({ name: e.target.value })}
              onKeyDown={Format.inputStopPropagation}
            />
          )}
        </OrderFilterRenderer>
      )
    },
    {
      key: 'code',
      name: 'Code',
      width: 110,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      cellClass: 'text-right sel-wider wide-2',
      summaryFormatter(props: SummaryFormatterProps<InvoiceSummary, Invoice>) {
        return <div title="How many sent invoices have been paid?">{props.row.totalSent ? Math.round(100 * props.row.totalPaid / props.row.totalSent) : 0}% paid</div>;
      }
    },
    {
      key: 'order_origin',
      name: 'Oorsprong',
      width: 100,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      cellClass: 'sel-wider wide-2',
    },
    {
      key: 'client_origin',
      name: 'DB Klant',
      width: 0,
      minWidth: 0
    },
    {
      key: 'client_name',
      name: 'Klant',
      editor: (props: any) => Format.ForeignEditor(props, clients, true),
      cellClass(row) {
        return row.expandRole === 'DETAIL' ? 'cell-expand-back' : 'highlight-name sel-wider wide-5 fade';
      },
      summaryFormatter(props: SummaryFormatterProps<InvoiceSummary, Invoice>) {
        return <div title="Sent but not paid (amount without VAT)">&euro; {props.row.amountUnpaid.toLocaleString()} unpaid</div>;
      }
    },
    {
      key: 'client_address',
      name: 'Adr',
      width: 50,
      minWidth: 50,
      editor: (props: any) => Format.TextFieldEditor(props, gridRef),
      cellClass: 'sel-wider wide-9 fade'
    },
    {
      key: 'client_vat_nr',
      name: 'BTW',
      width: 55,
      minWidth: 55,
      editor: (props: any) => Format.VatEditor(props, gridRef, updateForeigner), //(props: any) => Format.TextEditor(props, (v: string) => v.replace(/[ .\-]/g, '')),
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      cellClass: 'sel-wider wide-5 fade'
    },
    {
      key: 'client_vat_perc',
      name: '%',
      width: 45,
      minWidth: 45,
      editor: Format.TextEditor,
      formatter(props) { return <Format.PercentFormatter value={props.row.client_vat_perc} />; }
    },
    {
      key: 'loc_client',
      name: 'Locatie',
      editor: (props: any) => Format.ForeignEditor(props, clients, true),
      cellClass: 'sel-wider wide-5 fade'
    },
    {
      key: 'loc_address',
      name: 'Adr',
      width: 55,
      minWidth: 55,
      editor: (props: any) => Format.TextFieldEditor(props, gridRef),
      cellClass: 'sel-wider wide-8 fade'
    },
    {
      key: 'order_date',
      name: 'Opgesteld',
      editor: Format.DateEditor,
      width: 90,
      cellClass: 'sel-wider wide-2',
      formatter(props) { return <Format.DateFormatter value={props.row.order_date} />; }
    },
    {
      key: 'loc_date',
      name: 'Start',
      editor: Format.DateTimeEditor,
      width: 127,
      cellClass: 'highlight-status sel-wider wide-2',
      formatter(props) { return <Format.DateFormatter value={props.row.loc_date} />; }
    },
    {
      key: 'text',
      name: 'Omschrijving',
      editor: (props: any) => Format.TextFieldEditor(props, gridRef),
      cellClass: 'sel-wider wide-8 fade'
    },
    {
      key: 'event',
      name: 'Ev',
      width: 0,
      minWidth: 0
    },
    {
      key: 'eventId',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'sheet',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'client_ref',
      name: 'Ref',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      width: 40,
      minWidth: 40,
      cellClass: 'sel-wider wide-6 fade'
    },
    {
      key: 'status',
      name: 'Status',
      editor: (props: any) => Format.SelectEditor(props, [
        'draft',
        'sent',
        'paid'
      ]),
      width: 80,
      minWidth: 80,
      cellClass(row) { return 'status-' + row.status },
      summaryFormatter(props: SummaryFormatterProps<InvoiceSummary, Invoice>) {
        return <div title="Sent but not paid">{props.row.totalSent - props.row.totalPaid} unpaid</div>;
      }
    },
    {
      key: 'amount_tax',
      name: 'BTW',
      width: 80,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_tax} />; },
      summaryFormatter(props: SummaryFormatterProps<InvoiceSummary, Invoice>) {
        return <>&euro; {props.row.totalTax.toLocaleString()}</>;
      }
    },
    {
      key: 'amount_untaxed',
      name: 'Subtotaal',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_untaxed} />; },
      summaryFormatter(props: SummaryFormatterProps<InvoiceSummary, Invoice>) {
        return <>&euro; {props.row.totalUntaxed.toLocaleString()}</>;
      }
    },
    {
      key: 'amount_total',
      name: 'Totaal',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_total} />; },
      summaryFormatter(props: SummaryFormatterProps<InvoiceSummary, Invoice>) {
        return <>&euro; {props.row.grandTotal.toLocaleString()}</>;
      }
    }
  ];
}


export function getInvoiceFilteredSortedRows(rows: any, invoiceSortColumns: any, filters: any): readonly Invoice[] {
  // first filter, then sort
  const filteredRows = rows.filter((r: any) => {
    if (!filters.name) return true;
    const str = filters.name.toLowerCase();
    return (r.name?.toLowerCase().includes(str)
      || r.code?.toLowerCase().includes(str)
      || r.order_date?.toLowerCase().includes(str)
      || r.client_name?.toLowerCase().includes(str)
      || r.client_address?.toLowerCase().includes(str)
      || r.client_vat_nr?.toLowerCase().includes(str)
      || r.client_ref?.toLowerCase().includes(str)
      || r.loc_client?.toLowerCase().includes(str)
      || r.loc_address?.toLowerCase().includes(str)
      || r.loc_date?.toLowerCase().includes(str)
      || r.status?.toLowerCase().includes(str)
      || r.text?.toLowerCase().includes(str)
    );
  });
  if (invoiceSortColumns.length === 0) return filteredRows;
  const sortedRows = [...filteredRows];
  sortedRows.sort((a, b) => {
    for (const sort of invoiceSortColumns) {
      const comparator = invoiceComparator(sort.columnKey);
      const compResult = comparator(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
}




export function getContractSummaryRows(rows: any) {
  const { tax, untaxed, grand, unpaid, paid, sent } = rows.reduce(
    (a: any, b: any) => {
      return {
        tax: a.tax + (b.status !== 'draft' ? parseFloat(b.amount_tax) : 0),
        untaxed: a.untaxed + (b.status !== 'draft' ? parseFloat(b.amount_untaxed) : 0),
        grand: a.grand + (b.status !== 'draft' ? parseFloat(b.amount_total) : 0),
        unpaid: a.unpaid + (b.status === 'sent' ? parseFloat(b.amount_untaxed) : 0),
        paid: a.paid + Number(b.status === 'paid'),
        sent: a.sent + Number(b.status === 'sent' || b.status === 'paid')
      }
    },
    { tax: 0, untaxed: 0, grand: 0, unpaid: 0, paid: 0, sent: 0 }
  );
  let summaryRow: ContractSummary = {
    name: 'total_0',
    totalCount: rows.length,
    totalTax: Math.round(tax),
    totalUntaxed: Math.round(untaxed),
    grandTotal: Math.round(grand),
    amountUnpaid: Math.round(unpaid),
    totalPaid: paid,
    totalSent: sent
  };
  return [summaryRow];
}

export function getContractColumns(rows: any, filters: any, setFilters: any, gridRef: any, LineGrid: any, clients: any, suppliers: any): readonly Column<Contract, ContractSummary>[] {
  return [
    SelectColumn,
    {
      key: 'expanded',
      name: '',
      minWidth: 30,
      width: 30,
      colSpan(args) {
        return args.type === 'ROW' && args.row.expandRole === 'DETAIL' ? 18 : undefined;
      },
      cellClass(row) {
        return row.expandRole === 'DETAIL' ? 'cell-expand-back' : undefined;
      },
      formatter({ row, isCellSelected, onRowChange }) {
        if (row.expandRole === 'DETAIL') {
          return <LineGrid isCellSelected={true} parentId={row.id} />;
        } else {
          return (
            <CellExpanderFormatter
              expanded={row.expanded}
              isCellSelected={isCellSelected}
              onCellExpand={() => {
                onRowChange({ ...row, expanded: !row.expanded });
              }}
            />
          );
        }
      }
    },
    {
      key: 'id',
      name: 'ID',
      minWidth: 0,
      width: 0
    },
    {
      key: 'name',
      name: '',
      width: 100,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      summaryFormatter(props: SummaryFormatterProps<ContractSummary, Contract>) { return <div title="Sent or better (anything but draft)">{props.row.totalSent} sent</div>; },
      headerCellClass: 'filter-cell',
      headerRenderer: (props: HeaderRendererProps<Contract, ContractSummary>) => (
        <OrderFilterRenderer<Contract, ContractSummary, HTMLInputElement> {...props}>
          {({ filters, ...rest }) => (
            <input {...rest}
              className="filters"
              value={filters.name}
              onChange={(e) => setFilters({ name: e.target.value })}
              onKeyDown={Format.inputStopPropagation}
            />
          )}
        </OrderFilterRenderer>
      )
    },
    {
      key: 'code',
      name: 'Code',
      width: 110,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      cellClass: 'text-right sel-wider wide-2',
      summaryFormatter(props: SummaryFormatterProps<ContractSummary, Contract>) {
        return <div title="How many sent contracts have been paid?">{props.row.totalSent ? Math.round(100 * props.row.totalPaid / props.row.totalSent) : 0}% paid</div>;
      }
    },
    {
      key: 'order_name',
      name: 'Oorsprong',
      width: 102,
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      cellClass: 'sel-wider wide-2',
    },
    {
      key: 'contact_supplier',
      name: 'Masseur',
      editor: (props: any) => Format.ForeignEditor(props, suppliers),
      width: 180,
      cellClass(row) {
        return row.expandRole === 'DETAIL' ? 'cell-expand-back' : 'highlight-name sel-wider wide-2 fade';
      },
      formatter(props) {
        return <Format.ForeignFormatter value={suppliers.get(props.row.contact_supplier)?.name} />;
      },
      summaryFormatter(props: SummaryFormatterProps<ContractSummary, Contract>) {
        return <div title="Sent but not paid (amount without VAT)">&euro; {props.row.amountUnpaid.toLocaleString()} net unpaid</div>;
      }
    },
    {
      key: 'loc_client',
      name: 'Locatie',
      editor: (props: any) => Format.ForeignEditor(props, clients),
      cellClass: 'sel-wider wide-2 fade',
      formatter(props) {
        return <Format.ForeignFormatter value={clients.get(props.row.loc_client)?.name} />;
      }
    },
    {
      key: 'order_date',
      name: 'Opgesteld',
      editor: Format.DateEditor,
      width: 88,
      cellClass: 'sel-wider wide-2',
      formatter(props) { return <Format.DateFormatter value={props.row.order_date} />; }
    },
    {
      key: 'loc_date',
      name: 'Start',
      editor: Format.DateTimeEditor,
      width: 100,
      cellClass: 'highlight-status sel-wider wide-2',
      formatter(props) { return <Format.DateFormatter value={props.row.loc_date} />; }
    },
    {
      key: 'text',
      name: 'Omschrijving',
      editor: (props: any) => Format.TextFieldEditor(props, gridRef),
      width: 110,
      cellClass: 'sel-wider wide-8 fade'
    },
    {
      key: 'event',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'eventId',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'sheet',
      name: '',
      width: 0,
      minWidth: 0
    },
    {
      key: 'supplier_ref',
      name: 'Ref',
      editor: Format.TextEditor,
      editorOptions: { onNavigation(ev) { return Boolean(ev.stopPropagation()) }},
      width: 50,
      minWidth: 50,
      cellClass: 'sel-wider wide-3 fade'
    },
    {
      key: 'status',
      name: 'Status',
      editor: (props: any) => Format.SelectEditor(props, [
        'draft',
        'sent',
        'paid'
      ]),
      width: 40,
      cellClass(row) { return 'status-' + row.status },
      summaryFormatter(props: SummaryFormatterProps<ContractSummary, Contract>) {
        return <div title="Contracts sent but not paid">{props.row.totalSent - props.row.totalPaid} unpaid</div>;
      }
    },
    {
      key: 'amount_tax',
      name: 'BTW',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_tax} />; },
      summaryFormatter(props: SummaryFormatterProps<ContractSummary, Contract>) {
        return <>&euro; {props.row.totalTax.toLocaleString()}</>;
      }
    },
    {
      key: 'amount_untaxed',
      name: 'Subtotaal',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_untaxed} />; },
      summaryFormatter(props: SummaryFormatterProps<ContractSummary, Contract>) {
        return <>&euro; {props.row.totalUntaxed.toLocaleString()}</>;
      }
    },
    {
      key: 'amount_total',
      name: 'Totaal',
      width: 85,
      cellClass: 'text-right sel-wider wide-2',
      formatter(props) { return <Format.CurrencyFormatter value={props.row.amount_total} />; },
      summaryFormatter(props: SummaryFormatterProps<ContractSummary, Contract>) {
        return <>&euro; {props.row.grandTotal.toLocaleString()}</>;
      }
    }
  ];
}

export function getContractFilteredSortedRows(rows: any, contractSortColumns: any, filters: any, clients: any, suppliers: any): readonly Contract[] {
  // first filter, then sort
  const filteredRows = rows.filter((r: any) => {
    if (!filters.name) return true;
    const str = filters.name.toLowerCase();
    return (r.name?.toLowerCase().includes(str)
      || r.code?.toLowerCase().includes(str) || r.order_date?.toLowerCase().includes(str)
      || suppliers.get(r.contact_supplier)?.name.toLowerCase().includes(str)
      || clients.get(r.loc_client)?.name.toLowerCase().includes(str)
      || r.loc_date?.toLowerCase().includes(str)
      || r.status?.toLowerCase().includes(str) || r.supplier_ref?.toLowerCase().includes(str)
    );
  });
  if (contractSortColumns.length === 0) return filteredRows;
  const sortedRows = [...filteredRows];
  sortedRows.sort((a, b) => {
    for (const sort of contractSortColumns) {
      const comparator = contractComparator(sort.columnKey);
      const compResult = comparator(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
}


function orderComparator(sortColumn: string): OrderComparator {
  switch (sortColumn) {
    case 'id':
    case 'name':
    case 'code':
    case 'order_date':
    case 'contact_client':
    case 'invoice_client':
    case 'loc_client':
    case 'loc_date':
    case 'status':
    case 'text':
    case 'client_ref':
      return (a: any, b: any) => {
        return a[sortColumn].toString().localeCompare(b[sortColumn]);
      };
    case 'amount_tax':
    case 'amount_untaxed':
    case 'amount_total':
    case 'amount_margin':
      return (a: any, b: any) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`); //
  }
}

function invoiceComparator(sortColumn: string): InvoiceComparator {
  switch (sortColumn) {
    case 'id':
    case 'name':
    case 'code':
    case 'order_date':
    case 'client_name':
    case 'client_address':
    case 'client_vat_nr':
    case 'loc_client':
    case 'loc_address':
    case 'loc_date':
    case 'status':
    case 'text':
    case 'client_ref':
    case 'order_origin':
      return (a: any, b: any) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    case 'client_vat_perc':
    case 'amount_tax':
    case 'amount_untaxed':
    case 'amount_total':
    case 'amount_margin':
      return (a: any, b: any) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

function contractComparator(sortColumn: string): ContractComparator {
  switch (sortColumn) {
    case 'id':
    case 'name':
    case 'code':
    case 'order_date':
    case 'contact_supplier':
    case 'loc_client':
    case 'loc_date':
    case 'status':
    case 'text':
    case 'supplier_ref':
      return (a: any, b: any) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    case 'amount_tax':
    case 'amount_untaxed':
    case 'amount_total':
      return (a: any, b: any) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}


function OrderFilterRenderer<R, SR, T extends HTMLOrSVGElement>(
  { isCellSelected, column, children }: HeaderRendererProps<R, SR> &
  { children: (args: { ref: React.RefObject<T>; tabIndex: number; filters: Filter; }) => React.ReactElement; }
) {
  const filters = useContext(filterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);
  return (<><div>{column.name}</div><div>{children({ ref, tabIndex, filters })}</div></>);
}


// https://github.com/adazzle/react-data-grid/blob/main/src/hooks/useFocusRef.ts
const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
  const ref = useRef<T>(null);
  useLayoutEffect(() => { if (!isSelected) return; ref.current?.focus({ preventScroll: true }); }, [isSelected]);
  return { ref, tabIndex: isSelected ? 0 : -1 };
}
