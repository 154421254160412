import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';

// for my autocomplete select dropdown
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const Page: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle class="ion-text-capitalize clickaway">{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" class="ion-text-capitalize clickaway">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ThemeProvider theme={createTheme({ palette: { mode: 'dark' }})}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ExploreContainer name={name} />
          </LocalizationProvider>
        </ThemeProvider>
      </IonContent>
    </IonPage>
  );
};

export default Page;
